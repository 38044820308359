<div>
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
    <div *ngIf="!listArr">
      <nz-skeleton [nzParagraph]="{ rows: 10 }"></nz-skeleton>
    </div>
    <div *ngIf="listArr" style="margin-top: 30px; ">
      <nz-table [nzBordered]="false"
                [nzOuterBordered]="true"
                [nzShowPagination]="true"
                [nzLoading]="loading"
                [nzLoadingIndicator]="indicatorTemplate"
                [nzFrontPagination]="false"
                [nzData]="listArr.list"
                [nzPageIndex]="listArr.pageNum"
                [nzPageSize]="listArr.pageSize"
                [nzTotal]="listArr.total"
                [nzTitle]="tableHeader"
                [nzFooter]="tableFooter"
                (nzPageIndexChange)="onSearch($event)"
                [nzScroll]="{ x: '500px'}">
        <ng-template #tableHeader>
          <nz-row>
            <nz-col [nzSpan]="12">
              <div class="list-header-title">{{'PremiumFinancingList' | translate}}
                <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Refresh' | translate"
                   style="margin-left: 5px"
                   appThrottleClick (throttleClick)="onReload()">
                  <em nz-icon style="color: black;" nzType="reload" nzTheme="outline"></em>
                </a>
              </div>
            </nz-col>
            <nz-col [nzSpan]="12">
              <div style="width: 100%; display: flex; justify-content: flex-end;">
                <button nz-button nzType="default" nzSize="large" nzShape="circle"
                        nz-tooltip [nzTooltipTitle]="'Filter' | translate"
                        nz-popover [nzPopoverContent]="searchTemplate"
                        [(nzPopoverVisible)]="searchPopoverVisible"
                        nzPopoverTrigger="click"
                        nzPopoverPlacement="bottomRight"
                        style="margin-right: 10px;">
                  <img alt="" width="22" src="assets/images/ic_search.svg">
                </button>

                <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                        [nzTooltipTitle]="'PremiumFinancingCreate' | translate" style="margin-left: 10px;"
                        appThrottleClick (throttleClick)="onCreate()">
                  <img alt="" width="22" src="assets/images/ic_proposal_new.svg">
                </button>
              </div>
            </nz-col>
          </nz-row>
        </ng-template>
        <ng-template #tableFooter>
          <div class="table-title">
            {{'TotalRows' | translate}} : <span
            style="color: #002140; font-weight: bold;">{{ listArr.total }}</span>
          </div>
        </ng-template>

        <thead>
        <tr>
          <th [nzWidth]="'80px'">{{'IDNumber' | translate}}</th>
          <th [nzWidth]="'180px'">{{'ClientName' | translate}}</th>
          <th [nzWidth]="'180px'">{{'ProposalID' | translate}}</th>
          <th [nzWidth]="'250px'">{{'ProductName' | translate}}</th>
          <th [nzWidth]="'120px'">{{'InsuranceType' | translate}}</th>
          <th [nzWidth]="'120px'">{{'PaymentTerm' | translate}}</th>
          <th [nzWidth]="'90px'">{{'Gender' | translate}}</th>
          <th [nzWidth]="'90px'">{{'Age' | translate}}</th>
          <th [nzWidth]="'120px'">{{'Premium' | translate}}</th>
          <th *ngIf="instance.isMercer()" [nzWidth]="'120px'">{{'PFWCFull' | translate}}</th>
          <th *ngIf="instance.isMercer()" [nzWidth]="'120px'">{{'AnnualReturns' | translate}}(%)</th>
          <th [nzWidth]="'180px'">{{'CreatedTime' | translate}}</th>
          <th [nzWidth]="'120px'">{{'Status' | translate}}</th>
          <th [nzWidth]="'160px'" nzRight>{{'Functions' | translate}}</th>
        </tr>
        </thead>
        <tbody>
        <ng-template ngFor let-item [ngForOf]="listArr.list">
          <tr>
            <td>{{item.id}}</td>
            <td>{{item.name}}</td>
            <td>{{item.proposalId}}</td>
            <td nzEllipsis>{{item.proposal.productName}}</td>
            <td>{{item.proposal.categoryName}}</td>
            <td>{{item.proposal.paymentTerm}}</td>
            <td>{{item.proposal.gender | metadataTranslate: 'gender'}}</td>
            <td>{{item.proposal.age}}</td>
            <td>{{(item.premium | number) || '-'}}</td>
            <td *ngIf="instance.isMercer()">{{fullOrPartialStr(item.bankInfo.fullOrPartial)}}</td>
            <td *ngIf="instance.isMercer()">{{(item.annualReturns | number)}}</td>
            <td>{{item.addTime | date: 'yyyy-MM-dd HH:mm:ss'}}</td>
            <td>
              <div [ngSwitch]="item.status">
                <div>
                  <span *ngSwitchCase="'PENDING'" class="text-warning">●</span>
                  <span *ngSwitchCase="'COMPLETED'" class="text-success">●</span>
                  <span *ngSwitchCase="'FAIL'" class="text-danger">●</span>
                  <span *ngSwitchCase="'DELETED'" class="text-cancel">●</span>
                  <span> {{ item.status | metadataTranslate: 'premiumFinancingStatus' }}</span>
                </div>
              </div>
            </td>
            <td nzRight>
              <div style="display: flex; flex-direction: row; justify-content: start; align-items: start;">
                <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Detail' | translate"
                   appThrottleClick (throttleClick)="onDetail(item); $event.stopPropagation();">
                  <img alt="" width="20" src="assets/images/ic_details.svg">
                </a>
                <div *ngIf="item.status == 'COMPLETED'">
                  <a nz-button nzType="link" nzSize="small" *ngIf="item.status == 'COMPLETED'" nz-tooltip [nzTooltipTitle]="'Download' | translate" nz-dropdown
                     appThrottleClick (throttleClick)="$event.stopPropagation();"
                     [nzDropdownMenu]="menu" nzTrigger="click">
                    <img alt="" width="20" src="assets/images/ic_download.svg">
                  </a>
                  <nz-dropdown-menu #menu="nzDropdownMenu">
                    <ul nz-menu nzSelectable>
                      <li nz-menu-item appThrottleClick
                          (throttleClick)="onDownload(item.urlCn)">{{ 'CN' | translate}}</li>
                      <li nz-menu-item appThrottleClick
                          (throttleClick)="onDownload(item.urlHk)">{{ 'HK' | translate}}</li>
                      <li nz-menu-item appThrottleClick
                          (throttleClick)="onDownload(item.urlUs)">{{ 'EN' | translate}}</li>
                    </ul>
                  </nz-dropdown-menu>
                </div>
                <a *ngIf="item.status !== 'COMPLETED'" nz-button nzType="link" nzSize="small"
                   nz-tooltip [nzTooltipTitle]="'Retry' | translate"
                   appThrottleClick
                   (throttleClick)="onRetry(item); $event.stopPropagation();">
                  <em nz-icon style="color: black;font-size: 15px;" nzType="reload" nzTheme="outline"></em>
                </a>
                <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Delete' | translate"
                   appThrottleClick
                   (throttleClick)="onDelete(item, tplTitle, tplContent, tplFooter); $event.stopPropagation();">
                  <img alt="" width="20" src="assets/images/ic_delete.svg">
                </a>
              </div>
            </td>
          </tr>
        </ng-template>
        </tbody>
      </nz-table>
    </div>
  </nz-spin>
</div>

<!-- 搜索气泡Template -->
<ng-template #searchTemplate>
  <div style="width: 300px; padding: 5px;">
    <div style="padding: 0 0 15px 0">{{'Filter' | translate}}</div>
    <div class="property-bottom-line-blue"></div>
    <!--名字-->
    <app-property-input [title]="'ClientName' | translate"
                        [placeholder]="('PleaseEnter' | translate) + ('ClientName' | translate)"
                        [(value)]="search.name"></app-property-input>

    <app-property-input [title]="'ProposalID' | translate"
                        [placeholder]="('PleaseEnter' | translate) + ('ProposalID' | translate)"
                        [(value)]="search.proposalId"></app-property-input>
    <app-property-pick [title]="'InsuranceProduct' | translate"
                       appThrottleClick (throttleClick)="onProductSelection()"
                       [placeholder]="'ProductSelect' | translate"
                       [(value)]="search.product.productName"></app-property-pick>
    <!--保险种类-->
    <app-property-select [title]="'InsuranceType' | translate"
                         [placeholder]="('PleaseSelect' | translate) + ('InsuranceType' | translate)"
                         [options]="categoryOptions"
                         [(value)]="search.categoryCode">
    </app-property-select>

    <app-property-select [title]="'PFWCFull' | translate"
                         [placeholder]="('PleaseSelect' | translate) + ('PFWCFull' | translate)"
                         [options]="fullOptions"
                         [(value)]="search.fullOrPartial"></app-property-select>

    <app-property-gender [title]="'Gender' | translate"
                         [(value)]="search.gender"></app-property-gender>

    <app-property-input-number [title]="'Age' | translate"
                               [placeholder]="('PleaseEnter' | translate) + ('Age' | translate)"
                               [(value)]="search.age"></app-property-input-number>
    <!--    状态-->
<!--    <app-property-select [title]="'Status' | translate"-->
<!--                         [placeholder]="('PleaseSelect' | translate) + ('Status' | translate)"-->
<!--                         [options]="statusOptions"-->
<!--                         [(value)]="search.status">-->
<!--    </app-property-select>-->
    <nz-row [nzGutter]="5">
      <nz-col nzSpan="12">
        <button nz-button nzType="default" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick
                (throttleClick)="onReload()">
          <span>{{'Reset' | translate}}</span>
        </button>
      </nz-col>
      <nz-col nzSpan="12">
        <button nz-button nzType="primary" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick
                (throttleClick)="onSearch(1)">
          <span>{{'Confirm' | translate}}</span>
        </button>
      </nz-col>
    </nz-row>
  </div>
</ng-template>
<ng-template #tplTitle>
  <span>{{'Delete' | translate}}</span>
</ng-template>
<ng-template #tplContent let-params>
  <p>{{'DeletePremiumFinancing' | translate}} :「{{ params.id }}」?</p>
</ng-template>
<ng-template #tplFooter let-ref="modalRef" let-params>
  <button nz-button appThrottleClick (throttleClick)="ref.destroy()"
          [disabled]="deleting">{{'Cancel' | translate}}</button>
  <button nz-button nzType="primary" appThrottleClick (throttleClick)="onDeleteConfirm(ref, params)"
          [nzLoading]="deleting">
    {{'Delete' | translate}}
  </button>
</ng-template>
