<div class="drawer-container">
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div class="common-title" style="margin-bottom: 30px">
      {{'PremiumFinancing' | translate}}
    </div>

    <div class="section-title pre-lien" style="margin-top: 20px;display: flex">
      <span style="margin-left: 8px">{{'BasicInformation' | translate}}</span>
    </div>

    <div class="solid-box">
      <nz-row [nzGutter]="10">
        <nz-col [nzSpan]="24">
          <app-property-text [title]="'Proposal' | translate"
                             [displayMode]="'vertical'"
                             [value]="proposalLabel"></app-property-text>
        </nz-col>
        <nz-col [nzSpan]="12">
          <app-property-text [title]="'Name' | translate"
                             [displayMode]="'vertical'"
                             [value]="info.premiumFinancing.name"></app-property-text>
        </nz-col>
        <nz-col [nzSpan]="12">
          <app-property-text [title]="'Currency' | translate"
                             [displayMode]="'vertical'"
                             [value]="info.premiumFinancing.currency || 'N/A'"></app-property-text>
        </nz-col>
        <nz-col [nzSpan]="12">
          <app-property-text [title]="'PaymentTerm' | translate"
                             [displayMode]="'vertical'"
                             [value]="info.premiumFinancing.paymentTerm || 'N/A'"></app-property-text>
        </nz-col>
        <nz-col [nzSpan]="12">
          <app-property-text [title]="'AnnualPremium' | translate"
                             [displayMode]="'vertical'"
                             [value]="info.premiumFinancing.premium | number"></app-property-text>
        </nz-col>
        <nz-col [nzSpan]="12">
          <app-property-text [title]="'TotalPremium' | translate"
                             [displayMode]="'vertical'"
                             [value]="info.premiumFinancing.premiumTotal | number"></app-property-text>
        </nz-col>
        <nz-col [nzSpan]="12">
          <app-property-text [title]="'InitialSurrenderValue' | translate"
                             [displayMode]="'vertical'"
                             [value]="info.premiumFinancing.initialSurrenderValue | number"></app-property-text>
        </nz-col>
      </nz-row>
    </div>

    <div class="section-title pre-lien" style="margin-top: 20px;display: flex">
      <span style="margin-left: 8px">{{'FinancingInfo' | translate}}</span>
    </div>

    <div class="solid-box">
      <nz-row [nzGutter]="10">
        <nz-col [nzSpan]="12">
          <app-property-text [title]="'PFWCFull' | translate"
                             [displayMode]="'vertical'"
                             [value]="fullOrPartialStr(info.premiumFinancing.bankInfo.fullOrPartial)"></app-property-text>
        </nz-col>
        <nz-col [nzSpan]="12">
          <app-property-text [title]="('AnnualInterestRate' | translate) + ' (%)'"
                             [displayMode]="'vertical'"
                             [value]="info.premiumFinancing.bankInfo.annualInterestRate"></app-property-text>
        </nz-col>
        <nz-col [nzSpan]="12">
          <app-property-text [title]="('LoanRatio' | translate) + ' (%)'"
                             [displayMode]="'vertical'"
                             [valueColor]="'#cccccc'"
                             [value]="info.premiumFinancing.bankInfo.loanRatio"></app-property-text>
        </nz-col>
      </nz-row>
    </div>

    <div class="section-title pre-lien" style="margin-top: 20px;display: flex">
      <span style="margin-left: 8px">{{'Other' | translate}}</span>
    </div>

    <div class="solid-box">
      <nz-row [nzGutter]="10">
        <nz-col [nzSpan]="12">
          <app-property-text [title]="('AnnualReturns' | translate) + ' (%)'"
                             [displayMode]="'vertical'"
                             [value]="info.premiumFinancing.annualReturns"></app-property-text>
        </nz-col>
        <nz-col [nzSpan]="12" style="display: flex;align-items: end;">
          <app-property-switch  [title]="'MaskInsurerAsterisk' | translate"
                                [bottomLineVisible]="false"
                                [disabled]="true"
                                style="width: 100%;"
                                [(value)]="info.premiumFinancing.asterisk"></app-property-switch>
        </nz-col>
        <nz-col [nzSpan]="12">
          <app-property-text *ngIf="!info.premiumFinancing.asterisk"
                             [title]="'InsurerCode' | translate"
                             [displayMode]="'vertical'"
                             [valueColor]="'#cccccc'"
                             [value]="info.premiumFinancing.insuranceCompany"></app-property-text>
        </nz-col>
      </nz-row>
    </div>
  </nz-spin>
</div>
