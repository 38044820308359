import { Component, OnInit } from '@angular/core';
import { DataService } from '../shared/service/data.service';
import { WorkbenchService } from '../workbench/workbench.service';
import { Workbench } from '../workbench/workbench-types';
import { DataMode } from '../api/types';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-underwriting-main',
  templateUrl: './underwriting-main.component.html',
  styleUrls: ['./underwriting-main.component.less']
})
export class UnderwritingMainComponent implements OnInit {
  workbench: Workbench;
  selectIndex = 0;

  constructor(public dataService: DataService,
              private router: ActivatedRoute,
              private workbenchService: WorkbenchService) {
  }

  ngOnInit(): void {
    this.router
      .queryParams
      .subscribe(params => {
        switch (params['mode']) {
          case DataMode.OWN:
            this.selectIndex = 0;
            break;
          case DataMode.SUBORDINATE:
            this.selectIndex = 1;
            break;
          case DataMode.FOLLOWUP:
            this.selectIndex = 2;
            break;
          case DataMode.SALESGROUP:
            this.selectIndex = 3;
            break;
        }
      });
    this.workbench = this.workbenchService.workbench;
  }
}
