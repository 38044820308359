export class API {

  static baseUrl = 'https://api.lifebee.tech/';
  // static baseUrl = 'http://localhost:8080/';

  static errorV3 = API.baseUrl + 'app/v3/error';

  static gatewayV3 = API.baseUrl + 'app/v3/gateway';

  static accountV3 = API.baseUrl + 'app/v3/account';

  static accountV4 = API.baseUrl + 'app/v4/account';

  static systemV3 = API.baseUrl + 'app/v3/system';

  static commonV3 = API.baseUrl + 'app/v3/common';

  static commonV4 = API.baseUrl + 'app/v4/common';

  static storageV4 = API.baseUrl + 'app/v4/storage';

  static storageCentreV3 = API.baseUrl + 'app/v3/storage-centre';

  static dashboardV3 = API.baseUrl + 'app/v3/dashboard';

  static productV3 = API.baseUrl + 'app/v3/product';

  static companyV3 = API.baseUrl + 'app/v3/company';

  static compareV3 = API.baseUrl + 'app/v3/compare';

  static compareV4 = API.baseUrl + 'app/v4/compare';

  static messageV3 = API.baseUrl + 'app/v3/message';

  static proposalV3 = API.baseUrl + 'app/v3/proposal';

  static proposalV4 = API.baseUrl + 'app/v4/proposal';

  static bookingV3 = API.baseUrl + 'app/v3/booking';

  static bookingV4 = API.baseUrl + 'app/v4/booking';

  static bookingV5 = API.baseUrl + 'app/v5/booking';

  static bookingV6 = API.baseUrl + 'app/v6/booking';

  static amlSearchV3 = API.baseUrl + 'app/v3/aml/search';

  static amlSearchV4 = API.baseUrl + 'app/v4/aml/search';

  static amlMonitorV3 = API.baseUrl + 'app/v3/aml/monitor';

  static informationV3 = API.baseUrl + 'app/v3/information';

  static ticketV3 = API.baseUrl + 'app/v3/ticket';

  static ticketCentreV3 = API.baseUrl + 'app/v3/ticket-centre';

  static fnaV3 = API.baseUrl + 'app/v3/fna';

  static newFnaV3 = API.baseUrl + 'app/v3/new/fna';

  static inviteV3 = API.baseUrl + 'app/v3/client/intention/invite';

  static bvV3 = API.baseUrl + 'app/v3/business/volume/bv';

  static newFnaV4 = API.baseUrl + 'app/v4/new/fna';

  static fnaV4 = API.baseUrl + 'app/v4/fna';

  static underwritingV3 = API.baseUrl + 'app/v3/underwriting';

  static underwritingV4 = API.baseUrl + 'app/v4/underwriting';

  static policyV3 = API.baseUrl + 'app/v3/policy';

  static policyV4 = API.baseUrl + 'app/v4/policy';

  static paymentV3 = API.baseUrl + 'app/v3/payment';

  static documentV3 = API.baseUrl + 'app/v3/document';

  static documentV4 = API.baseUrl + 'app/v4/document';

  static knowledgeV3 = API.baseUrl + 'app/v3/knowledge';

  static commissionV3 = API.baseUrl + 'app/v3/commission';

  static contactV3 = API.baseUrl + 'app/v3/contact';

  static contactV4 = API.baseUrl + 'app/v4/contact';

  static shareV3 = API.baseUrl + 'app/v3/share';

  static shareV4 = API.baseUrl + 'app/v4/share';

  static quotationV3 = API.baseUrl + 'app/v3/quotation';

  static contractV3 = API.baseUrl + 'app/v3/contract';

  static scheduleV3 = API.baseUrl + 'app/v3/schedule';

  static leaveWordV3 = API.baseUrl + 'app/v3/leave-word';

  static proposalCompareV3 = API.baseUrl + 'app/v3/proposal-compare';

  static salesPact = API.baseUrl + 'app/v3/pact';

  static open = API.baseUrl + 'app/open';

  static proposalCustomV3 = API.baseUrl + 'app/v3/custom';

  static newCommissionV3 = API.baseUrl + 'app/v3/sales/bill';

  static behaviorV3 = API.baseUrl + 'app/v3/behavior';

  static policyCommissionV3 = API.baseUrl + 'app/v3/policy/commission';

  static policyCommissionV4 = API.baseUrl + 'app/v4/policy/commission';

  static premiumFinancingV3 = API.baseUrl + 'app/v3/premium-financing';

}
