import { Type } from 'class-transformer';
import { Feature, MessageListResp, TemplateFileMD5List } from '../api/types';
import { InformationListResp } from '../information/information-types';

export class Workbench {
  banners: string[]; // 工作台Banners
  userName: string; // 用户名称
  nameEn; // 英文名称
  avatar: string; // 用户头像
  companyName: string;
  appCompanyName: string;
  appointmentDateLimit: AppointmentDateLimit = new AppointmentDateLimit(); // 预约日期限制
  profile; // 个人简介
  pivot; // 数据透视: OWN-自身;DIRECT_SUBORDINATE-直接下级;ALL_SUBORDINATE-所有下级
  pendingCount: number; // Pending未完成数
  serviceCount: number; // Service未完成数
  unFinishCount: number; // 待办事项数字提醒
  bookingDialogCount: number;
  policyDialogCount: number;
  underwritingDialogCount: number;
  @Type(() => Feature)
  features: Feature[]; // 功能分组
  // private WorkbenchInformationVO information; // 资讯
  shareApp: boolean; // 可分享APP
  extender: boolean; // 是否拓展者
  isBookingOwnerSignature: boolean;
  hiddenLastName: boolean;
  amlChineseNameEnable: boolean; // aml中文姓名禁用
  amlNameConfig: number; // aml支持姓名配置：0-中文和英文；1-中文；2-英文
  tr: boolean; // 是否签单员
  secondAccountName: boolean; // 双渠道
  showVehicleServiceConfig: boolean;
  commissionExcelShare: boolean; // 佣金表Excel支持设置无法分享
  salesDownloadContract: boolean; // 智能合約支持下载
  registerNumber: string; // 理财顾问注册编号
  consultantNumber: string; // 理财顾问编码
  viewFnaPdf: boolean; // 是否查看FNA PDF(默认是)
  bookingNeedProposal: boolean; // 是否必须关联计划书提交预约
  proposalAccount: boolean; // 计划书账号
  starRatingConfig: boolean; // 是否隐藏产品对比打分
  bookingAttachmentUpload: boolean; // APP是否需要预约上传附件
  bookingSignatureDataRemark: string; // 预约签单资料备注
  bookingCompanyWorkDateRemark: string; // 保险公司工作时间备注
  bookingTipsRemark: string; // 新建预约备注
  bookingChargeRemark: string; // 预约收费备注
  bookingVipChargeRemark: string; // 预约VIP收费备注
  underwritingAttachmentUpload: boolean; // APP是否需要新单上传附件
  proposalClean: boolean; // 是否支持计划书清理
  confirmSubordinateBill: boolean; // 是否允許確認下線帳單
  @Type(() => MessageListResp)
  messageList: MessageListResp[]; // 最新消息
  @Type(() => InformationListResp)
  informationList: InformationListResp[]; // 最新消息
  salesAchievementReport: boolean; // 銷售業績報表
  @Type(() => TemplateFileMD5List)
  templateFileMD5List: TemplateFileMD5List[]; // 模板文件的md5值
  bookingAttachmentUploadNew: number; // APP预约附件：禁用-0、仅查看-2、查看和上传-1
  underwritingAttachmentUploadNew: number; // APP新单附件：禁用-0、仅查看-2、查看和上传-1
  policyAttachmentUploadNew: number; // APP保单附件：禁用-0、仅查看-2、查看和上传-1
  childAttachmentUpload: number; // APP我下线的附件：禁用-0、仅查看-2、查看和上传-1
  followAttachmentUpload: number; // APP我跟进的附件：禁用-0、仅查看-2、查看和上传-1
  isModifyAccountName: number; // 限制销售修改销售姓名 0-支持修改;1-不支持修改
  teamAttachmentUpload: number; // APP我团队的附件：禁用-0、仅查看-2、查看和上传-1
  hideUploader: boolean; // 是否隐藏资料云盘上传者 0:不隐藏 1:隐藏
  schedule: boolean; // 是否显示销售渠道日程
  relation: boolean; // 是否显示关系模块
  pinCodeEnable: boolean; // 是否开启PIN
  microCard: boolean; // 是否显示销售渠道微名片
  showExchangeRate: boolean; // 是否展示结算汇率
  salesDeleteProposal: boolean; // 计划书支持删除
  downloadProposal: boolean; // 计划书PDF支持下载
  downloadProposalDetailValue: boolean; // 计划书价值演示支持下载
  addressConfig: boolean; // 计划书价值演示支持下载
  groupDesensitization: boolean; // 是否在不脱敏的特殊分组内，true：不脱敏,false:脱敏
  fnaSign: number; // FNA签名权限:0-不需要签名 1-销售签名 2-客户签名 3-销售跟客户签名
  fnaVersion: string; // FNA版本
  systemTimeZone: string; // 时区
  language: string; // 语言偏好
}

export class AppointmentDateLimit {
  dayLimit: number; // 限制预约日期，最早可以选择日期，0/没有设置代表不限制，1代表最早能选择当前日期+1，可以为负数代表提前；
  minutes: any[]; // 分钟数 (可为空)
  timeEndLimit: string; // 选择时间点结束限制 格式: HH:mm 24小时格式 (可为空)
  timeStartLimit: string; // 选择时间点开始限制 格式: HH:mm 24小时格式
}
