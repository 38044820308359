<div class="drawer-container">

  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">

    <div class="common-title" style="margin-bottom: 40px">
      {{'BookingInfo' | translate}}
    </div>

<!--    <div *ngIf="!canSave" class="tip">-->
<!--      <span style="color: #1D64C4"><i nz-icon nzType="info-circle" nzTheme="outline"></i></span>-->
<!--      <span style="color: #1D64C4; margin: 5px 5px 5px 5px">{{ tip }}</span>-->
<!--    </div>-->

    <app-property-select [title]="'ApplicationType' | translate"
                         [placeholder]="('PleaseSelect' | translate) + ('ApplicationType' | translate)"
                         [required]="true"
                         [options]="applicationTypeOptions"
                         [(value)]="booking.applicationType"></app-property-select>

    <div class="section-wrapper">
      <!-- 是否预核保 -->
      <app-property-switch  *ngIf="instance.isWanson()" [title]="'PreUnderwriting' | translate"
                            [disabled]="!preUnderwritingEdit"
                            [value]="booking.preUnderwriting"
                            (valueChange)="onPreUnderwritingChange($event)"></app-property-switch>

      <div class="property-row">
        <div class="property-title-row">{{ 'PreferredTime' | translate }}</div>
        <img *ngIf="isPreferredTime()" alt="" width="24" src="assets/images/ic_required.svg">
        <div class="property-value-row">
          <nz-date-picker
            class="date-picker-row" nzBorderless
            [nzShowTime]="{ nzFormat: 'HH:mm',nzHideDisabledOptions: true }"
            nzFormat="yyyy-MM-dd HH:mm"
            [nzShowNow]="false"
            [nzMode]="'date'"
            [ngModel]="startValue"
            [nzDisabledTime]="disabledDateTime"
            [nzDisabledDate]="disabledDate"
            (ngModelChange)="temporaryTimeChange($event)"
          ></nz-date-picker>
        </div>
      </div>
      <div class="property-bottom-line"></div>
<!--      <app-property-datetime [title]="'PreferredTime' | translate"-->
<!--                             [formatter]="'yyyy-MM-dd HH:mm'"-->
<!--                             [showTime]="{nzFormat: 'HH:mm'}"-->
<!--                             [required]="!booking.preUnderwriting"-->
<!--                             [(value)]="booking.temporaryTime"></app-property-datetime>-->

<!--      销售渠道-->
      <app-property-pick [title]="'Referee' | translate"
                         [valueCursor]="(isCreate ? 'auto' : 'no-drop')"
                         appThrottleClick (throttleClick)="onSub('Referee')"
                         [value]="booking.nickName"></app-property-pick>

      <app-property-pick *ngIf="workbench.secondAccountName ? !isCreate ? booking.secondNickName : true : false"
                         [title]="('Referee' | translate) + '2'"
                         [valueCursor]="(isCreate ? 'auto' : 'no-drop')"
                         appThrottleClick (throttleClick)="onSub('Referee2')"
                         [value]="booking.secondNickName"></app-property-pick>

      <!-- 顧問姓名 -->
      <app-property-input [title]="'ConsultantName' | translate"
                          [placeholder]="('PleaseEnter' | translate) + ('ConsultantName' | translate)"
                          [required]="false"
                          [(value)]="booking.accompanyName"></app-property-input>
      <!-- 顧問手機 -->
      <app-property-pick [title]="'ConsultantPhone' | translate"
                         [required]="false"
                         appThrottleClick (throttleClick)="onConsultantPhone()"
                         [value]="booking.consultantPhoneString()"></app-property-pick>
      <!-- 顧問郵箱 -->
      <app-property-input [title]="'ConsultantEmail' | translate"
                          [placeholder]="('PleaseEnter' | translate) + ('ConsultantEmail' | translate)"
                          [required]="false"
                          [bottomLineVisible]="instance.isHarvest()"
                          [(value)]="booking.accompanyEmail"></app-property-input>
      <div *ngIf="instance.isHarvest()">
        <!-- 服务代表 -->
        <app-property-pick [title]="('ServiceAgent' | translate) + '1'"
                           appThrottleClick (throttleClick)="onSub('ServiceAgent')"
                           [required]="true"
                           [value]="booking.serviceAgent"></app-property-pick>

        <!-- 服务代表 -->
        <app-property-pick [title]="('ServiceAgent' | translate) + '2'"
                           [bottomLineVisible]="false"
                           appThrottleClick (throttleClick)="onSub('ServiceAgent2')"
                           [value]="booking.serviceAgent2"></app-property-pick>
      </div>
    </div>

    <div class="section-wrapper" *ngIf="booking.trAccountName && !booking.hideTr">
      <!-- 签单员1 -->
      <app-property-text [title]="('TechnicalRepresentative' | translate) +'1 '+ ('Account' | translate)"
                         [value]="booking.trAccountName"></app-property-text>
      <!-- 姓名 -->
      <app-property-text [title]="('TechnicalRepresentative' | translate) +'1 '+ ('FullName' | translate)"
                         [value]="booking.trName"></app-property-text>
      <!-- 手机号 -->
      <app-property-text [title]="('TechnicalRepresentative' | translate) +'1 '+ ('PhoneNumber' | translate)"
                         [value]="'+'+booking.trAreaCode +' '+ booking.trPhone"></app-property-text>
      <!-- 邮箱 -->
      <app-property-text [title]="('TechnicalRepresentative' | translate) +'1 '+ ('Email' | translate)"
                         [bottomLineVisible]="false"
                         [value]="booking.trEmail"></app-property-text>
    </div>
    <div class="section-wrapper" *ngIf="booking.tr2AccountName && !booking.hideTr">
      <!-- 签单员2 -->
      <app-property-text [title]="('TechnicalRepresentative' | translate) +'2 '+ ('Account' | translate)"
                         [value]="booking.tr2AccountName"></app-property-text>
      <!-- 姓名 -->
      <app-property-text [title]="('TechnicalRepresentative' | translate) +'2 '+ ('FullName' | translate)"
                         [value]="booking.tr2Name"></app-property-text>
      <!-- 电话 -->
      <app-property-text [title]="('TechnicalRepresentative' | translate) +'2 '+ ('PhoneNumber' | translate)"
                         [value]="'+'+booking.tr2AreaCode +' '+ booking.tr2Phone"></app-property-text>
      <!-- 邮箱 -->
      <app-property-text [title]="('TechnicalRepresentative' | translate) +'2 '+ ('Email' | translate)"
                         [bottomLineVisible]="false"
                         [value]="booking.tr2Email"></app-property-text>
    </div>
    <div class="section-wrapper" *ngIf="booking.tr3AccountName && !booking.hideTr">
      <!-- 签单员3 -->
      <app-property-text [title]="('TechnicalRepresentative' | translate) +'3 '+ ('Account' | translate)"
                         [value]="booking.tr3AccountName"></app-property-text>
      <!-- 姓名 -->
      <app-property-text [title]="('TechnicalRepresentative' | translate) +'3 '+ ('FullName' | translate)"
                         [value]="booking.tr3Name"></app-property-text>
      <!-- 手机号 -->
      <app-property-text [title]="('TechnicalRepresentative' | translate) +'3 '+ ('PhoneNumber' | translate)"
                         [value]="'+'+booking.tr3AreaCode +' '+ booking.tr3Phone"></app-property-text>
      <!-- 邮箱 -->
      <app-property-text [title]="('TechnicalRepresentative' | translate) +'3 '+ ('Email' | translate)"
                         [bottomLineVisible]="false"
                         [value]="booking.tr3Email"></app-property-text>
    </div>
    <div class="section-wrapper">
      <!-- 到港時間 -->
      <app-property-datetime [title]="'ArriveTime' | translate"
                             [formatter]="'yyyy-MM-dd HH:mm'"
                             [showTime]="{nzFormat: 'HH:mm'}"
                             [(value)]="booking.arriveTime"></app-property-datetime>
      <!-- 離港時間 -->
      <app-property-datetime [title]="'DepartureTime' | translate"
                             [formatter]="'yyyy-MM-dd HH:mm'"
                             [showTime]="{nzFormat: 'HH:mm'}"
                             [(value)]="booking.leaveTime"></app-property-datetime>
      <!-- 會面地點 -->
      <app-property-select *ngIf="!instance.isIAM()" [title]="'MeetingLocation' | translate"
                           [placeholder]="('PleaseSelect' | translate) + ('MeetingLocation' | translate)"
                           [options]="meetingLocationOptions"
                           [(value)]="booking.meetingPlace"></app-property-select>

      <app-property-input [title]="instance.isFone() ? ('SigningAddress' | translate) : ('MeetingAddress' | translate)"
                          [placeholder]="('PleaseEnter' | translate) + (instance.isFone() ? ('SigningAddress' | translate) : ('MeetingAddress' | translate))"
                          [(value)]="booking.appointmentAddress"></app-property-input>
      <!-- 客户在港期间联络电话 -->
      <app-property-pick [title]="'HongKongContactNumber' | translate"
                         appThrottleClick (throttleClick)="onHongKongContactNumber()"
                         [value]="booking.clientContactNoInHongKong()"></app-property-pick>
      <!-- 是否开户 -->
      <app-property-yes-or-not *ngIf="!instance.isIAM()" [title]="'OpenBankAccount' | translate"
                               [(value)]="booking.openBankAccount"></app-property-yes-or-not>

      <div *ngIf="booking.openBankAccount">

        <div *ngIf="workbench.bookingChargeRemark" style="margin: 20px 0 10px 0; padding-bottom: 10px; color: #666666">
          <div [innerHTML]="workbench.bookingChargeRemark | html"></div>
          <div class="property-bottom-line" style="margin-top: 10px"></div>
        </div>

        <!--   开户行名称   -->
        <app-property-input [title]="'OpenAccountBankName' | translate"
                            [placeholder]="('PleaseEnter' | translate) + ('OpenAccountBankName' | translate)"
                            [required]="(instance?.key === 'wanson' || instance?.key === 'dev')"
                            [(value)]="booking.openAccountInfo.openAccountBankName"></app-property-input>
        <!--   开户行支行（分行）  -->
        <app-property-input [disabled]="instance?.key === 'wanson' || instance?.key === 'dev'"
                            [title]="'OpenAccountBranchBankName' | translate"
                            [placeholder]="('PleaseEnter' | translate) + ('OpenAccountBranchBankName' | translate)"
                            [(value)]="booking.openAccountInfo.openAccountBranchBankName"></app-property-input>
        <!--   開戶時間   -->
        <app-property-datetime-string [title]="'StartTimeOpenAccount' | translate"
                                      [formatter]="'yyyy-MM-dd HH:mm'"
                                      [showTime]="{nzFormat: 'HH:mm'}"
                                      [required]="instance.isBailu()"
                                      [(value)]="booking.openAccountInfo.openAccountDate">
        </app-property-datetime-string>
        <!--   開戶時間段结束   -->
        <app-property-datetime-string [title]="'EndTimeOpenAccount' | translate"
                                      [formatter]="'yyyy-MM-dd HH:mm'"
                                      [showTime]="{nzFormat: 'HH:mm'}"
                                      [required]="instance.isBailu()"
                                      [(value)]="booking.openAccountInfo.openAccountEndDate">
        </app-property-datetime-string>
        <!--   開戶地點-->
        <app-property-input [disabled]="instance?.key === 'wanson' || instance?.key === 'dev'"
                            [title]="'OpenAccountAddress' | translate"
                            [placeholder]="('PleaseEnter' | translate) + ('OpenAccountAddress' | translate)"
                            [(value)]="booking.openAccountInfo.openAccountAddress">
        </app-property-input>
        <!--   開戶須知   -->
        <app-property-input-area [disabled]="instance?.key === 'wanson' || instance?.key === 'dev'"
                                 [title]="'OpenAccountInstructions' | translate"
                                 [placeholder]="('PleaseEnter' | translate) + ('OpenAccountInstructions' | translate)"
                                 [(value)]="booking.openAccountInfo.openAccountInstructions">
        </app-property-input-area>
      </div>

      <!-- 是否体检 -->
      <app-property-yes-or-not *ngIf="!instance.isIAM()" [title]="'HealthCheck' | translate"
                               [(value)]="booking.healthCheck"></app-property-yes-or-not>

      <!-- 所需資料 -->
      <app-property-texts [title]="'RequiredInformation' | translate"
                          [value]="requiredInformation"></app-property-texts>

      <app-property-switch  [title]="'hongKongInsurance' | translate"
                            [bottomLineVisible]="false"
                            [(value)]="booking.hongKongInsurance"></app-property-switch>
    </div>
    <div class="section-wrapper" *ngIf="workbench.showVehicleServiceConfig">
      <!-- VIP客戶 -->
<!--      <app-property-switch  *ngIf="isFone" [title]="'VIPClient' | translate"-->
<!--                           [value]="vipOption"-->
<!--                           [bottomLineVisible]="vipOption"-->
<!--                           (valueChange)="onVipOptionChange($event)"></app-property-switch>-->
      <app-property-switch *ngIf="!isFone" [title]="'VehicleService' | translate"
                           [value]="vipOption"
                           [bottomLineVisible]="vipOption"
                           (valueChange)="onVipOptionChange($event)"></app-property-switch>
      <div *ngIf="showBookingVipChargeRemark" style="margin: 20px 0 10px 0; padding-bottom: 10px; color: #666666">
        <div [innerHTML]="workbench.bookingVipChargeRemark | html"></div>
        <div class="property-bottom-line" style="margin-top: 10px"></div>
      </div>

      <div *ngIf="workbench.showVehicleServiceConfig">
<!--        <div *ngIf="isFone">-->
<!--          <app-booking-vip-edit [(vip)]="booking.vip"></app-booking-vip-edit>-->
<!--        </div>-->
        <div *ngIf="vipOption">
          <app-booking-vip-edit-v2 [(vip)]="booking.vip" [isShow]="isEdit" [disabled]="isEdit"></app-booking-vip-edit-v2>
        </div>
      </div>

    </div>

<!--    <div class="section-wrapper" *ngIf="(instance?.key === 'wanson' || instance?.key === 'dev') && isEdit">-->
<!--      <app-property-text [title]="'BookingRemark' | translate"-->
<!--                         [bottomLineVisible]="false"-->
<!--                         [value]="booking.special"></app-property-text>-->
<!--    </div>-->

  </nz-spin>

  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
<!--      <div *ngIf="!canSave" nz-col nzSpan="24" class="footer-button">-->
<!--        <div class="flex-row" style="height: 100%">-->
<!--          <span style="color: #1D64C4"><i nz-icon nzType="info-circle" nzTheme="outline"></i></span>-->
<!--          <span style="color: #1D64C4; font-weight: bold; margin: 5px 5px 5px 5px">{{ tip }}</span>-->
<!--        </div>-->
<!--      </div>-->
      <div nz-col nzSpan="24" class="footer-button-primary"
           nz-tooltip [nzTooltipTitle]="tip">
        <a nz-button
           [disabled]="!canSave"
           [style.opacity]="canSave ? '1' : '0.5'"
           nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onSave()">
          <em nz-icon nzType="save" nzTheme="outline"></em>
          <span>{{'Save' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

</div>
