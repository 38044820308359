import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  PremiumFinancingCreate,
  PremiumFinancingDetail,
  PremiumFinancingProposalSearch
} from '../premium-financing-types';
import { PropertySelectOption } from '../../shared/component/property-select/property-select.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-financing-excel-detail',
  templateUrl: './financing-excel-detail.component.html',
  styleUrls: ['./financing-excel-detail.component.less']
})
export class FinancingExcelDetailComponent implements OnInit {
  loading = false;

  premiumFinancing: PremiumFinancingCreate = new PremiumFinancingCreate();

  info: PremiumFinancingDetail = new PremiumFinancingDetail();

  fullOptions: PropertySelectOption[] = [];
  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
  }

  get proposalLabel(): string {
    if (!this.info.premiumFinancing || !this.info.premiumFinancing.proposalId) {
      return '';
    }
    return (this.info.productName ?? '') + ' | ' + (this.info.premiumFinancing.proposalId ?? '');
  }


  fullOrPartialStr(val: string): string {
    let str = '';
    if (val) {
      switch (val) {
        case 'FULL':
          str = this.translate.instant('WCFull');
          break;
        case 'PARTIAL':
          str = this.translate.instant('PFFull');
          break;
      }
    }
    return str;
  }

}
