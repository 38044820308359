<div class="drawer-container" style="padding-top: 16px;">
  <div *ngIf="product.isUpdate" class="upData-info">
    <i nz-icon nzType="sync" nzTheme="outline" style="vertical-align: -2px;"></i>
    {{ 'Updating' | translate }}
  </div>

  <div class="drawer-title-small" [style]="product.isUpdate ? 'margin-bottom: 50px;' : 'margin-bottom: 30px;'">
    {{'ProductDetail' | translate}}
  </div>
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div>
      <div style="display: flex; flex-direction: row; align-items: center; justify-content: flex-start">
        <div style="font-weight: bold; font-size: 24px; color: #404040 ">{{product.productName}}</div>
        <div>
          <span class="tag" style="margin: 0 0 0 10px">
            {{product.categoryName}}
          </span>
        </div>
      </div>
      <div style="margin: 8px 0 0 0">
        <app-read-more style="color: #A6A6A6; font-size: 14px" [text]="product.desc"></app-read-more>
      </div>

      <div class="flex-row">
        <div class="detail-button btn_bg_l"
             [style.opacity]="product.comparable ? '1' : '0.5'"
             [style.cursor]="product.comparable ? 'pointer' : 'not-allowed'"
             (click)="onComparison()">
          <div class="inner-container">
            <img width="30" src="assets/images/ic_comparison.svg" alt="">
            <span class="text">{{ 'ProductCompare' | translate }}</span>
            <i nz-icon style="color: #003781;font-size: 18px;" nzType="right" nzTheme="outline"></i>
          </div>
        </div>
        <div class="detail-button btn_bg_r"
             [style.opacity]="product.quotable ? '1' : '0.5'"
             [style.cursor]="product.quotable ? 'pointer' : 'not-allowed'"
             (click)="onQuotation()">
          <div class="inner-container">
            <img width="30" src="assets/images/ic_quotation.svg" alt="">
            <span class="text">{{ 'QuotationCalculate' | translate }}</span>
            <i nz-icon style="color: #003781;font-size: 18px;" nzType="right" nzTheme="outline"></i>
          </div>
        </div>
      </div>
    </div>
    <nz-tabset [nzSize]="'large'" [nzCentered]="false">
      <nz-tab [nzTitle]=properties>
        <app-product-attributes [product]="product"></app-product-attributes>
      </nz-tab>
      <ng-template #properties>
        {{'ProductInfo' | translate}}
      </ng-template>
      <nz-tab [nzTitle]=highlights>
        <app-product-highlights [product]="product"></app-product-highlights>
      </nz-tab>
      <ng-template #highlights>
        {{'ProductHighlights' | translate}}
      </ng-template>
      <nz-tab [nzTitle]=news>
        <app-product-news [product]="product"></app-product-news>
      </nz-tab>
      <ng-template #news>
        {{'ProductNews' | translate}}
      </ng-template>
      <nz-tab [nzTitle]=officialDocs>
        <app-attachments [attachments]="product.files" [readonly]="true"></app-attachments>
      </nz-tab>
      <ng-template #officialDocs>
        {{'OfficialDocs' | translate}}
      </ng-template>
      <nz-tab [nzTitle]=internalDocs>
        <app-attachments [attachments]="product.customFiles" [readonly]="true"></app-attachments>
      </nz-tab>
      <ng-template #internalDocs>
        {{'InternalDocs' | translate}}
      </ng-template>
    </nz-tabset>
  </nz-spin>
  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="12" class="footer-button">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onProposalApply()">
          <span>{{'ProposalApply' | translate}}</span>
        </a>
      </div>
      <div nz-col nzSpan="12" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onReservation()">
          <span>{{'MakeReservationNow' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

</div>
