import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Workbench } from '../../../workbench/workbench-types';
import { WorkbenchService } from '../../../workbench/workbench.service';
import { DataService } from '../../service/data.service';
import { BookingService } from '../../../booking/booking.service';
import { BookingListResp, BookingSearch } from '../../../booking/booking-types';
import { DataDesensitization, DataMode, Instance, PagedResp, UnderWritingListResp } from '../../../api/types';
import { BaseComponent } from '../../../base/base.component';
import { UnderwritingService } from '../../../underwriting/underwriting.service';
import { PolicyListResp, PolicySearch } from '../../../policy/policy-types';
import { PolicyService } from '../../../policy/policy.service';
import { UnderwritingSearch } from '../../../underwriting/underwriting-types';
import { debounceTime, finalize, tap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { Tool } from '../../utils/tool';
import { AccountService } from '../../../account/account.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-common-search',
  templateUrl: './common-search.component.html',
  styleUrls: ['./common-search.component.less']
})
export class CommonSearchComponent extends BaseComponent implements OnInit, OnDestroy {
  searchVisible = false;
  loading = false;
  searchValue: string;
  workbench: Workbench;
  selectIndex = 0;
  bookingList: PagedResp<BookingListResp>;
  bookingListSub: PagedResp<BookingListResp>;
  bookingListFoll: PagedResp<BookingListResp>;
  bookingListGroup: PagedResp<BookingListResp>;
  underwritingList: PagedResp<UnderWritingListResp>;
  underwritingListSub: PagedResp<UnderWritingListResp>;
  underwritingListFoll: PagedResp<UnderWritingListResp>;
  underwritingListGroup: PagedResp<UnderWritingListResp>;
  policyList: PagedResp<PolicyListResp>;
  policyListSub: PagedResp<PolicyListResp>;
  policyListFoll: PagedResp<PolicyListResp>;
  policyListGroup: PagedResp<PolicyListResp>;
  isViewList = true;
  tableTitle = '';
  tableMode = '';
  listOfData: PagedResp<BookingListResp>;
  listOfUnderwriting: PagedResp<UnderWritingListResp>;
  listOfPolicy: PagedResp<PolicyListResp>;
  dataDesensitization: DataDesensitization = new DataDesensitization();
  maskMode: DataMode;
  instance: Instance;

  bookingSearch: BookingSearch = new BookingSearch();
  underwritingSearch: UnderwritingSearch = new UnderwritingSearch();
  policySearch: PolicySearch = new PolicySearch();

  @Output()
  valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  searchParamChange: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  get value(): boolean {
    return this.searchVisible;
  }

  set value(val: boolean) {
    this.searchVisible = val;
    this.valueChange.emit(this.searchVisible);
  }
  constructor(private router: Router,
              public dataService: DataService,
              private policyService: PolicyService,
              private accountService: AccountService,
              private bookingService: BookingService,
              private workbenchService: WorkbenchService,
              private underwritingService: UnderwritingService) {
    super();
  }

  ngOnInit(): void {
    this.workbench = this.workbenchService.workbench;
    this.instance = this.workbenchService.mockInstance;
    this.searchParamChange.pipe(debounceTime(500))
      .subscribe(
        data => {
          this.tabsIndex(this.selectIndex);
        }
      );
    this.dataDesensitization = this.accountService.dataDesensitization;
  }

  OnDestroy(): void {
  }

  handleCancel(): void {
    this.init();
    this.searchVisible = false;
    this.selectIndex = 0;
    this.isViewList = true;
    this.valueChange.emit(this.searchVisible);
  }

  handleOk(): void {
    this.searchVisible = false;
    this.valueChange.emit(this.searchVisible);
  }

  clearSearch(): void {
    this.searchValue = '';
    this.init();
  }

  async onBookingMode(): Promise<void> {
    this.loading = true;
    try {
      await Promise.all([
        this.onBookingList(DataMode.OWN),
        (this.workbench.pivot !== this.dataService.pivotOwn)
          ? this.onBookingList(DataMode.SUBORDINATE)
          : Promise.resolve(),
        this.workbench.tr
          ? this.onBookingList(DataMode.FOLLOWUP)
          : Promise.resolve(),
        this.onBookingList(DataMode.SALESGROUP)
      ]);
    } catch (err) {
      console.error('Error in onUnderwritingMode:', err);
    } finally {
      this.loading = false;
    }
  }

  onBookingList(mode: DataMode): Promise<void> {
    return new Promise((resolve, reject) => {
      const search = new BookingSearch();
      search.searchKey = this.searchValue;
      this.bookingService.list(search, mode).subscribe(
        data => {
          switch (mode) {
            case DataMode.OWN:
              this.bookingList = data;
              break;
            case DataMode.SUBORDINATE:
              this.bookingListSub = data;
              break;
            case DataMode.FOLLOWUP:
              this.bookingListFoll = data;
              break;
            case DataMode.SALESGROUP:
              this.bookingListGroup = data;
              break;
          }
          resolve(); // 请求成功时调用 resolve
        },
        error => {
          console.error('Error in onUnderwritingList:', error);
          reject(error); // 请求失败时调用 reject
        }
      ).disposedBy(this.disposeBag);
    });
  }

  onUnderwritingMode(): void {
    this.loading = true;
    // 构建所有请求的数组
    const observables = [
      this.onUnderwritingList(DataMode.OWN) // OWN 请求
    ];

    if (this.workbench.pivot !== this.dataService.pivotOwn) {
      observables.push(this.onUnderwritingList(DataMode.SUBORDINATE)); // SUBORDINATE 请求
    }

    if (this.workbench.tr) {
      observables.push(this.onUnderwritingList(DataMode.FOLLOWUP)); // FOLLOWUP 请求
    }

    observables.push(this.onUnderwritingList(DataMode.SALESGROUP)); // SALESGROUP 请求

    // 使用 forkJoin 来等待所有请求完成
    forkJoin(observables)
      .pipe(finalize(() => this.loading = false)) // 所有请求完成后设置 loading 为 false
      .subscribe(
        () => {
          // 可以在这里处理成功的逻辑
        },
        error => {
          // 可以在这里处理错误的逻辑
        }
      );
  }

  onUnderwritingList(mode: DataMode): Observable<PagedResp<UnderWritingListResp>> {
    const search = new UnderwritingSearch();
    search.searchKey = this.searchValue;
    // 返回 Observable，让 forkJoin 来处理
    return this.underwritingService.list(search, mode).pipe(
      // 根据不同的 mode 处理数据
      tap(data => {
        switch (mode) {
          case DataMode.OWN:
            this.underwritingList = data;
            break;
          case DataMode.SUBORDINATE:
            this.underwritingListSub = data;
            break;
          case DataMode.FOLLOWUP:
            this.underwritingListFoll = data;
            break;
          case DataMode.SALESGROUP:
            this.underwritingListGroup = data;
            break;
        }
      })
    );
  }

  async onPolicyMode(): Promise<void> {
    this.loading = true;
    try {
      await Promise.all([
        this.onPolicyListPromise(DataMode.OWN),
        (this.workbench.pivot !== this.dataService.pivotOwn)
          ? this.onPolicyListPromise(DataMode.SUBORDINATE)
          : Promise.resolve(),
        this.workbench.tr
          ? this.onPolicyListPromise(DataMode.FOLLOWUP)
          : Promise.resolve(),
        this.onPolicyListPromise(DataMode.SALESGROUP)
      ]);
    } catch (err) {
      console.error('Error in onUnderwritingMode:', err);
    } finally {
      this.loading = false;
    }
  }

  onPolicyListPromise(mode: DataMode): Promise<void> {
    return new Promise((resolve, reject) => {
      const search = new PolicySearch();
      search.searchKey = this.searchValue;
      this.policyService.list(search, mode).subscribe(
        data => {
          switch (mode) {
            case DataMode.OWN:
              this.policyList = data;
              break;
            case DataMode.SUBORDINATE:
              this.policyListSub = data;
              break;
            case DataMode.FOLLOWUP:
              this.policyListFoll = data;
              break;
            case DataMode.SALESGROUP:
              this.policyListGroup = data;
              break;
          }
          resolve(); // 请求成功时调用 resolve
        },
        error => {
          console.error('Error in onUnderwritingList:', error);
          reject(error); // 请求失败时调用 reject
        }
      ).disposedBy(this.disposeBag);
    });
  }

  get isEmptyBooking(): boolean {
    return !!this.bookingList?.list?.length || !!this.bookingListSub?.list?.length || !!this.bookingListFoll?.list?.length || !!this.bookingListGroup?.list?.length;
  }

  get isEmptyUnderwriting(): boolean {
    return !!this.underwritingList?.list?.length || !!this.underwritingListSub?.list?.length || !!this.underwritingListFoll?.list?.length || !!this.underwritingListGroup?.list?.length;
  }

  get isEmptyPolicy(): boolean {
    return !!this.policyList?.list?.length || !!this.policyListSub?.list?.length || !!this.policyListFoll?.list?.length || !!this.policyListGroup?.list?.length;
  }

  tabsIndex(e: number): void {
    if (!this.searchValue) { return; }
    switch (e) {
      case 0:
        if (this.isViewList) {
          this.onBookingMode();
        } else {
          this.load();
        }
        break;
      case 1:
        if (this.isViewList) {
          this.onUnderwritingMode();
        } else {
          this.loadUnderwriting();
        }
        break;
      case 2:
        if (this.isViewList) {
          this.onPolicyMode();
        } else {
          this.loadPolicy();
        }
        break;
    }
  }

  onSearchChange(): void {
    if (this.searchValue) {
      this.bookingSearch.searchKey = this.searchValue;
      this.underwritingSearch.searchKey = this.searchValue;
      this.policySearch.searchKey = this.searchValue;
      this.searchParamChange.emit('');
    } else {
      this.init();
    }
  }

  init(): void {
    this.searchValue = null;
    this.bookingList = new PagedResp<BookingListResp>();
    this.bookingListSub = new PagedResp<BookingListResp>();
    this.bookingListFoll = new PagedResp<BookingListResp>();
    this.bookingListGroup = new PagedResp<BookingListResp>();
    this.listOfData = new PagedResp<BookingListResp>();
    this.underwritingList = new PagedResp<UnderWritingListResp>();
    this.underwritingListSub = new PagedResp<UnderWritingListResp>();
    this.underwritingListFoll = new PagedResp<UnderWritingListResp>();
    this.underwritingListGroup = new PagedResp<UnderWritingListResp>();
    this.listOfUnderwriting = new PagedResp<UnderWritingListResp>();
    this.policyList = new PagedResp<PolicyListResp>();
    this.policyListSub = new PagedResp<PolicyListResp>();
    this.policyListFoll = new PagedResp<PolicyListResp>();
    this.policyListGroup = new PagedResp<PolicyListResp>();
    this.listOfPolicy = new PagedResp<PolicyListResp>();
  }

  onViewMore(title: string, mode: string): void {
    this.tableTitle = title;
    console.log('onViewMore');
    this.tableMode = mode;
    switch (title) {
      case 'MyDeals':
        this.listOfData = this.bookingList;
        this.listOfUnderwriting = this.underwritingList;
        this.listOfPolicy = this.policyList;
        this.maskMode = DataMode.OWN;
        break;
      case 'MySubordinates':
        this.listOfData = this.bookingListSub;
        this.listOfUnderwriting = this.underwritingListSub;
        this.listOfPolicy = this.policyListSub;
        this.maskMode = DataMode.SUBORDINATE;
        break;
      case 'MyToDo':
        this.listOfData = this.bookingListFoll;
        this.listOfUnderwriting = this.underwritingListFoll;
        this.listOfPolicy = this.policyListFoll;
        this.maskMode = DataMode.FOLLOWUP;
        break;
      case 'MySalesGroup':
        this.listOfData = this.bookingListGroup;
        this.listOfUnderwriting = this.underwritingListGroup;
        this.listOfPolicy = this.policyListGroup;
        this.maskMode = DataMode.SALESGROUP;
        break;
    }
    this.isViewList = false;
  }

  onBack(): void {
    this.isViewList = true;
    this.tabsIndex(this.selectIndex);
    console.log('onBack');
  }

  dynamicEncryption(keyName, val, mode): string {
    let isShow = val;
    if (mode === 'SUBORDINATE') {
      this.dataDesensitization?.subordinates?.map(item => {
        if (item.name === keyName && item.mask) {
          isShow = Tool.dataMasking(val);
        }
      });
      return isShow;
    } else {
      this.dataDesensitization?.mines?.map(item => {
        if (item.name === keyName && item.mask) {
          isShow = Tool.dataMasking(val);
        }
      });
    }
    return isShow;
  }

  onPageSizeChange(pageSize: number): void {
    switch (this.tableMode) {
      case 'booking':
        this.bookingSearch.pageSize = pageSize;
        this.load();
        break;
      case 'underwriting':
        this.underwritingSearch.pageSize = pageSize;
        this.loadUnderwriting();
        break;
      case 'policy':
        this.policySearch.pageSize = pageSize;
        this.loadPolicy();
        break;
    }
  }

  onSearch(pageNum: number): void {
    switch (this.tableMode) {
      case 'booking':
        this.bookingSearch.pageNum = pageNum;
        this.load();
        break;
      case 'underwriting':
        this.underwritingSearch.pageNum = pageNum;
        this.loadUnderwriting();
        break;
      case 'policy':
        this.policySearch.pageNum = pageNum;
        this.loadPolicy();
        break;
    }
  }

  load(): void {
    if (!this.searchValue) { return; }
    this.loading = true;
    this.bookingService.list(this.bookingSearch, this.maskMode)
      .subscribe(
        data => {
          this.loading = false;
          this.listOfData = data;
        },
        error => {
          this.loading = false;
        })
      .disposedBy(this.disposeBag);
  }

  loadUnderwriting(): void {
    if (!this.searchValue) { return; }
    this.loading = true;
    this.underwritingService.list(this.underwritingSearch, this.maskMode)
      .subscribe(
        data => {
          this.loading = false;
          this.listOfUnderwriting = data;
        },
        error => {
          this.loading = false;
        })
      .disposedBy(this.disposeBag);
  }

  loadPolicy(): void {
    if (!this.searchValue) { return; }
    this.loading = true;
    this.policyService.list(this.policySearch, this.maskMode)
      .subscribe(
        data => {
          this.loading = false;
          this.listOfPolicy = data;
        },
        error => {
          this.loading = false;
        })
      .disposedBy(this.disposeBag);
  }

  onViewBooing(list: BookingListResp): void {
    console.log('BookingListResp');
    this.router.navigate(['/user/booking'], {queryParams: {bookingNo: list.bookingNo, mode: this.maskMode}}).then();
    this.handleCancel();
  }

  onViewUnderwriting(list: UnderWritingListResp): void {
    console.log('BookingListResp');
    this.router.navigate(['/user/underwriting'], {queryParams: {underwritingNo: list.underwritingNo, mode: this.maskMode}}).then();
    this.handleCancel();
  }

  onViewPolicy(list: PolicyListResp): void {
    console.log('BookingListResp');
    this.router.navigate(['/user/policy'], {queryParams: {policyNo: list.policyNo, mode: this.maskMode}}).then();
    this.handleCancel();
  }

  isShowTab(str: string): boolean {
    let flag = false;
    this.workbenchService.workbench.features.map(item => {
      item.squares.map(info => {
        if (info.name === str) {
          flag = info.enable;
        }
      });
    });
    return flag;
  }

}
