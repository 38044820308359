<nz-modal [(nzVisible)]="searchVisible"
          [nzTitle]="null"
          [nzContent]="modalContent"
          [nzClosable]="false"
          [nzFooter]="null"
          [nzWidth]="1200"
          (nzOnCancel)="handleCancel()">
  <ng-template #modalContent>
    <div class="search-input-box">
      <img nz-tooltip nzTooltipPlacement="bottom" alt="" width="24"
           src="assets/images/search_input.svg" style="font-size: 16px;">

      <nz-input-group class="search-input" [nzSuffix]="suffixIconSearch">
        <input nz-input
               [placeholder]="'Search' | translate"
               [(ngModel)]="searchValue"
               [nzBorderless]="true"
               (ngModelChange)="onSearchChange()"/>
      </nz-input-group>
      <ng-template #suffixIconSearch>
        <span *ngIf="searchValue" style="cursor: pointer;color: #00000073;"
              appThrottleClick (throttleClick)="clearSearch()">{{'Clear' | translate}}</span>
      </ng-template>
      <span class="close-icon"
            appThrottleClick
            (throttleClick)="handleCancel()">
        <i nz-icon nzType="close" nzTheme="outline"></i>
      </span>
    </div>


    <div *ngIf="isViewList" class="search-tabs">
      <nz-tabset [nzSize]="'large'" [(nzSelectedIndex)]="selectIndex" (nzSelectedIndexChange)="tabsIndex($event)">
        <nz-tab [nzTitle]=booking *ngIf="isShowTab('BOOKING')">
          <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
          <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
            <div *ngIf="isEmptyBooking"
                 style="height: 630px;overflow: hidden;overflow-y: auto;">
              <div class="tab-card" *ngIf="bookingList?.list?.length">
                <div class="card-top">
                  <div>{{'MyDeals' | translate}}</div>
                  <a nz-button nzType="link" nzSize="small" style="color: #1D64C4; font-size: 14px;"
                     appThrottleClick (throttleClick)="onViewMore('MyDeals', 'booking')">
                    {{'ViewMore' | translate}}
                    <i nz-icon nzType="right" nzTheme="outline"></i>
                  </a>
                </div>
                <div *ngFor="let booking of bookingList.list; let i = index">
                  <div class="card-list" *ngIf="i < 2">
                    <div class="num-no" [title]="booking.bookingNo">
                      <img nz-tooltip nzTooltipPlacement="bottom" alt="" width="16"
                           src="assets/images/search_no.svg" style="font-size: 16px;">
                      {{'BookingNumber' | translate}} :
                      <span>{{ booking.bookingNo }}</span>
                    </div>
                    <div style="margin-left: 40px;" class="num-no">
                      <img nz-tooltip nzTooltipPlacement="bottom" alt="" width="16"
                           src="assets/images/search_user.svg" style="font-size: 16px;">
                      {{'Referee' | translate}} :
                      <span>{{ booking.salesName }} {{booking.accountName}}</span>
                    </div>
                    <div style="margin-left: 40px;" class="num-no">
                      <img nz-tooltip nzTooltipPlacement="bottom" alt="" width="16"
                           src="assets/images/search_name.svg" style="font-size: 16px;">
                      {{'Holder' | translate}} :
                      <span>{{ booking.name }} {{booking.namePinYin}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-card" *ngIf="workbench.pivot !== dataService.pivotOwn && bookingListSub?.list?.length">
                <div class="card-top">
                  <div>{{'MySubordinates' | translate}}</div>
                  <a nz-button nzType="link" nzSize="small" style="color: #1D64C4; font-size: 14px;"
                     appThrottleClick (throttleClick)="onViewMore('MySubordinates', 'booking')">
                    {{'ViewMore' | translate}}
                    <i nz-icon nzType="right" nzTheme="outline"></i>
                  </a>
                </div>
                <div *ngFor="let booking of bookingListSub.list; let i = index">
                  <div class="card-list" *ngIf="i < 2">
                    <div class="num-no" [title]="booking.bookingNo">
                      <img nz-tooltip nzTooltipPlacement="bottom" alt="" width="16"
                           src="assets/images/search_no.svg" style="font-size: 16px;">
                      {{'BookingNumber' | translate}} :
                      <span>{{ booking.bookingNo }}</span>
                    </div>
                    <div style="margin-left: 40px;" class="num-no">
                      <img nz-tooltip nzTooltipPlacement="bottom" alt="" width="16"
                           src="assets/images/search_user.svg" style="font-size: 16px;">
                      {{'Referee' | translate}} :
                      <span>{{ booking.salesName }} {{booking.accountName}}</span>
                    </div>
                    <div style="margin-left: 40px;" class="num-no">
                      <img nz-tooltip nzTooltipPlacement="bottom" alt="" width="16"
                           src="assets/images/search_name.svg" style="font-size: 16px;">
                      {{'Holder' | translate}} :
                      <span>{{ booking.name }} {{booking.namePinYin}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-card" *ngIf="workbench.tr && bookingListFoll?.list?.length">
                <div class="card-top">
                  <div>{{'MyToDo' | translate}}</div>
                  <a nz-button nzType="link" nzSize="small" style="color: #1D64C4; font-size: 14px;"
                     appThrottleClick (throttleClick)="onViewMore('MyToDo', 'booking')">
                    {{'ViewMore' | translate}}
                    <i nz-icon nzType="right" nzTheme="outline"></i>
                  </a>
                </div>
                <div *ngFor="let booking of bookingListFoll.list; let i = index">
                  <div class="card-list" *ngIf="i < 2">
                    <div class="num-no" [title]="booking.bookingNo">
                      <img nz-tooltip nzTooltipPlacement="bottom" alt="" width="16"
                           src="assets/images/search_no.svg" style="font-size: 16px;">
                      {{'BookingNumber' | translate}} :
                      <span>{{ booking.bookingNo }}</span>
                    </div>
                    <div style="margin-left: 40px;" class="num-no">
                      <img nz-tooltip nzTooltipPlacement="bottom" alt="" width="16"
                           src="assets/images/search_user.svg" style="font-size: 16px;">
                      {{'Referee' | translate}} :
                      <span>{{ booking.salesName }} {{booking.accountName}}</span>
                    </div>
                    <div style="margin-left: 40px;" class="num-no">
                      <img nz-tooltip nzTooltipPlacement="bottom" alt="" width="16"
                           src="assets/images/search_name.svg" style="font-size: 16px;">
                      {{'Holder' | translate}} :
                      <span>{{ booking.name }} {{booking.namePinYin}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-card" *ngIf="bookingListGroup?.list?.length">
                <div class="card-top">
                  <div>{{'MySalesGroup' | translate}}</div>
                  <a nz-button nzType="link" nzSize="small" style="color: #1D64C4; font-size: 14px;"
                     appThrottleClick (throttleClick)="onViewMore('MySalesGroup', 'booking')">
                    {{'ViewMore' | translate}}
                    <i nz-icon nzType="right" nzTheme="outline"></i>
                  </a>
                </div>
                <div *ngFor="let booking of bookingListGroup.list; let i = index">
                  <div class="card-list" *ngIf="i < 2">
                    <div class="num-no" [title]="booking.bookingNo">
                      <img nz-tooltip nzTooltipPlacement="bottom" alt="" width="16"
                           src="assets/images/search_no.svg" style="font-size: 16px;">
                      {{'BookingNumber' | translate}} :
                      <span>{{ booking.bookingNo }}</span>
                    </div>
                    <div style="margin-left: 40px;" class="num-no">
                      <img nz-tooltip nzTooltipPlacement="bottom" alt="" width="16"
                           src="assets/images/search_user.svg" style="font-size: 16px;">
                      {{'Referee' | translate}} :
                      <span>{{ booking.salesName }} {{booking.accountName}}</span>
                    </div>
                    <div style="margin-left: 40px;" class="num-no">
                      <img nz-tooltip nzTooltipPlacement="bottom" alt="" width="16"
                           src="assets/images/search_name.svg" style="font-size: 16px;">
                      {{'Holder' | translate}} :
                      <span>{{ booking.name }} {{booking.namePinYin}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="!isEmptyBooking" style="height: 630px;">
              <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Empty' | translate"></nz-empty>
            </div>
          </nz-spin>
        </nz-tab>
        <ng-template #booking>
          {{'BookingInfo' | translate}}
        </ng-template>
        <nz-tab [nzTitle]=underwriting *ngIf="isShowTab('UNDERWRITING')">
          <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
          <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
            <div *ngIf="isEmptyUnderwriting" style="height: 630px;overflow: hidden;overflow-y: auto;">
              <div class="tab-card" *ngIf="underwritingList?.list?.length">
                <div class="card-top">
                  <div>{{'MyDeals' | translate}}</div>
                  <a nz-button nzType="link" nzSize="small" style="color: #1D64C4; font-size: 14px;"
                     appThrottleClick (throttleClick)="onViewMore('MyDeals', 'underwriting')">
                    {{'ViewMore' | translate}}
                    <i nz-icon nzType="right" nzTheme="outline"></i>
                  </a>
                </div>
                <div *ngFor="let booking of underwritingList.list; let i = index">
                  <div class="card-list" *ngIf="i < 2">
                    <div class="num-no" [title]="booking.underwritingNo">
                      <img nz-tooltip nzTooltipPlacement="bottom" alt="" width="16"
                           src="assets/images/search_no.svg" style="font-size: 16px;">
                      {{'UnderwritingNumber' | translate}} :
                      <span>{{ booking.underwritingNo }}</span>
                    </div>
                    <div style="margin-left: 40px;" class="num-no">
                      <img nz-tooltip nzTooltipPlacement="bottom" alt="" width="16"
                           src="assets/images/search_user.svg" style="font-size: 16px;">
                      {{'Referee' | translate}} :
                      <span>{{ booking.salesName }} {{booking.accountName}}</span>
                    </div>
                    <div style="margin-left: 40px;" class="num-no">
                      <img nz-tooltip nzTooltipPlacement="bottom" alt="" width="16"
                           src="assets/images/search_name.svg" style="font-size: 16px;">
                      {{'Holder' | translate}} :
                      <span>{{ booking.name }} {{booking.pinyin}}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tab-card" *ngIf="workbench.pivot !== dataService.pivotOwn && underwritingListSub?.list?.length">
                <div class="card-top">
                  <div>{{'MySubordinates' | translate}}</div>
                  <a nz-button nzType="link" nzSize="small" style="color: #1D64C4; font-size: 14px;"
                     appThrottleClick (throttleClick)="onViewMore('MySubordinates', 'underwriting')">
                    {{'ViewMore' | translate}}
                    <i nz-icon nzType="right" nzTheme="outline"></i>
                  </a>
                </div>
                <div *ngFor="let booking of underwritingListSub.list; let i = index">
                  <div class="card-list" *ngIf="i < 2">
                    <div class="num-no" [title]="booking.underwritingNo">
                      <img nz-tooltip nzTooltipPlacement="bottom" alt="" width="16"
                           src="assets/images/search_no.svg" style="font-size: 16px;">
                      {{'UnderwritingNumber' | translate}} :
                      <span>{{ booking.underwritingNo }}</span>
                    </div>
                    <div style="margin-left: 40px;" class="num-no">
                      <img nz-tooltip nzTooltipPlacement="bottom" alt="" width="16"
                           src="assets/images/search_user.svg" style="font-size: 16px;">
                      {{'Referee' | translate}} :
                      <span>{{ booking.salesName }} {{booking.accountName}}</span>
                    </div>
                    <div style="margin-left: 40px;" class="num-no">
                      <img nz-tooltip nzTooltipPlacement="bottom" alt="" width="16"
                           src="assets/images/search_name.svg" style="font-size: 16px;">
                      {{'Holder' | translate}} :
                      <span>{{ booking.name }} {{booking.pinyin}}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tab-card" *ngIf="workbench.tr && underwritingListFoll?.list?.length">
                <div class="card-top">
                  <div>{{'MyToDo' | translate}}</div>
                  <a nz-button nzType="link" nzSize="small" style="color: #1D64C4; font-size: 14px;"
                     appThrottleClick (throttleClick)="onViewMore('MyToDo', 'underwriting')">
                    {{'ViewMore' | translate}}
                    <i nz-icon nzType="right" nzTheme="outline"></i>
                  </a>
                </div>
                <div *ngFor="let booking of underwritingListFoll.list; let i = index">
                  <div class="card-list" *ngIf="i < 2">
                    <div class="num-no" [title]="booking.underwritingNo">
                      <img nz-tooltip nzTooltipPlacement="bottom" alt="" width="16"
                           src="assets/images/search_no.svg" style="font-size: 16px;">
                      {{'UnderwritingNumber' | translate}} :
                      <span>{{ booking.underwritingNo }}</span>
                    </div>
                    <div style="margin-left: 40px;" class="num-no">
                      <img nz-tooltip nzTooltipPlacement="bottom" alt="" width="16"
                           src="assets/images/search_user.svg" style="font-size: 16px;">
                      {{'Referee' | translate}} :
                      <span>{{ booking.salesName }} {{booking.accountName}}</span>
                    </div>
                    <div style="margin-left: 40px;" class="num-no">
                      <img nz-tooltip nzTooltipPlacement="bottom" alt="" width="16"
                           src="assets/images/search_name.svg" style="font-size: 16px;">
                      {{'Holder' | translate}} :
                      <span>{{ booking.name }} {{booking.pinyin}}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tab-card" *ngIf="underwritingListGroup?.list?.length">
                <div class="card-top">
                  <div>{{'MySalesGroup' | translate}}</div>
                  <a nz-button nzType="link" nzSize="small" style="color: #1D64C4; font-size: 14px;"
                     appThrottleClick (throttleClick)="onViewMore('MySalesGroup', 'underwriting')">
                    {{'ViewMore' | translate}}
                    <i nz-icon nzType="right" nzTheme="outline"></i>
                  </a>
                </div>
                <div *ngFor="let booking of underwritingListGroup.list; let i = index">
                  <div class="card-list" *ngIf="i < 2">
                    <div class="num-no" [title]="booking.underwritingNo">
                      <img nz-tooltip nzTooltipPlacement="bottom" alt="" width="16"
                           src="assets/images/search_no.svg" style="font-size: 16px;">
                      {{'UnderwritingNumber' | translate}} :
                      <span>{{ booking.underwritingNo }}</span>
                    </div>
                    <div style="margin-left: 40px;" class="num-no">
                      <img nz-tooltip nzTooltipPlacement="bottom" alt="" width="16"
                           src="assets/images/search_user.svg" style="font-size: 16px;">
                      {{'Referee' | translate}} :
                      <span>{{ booking.salesName }} {{booking.accountName}}</span>
                    </div>
                    <div style="margin-left: 40px;" class="num-no">
                      <img nz-tooltip nzTooltipPlacement="bottom" alt="" width="16"
                           src="assets/images/search_name.svg" style="font-size: 16px;">
                      {{'Holder' | translate}} :
                      <span>{{ booking.name }} {{booking.pinyin}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="!isEmptyUnderwriting" style="height: 630px;">
              <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Empty' | translate"></nz-empty>
            </div>
          </nz-spin>
        </nz-tab>
        <ng-template #underwriting>
          {{'UnderwritingFollowUp' | translate}}
        </ng-template>
        <nz-tab [nzTitle]=policy *ngIf="isShowTab('POLICY')">
          <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
          <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
            <div *ngIf="isEmptyPolicy" style="height: 630px;overflow: hidden;overflow-y: auto;">
              <div class="tab-card" *ngIf="policyList?.list?.length">
                <div class="card-top">
                  <div>{{'MyDeals' | translate}}</div>
                  <a nz-button nzType="link" nzSize="small" style="color: #1D64C4; font-size: 14px;"
                     appThrottleClick (throttleClick)="onViewMore('MyDeals', 'policy')">
                    {{'ViewMore' | translate}}
                    <i nz-icon nzType="right" nzTheme="outline"></i>
                  </a>
                </div>
                <div *ngFor="let booking of policyList.list; let i = index">
                  <div class="card-list" *ngIf="i < 2">
                    <div class="num-no" [title]="booking.policyNo">
                      <img nz-tooltip nzTooltipPlacement="bottom" alt="" width="16"
                           src="assets/images/search_no.svg" style="font-size: 16px;">
                      {{'PolicyNo' | translate}} :
                      <span>{{ booking.policyNo }}</span>
                    </div>
                    <div style="margin-left: 40px;" class="num-no">
                      <img nz-tooltip nzTooltipPlacement="bottom" alt="" width="16"
                           src="assets/images/search_user.svg" style="font-size: 16px;">
                      {{'Referee' | translate}} :
                      <span>{{ booking.salesName }} {{booking.accountName}}</span>
                    </div>
                    <div style="margin-left: 40px;" class="num-no">
                      <img nz-tooltip nzTooltipPlacement="bottom" alt="" width="16"
                           src="assets/images/search_name.svg" style="font-size: 16px;">
                      {{'Holder' | translate}} :
                      <span>{{ booking.ownerName }} {{booking.ownerPinyin}}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tab-card" *ngIf="workbench.pivot !== dataService.pivotOwn && policyListSub?.list?.length">
                <div class="card-top">
                  <div>{{'MySubordinates' | translate}}</div>
                  <a nz-button nzType="link" nzSize="small" style="color: #1D64C4; font-size: 14px;"
                     appThrottleClick (throttleClick)="onViewMore('MySubordinates', 'policy')">
                    {{'ViewMore' | translate}}
                    <i nz-icon nzType="right" nzTheme="outline"></i>
                  </a>
                </div>
                <div *ngFor="let booking of policyListSub.list; let i = index">
                  <div class="card-list" *ngIf="i < 2">
                    <div class="num-no" [title]="booking.policyNo">
                      <img nz-tooltip nzTooltipPlacement="bottom" alt="" width="16"
                           src="assets/images/search_no.svg" style="font-size: 16px;">
                      {{'PolicyNo' | translate}} :
                      <span>{{ booking.policyNo }}</span>
                    </div>
                    <div style="margin-left: 40px;" class="num-no">
                      <img nz-tooltip nzTooltipPlacement="bottom" alt="" width="16"
                           src="assets/images/search_user.svg" style="font-size: 16px;">
                      {{'Referee' | translate}} :
                      <span>{{ booking.salesName }} {{booking.accountName}}</span>
                    </div>
                    <div style="margin-left: 40px;" class="num-no">
                      <img nz-tooltip nzTooltipPlacement="bottom" alt="" width="16"
                           src="assets/images/search_name.svg" style="font-size: 16px;">
                      {{'Holder' | translate}} :
                      <span>{{ booking.ownerName }} {{booking.ownerPinyin}}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tab-card" *ngIf="workbench.tr && policyListFoll?.list?.length">
                <div class="card-top">
                  <div>{{'MyToDo' | translate}}</div>
                  <a nz-button nzType="link" nzSize="small" style="color: #1D64C4; font-size: 14px;"
                     appThrottleClick (throttleClick)="onViewMore('MyToDo', 'policy')">
                    {{'ViewMore' | translate}}
                    <i nz-icon nzType="right" nzTheme="outline"></i>
                  </a>
                </div>
                <div *ngFor="let booking of policyListFoll.list; let i = index">
                  <div class="card-list" *ngIf="i < 2">
                    <div class="num-no" [title]="booking.policyNo">
                      <img nz-tooltip nzTooltipPlacement="bottom" alt="" width="16"
                           src="assets/images/search_no.svg" style="font-size: 16px;">
                      {{'PolicyNo' | translate}} :
                      <span>{{ booking.policyNo }}</span>
                    </div>
                    <div style="margin-left: 40px;" class="num-no">
                      <img nz-tooltip nzTooltipPlacement="bottom" alt="" width="16"
                           src="assets/images/search_user.svg" style="font-size: 16px;">
                      {{'Referee' | translate}} :
                      <span>{{ booking.salesName }} {{booking.accountName}}</span>
                    </div>
                    <div style="margin-left: 40px;" class="num-no">
                      <img nz-tooltip nzTooltipPlacement="bottom" alt="" width="16"
                           src="assets/images/search_name.svg" style="font-size: 16px;">
                      {{'Holder' | translate}} :
                      <span>{{ booking.ownerName }} {{booking.ownerPinyin}}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tab-card" *ngIf="policyListGroup?.list?.length">
                <div class="card-top">
                  <div>{{'MySalesGroup' | translate}}</div>
                  <a nz-button nzType="link" nzSize="small" style="color: #1D64C4; font-size: 14px;"
                     appThrottleClick (throttleClick)="onViewMore('MySalesGroup', 'policy')">
                    {{'ViewMore' | translate}}
                    <i nz-icon nzType="right" nzTheme="outline"></i>
                  </a>
                </div>
                <div *ngFor="let booking of policyListGroup.list; let i = index">
                  <div class="card-list" *ngIf="i < 2">
                    <div class="num-no" [title]="booking.policyNo">
                      <img nz-tooltip nzTooltipPlacement="bottom" alt="" width="16"
                           src="assets/images/search_no.svg" style="font-size: 16px;">
                      {{'PolicyNo' | translate}} :
                      <span>{{ booking.policyNo }}</span>
                    </div>
                    <div style="margin-left: 40px;" class="num-no">
                      <img nz-tooltip nzTooltipPlacement="bottom" alt="" width="16"
                           src="assets/images/search_user.svg" style="font-size: 16px;">
                      {{'Referee' | translate}} :
                      <span>{{ booking.salesName }} {{booking.accountName}}</span>
                    </div>
                    <div style="margin-left: 40px;" class="num-no">
                      <img nz-tooltip nzTooltipPlacement="bottom" alt="" width="16"
                           src="assets/images/search_name.svg" style="font-size: 16px;">
                      {{'Holder' | translate}} :
                      <span>{{ booking.ownerName }} {{booking.ownerPinyin}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="!isEmptyPolicy" style="height: 630px;">
              <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'Empty' | translate"></nz-empty>
            </div>
          </nz-spin>
        </nz-tab>
        <ng-template #policy>
          {{'Policy' | translate}}
        </ng-template>
      </nz-tabset>
    </div>

    <div *ngIf="!isViewList" class="tab-card" style="margin-top: 16px;">
      <div class="card-top">
        <div>{{ tableTitle | translate}}</div>
        <button nz-button nzType="default" [nzSize]="'small'" style="font-size: 12px;"
                appThrottleClick (throttleClick)="onBack()">
          <i nz-icon nzType="left"></i>
          {{ 'ReturnBtn' | translate}}
        </button>
      </div>
      <ng-template #tableTemplate><i nz-icon nzType="loading"></i></ng-template>
      <div *ngIf="tableMode === 'booking'" style="margin-top: 14px;">
        <nz-table [nzBordered]="false"
                  [nzShowPagination]="true"
                  [nzLoading]="loading"
                  [nzLoadingIndicator]="tableTemplate"
                  [nzFrontPagination]="false"
                  [nzData]="listOfData.list"
                  [nzPageIndex]="listOfData.pageNum"
                  [nzPageSize]="listOfData.pageSize"
                  [nzTotal]="listOfData.total"
                  [nzShowSizeChanger]="true"
                  [nzPageSizeOptions]="bookingSearch.pageSizeOptions"
                  (nzPageIndexChange)="onSearch($event)"
                  (nzPageSizeChange)="onPageSizeChange($event)"
                  [nzScroll]="{ x: '1110px', y: '472px'}">
          <thead>
            <ng-container>
              <tr>
                <th nzWidth="160px">
                  <span>{{'BookingNo' | translate}}</span>
                </th>
                <th nzWidth="120px">
                  <span>{{'Referee' | translate}}</span>
                </th>
                <th nzWidth="300px">
                  <span>{{'ProductName' | translate}}</span>
                </th>
                <th nzWidth="140px">
                  <span>{{'Holder' | translate}}</span>
                </th>
                <th nzWidth="100px"><span>{{'Status' | translate}}</span>
                </th>
                <th nzWidth="280px">
                  <span style="margin-right: 4px;">{{'Time' | translate}}</span>
                  <i nz-tooltip [nzTooltipTitle]="('BookingTimeTips' | translate)" nz-icon nzType="question-circle" nzTheme="outline"></i>
                </th>
                <th nzRight nzWidth="90px">
                </th>
              </tr>
            </ng-container>
          </thead>
          <tbody>
          <tr *ngFor="let booking of listOfData.list; let i = index">
            <td>
              <span style="font-weight: bold; font-size: 12px">
                {{ booking.bookingNo }}
              </span>
            </td>
            <td nzEllipsis>
              <div>
                <span>{{ dynamicEncryption('salesName', booking.salesName, maskMode) }}</span>
                <span>{{ dynamicEncryption('accountName', booking.accountName, maskMode) }}</span>
              </div>
              <div *ngIf="workbench.secondAccountName">
                <span>{{ dynamicEncryption('salesName', booking.secondSalesName, maskMode) }}</span>
                <span>{{ dynamicEncryption('accountName', booking.secondAccountName, maskMode) }}</span>
              </div>
            </td>
            <td>
              <div style="display: flex; align-items: center;">
                <img width="20" style="border-radius: 3px; margin-right: 5px" [src]="booking.companyLogo" alt=""/>
                <span>{{ booking.productName || 'N/A' }} <span
                  *ngIf="booking.productYear">&nbsp;({{ booking.custom ? (booking.productYear | yearConversion) : (booking.productYear | yearsOrMonthOrAge)}}
                  )</span> </span>
              </div>
            </td>
            <td nzEllipsis>{{ dynamicEncryption('name', booking.name, maskMode) }} {{dynamicEncryption('pinyin', booking.namePinYin, maskMode)}}</td>
            <td>
              <div [ngSwitch]="booking.status">
                <div>
                  <span *ngSwitchCase="'DRAFT'" class="text-warning">●</span>
                  <span *ngSwitchCase="'PENDING_REVIEW'" class="text-warning">●</span>
                  <span *ngSwitchCase="'APPLIED'" class="text-processing">●</span>
                  <span *ngSwitchCase="'PRE_UNDERWRITING'" class="text-processing">●</span>
                  <span *ngSwitchCase="'UNDERWRITING_APPROVAL'" class="text-processing">●</span>
                  <span *ngSwitchCase="'CONFIRMED'" class="text-processing">●</span>
                  <span *ngSwitchCase="'COMPLETED'" class="text-success">●</span>
                  <span *ngSwitchCase="'CANCELLED'" class="text-cancel">●</span>
                  <span> {{ booking.status | metadataTranslate: 'bookingStatus' }}</span>
                </div>
              </div>
            </td>
            <td>
              <div [ngSwitch]="booking.status">
                <div>
                  <div *ngSwitchCase="'PENDING_REVIEW'">
                    <span style="color: #999999; font-size: 12px">{{'ConfirmTime' | translate}} : </span>
                    {{ (booking.appointmentTime | date:'yyyy-MM-dd HH:mm' : (workbench.systemTimeZone.split(',')[0] )) || 'N/A' }}
                  </div>
                  <div *ngSwitchCase="'DRAFT'">
                    <span style="color: #999999; font-size: 12px">{{'PreferredTime' | translate}} : </span>
                    {{ (booking.temporaryTime | date:'yyyy-MM-dd HH:mm' : (workbench.systemTimeZone.split(',')[0] )) || 'N/A' }}
                  </div>
                  <div *ngSwitchCase="'APPLIED'">
                    <span style="color: #999999; font-size: 12px">{{'PreferredTime' | translate}} : </span>
                    {{ (booking.temporaryTime | date:'yyyy-MM-dd HH:mm' : (workbench.systemTimeZone.split(',')[0] )) || 'N/A' }}
                  </div>
                  <div *ngSwitchCase="'CONFIRMED'">
                    <span style="color: #999999; font-size: 12px">{{'ConfirmTime' | translate}} : </span>
                    {{ (booking.appointmentTime | date:'yyyy-MM-dd HH:mm' : (workbench.systemTimeZone.split(',')[0] )) || 'N/A' }}
                  </div>
                  <div *ngSwitchCase="'COMPLETED'">
                    <span style="color: #999999; font-size: 12px">{{'ConfirmTime' | translate}} : </span>
                    {{ (booking.appointmentTime | date:'yyyy-MM-dd HH:mm' : (workbench.systemTimeZone.split(',')[0] )) || 'N/A' }}
                  </div>
                  <div *ngSwitchCase="'CANCELLED'">
                    <div style="color: #999999; font-size: 12px">{{'PreferredTime' | translate}} : </div>
                    {{ (booking.temporaryTime | date:'yyyy-MM-dd HH:mm' : (workbench.systemTimeZone.split(',')[0] )) || 'N/A' }}
                  </div>
                </div>
              </div>
            </td>
            <td nzRight>
              <a nz-button nzType="link" nzSize="small"
                 appThrottleClick (throttleClick)="onViewBooing(booking)">
                {{'View' | translate}}
              </a>
            </td>
          </tr>
          </tbody>
        </nz-table>
      </div>
      <div *ngIf="tableMode === 'underwriting'" style="margin-top: 14px;">
        <nz-table [nzBordered]="false"
                  [nzShowPagination]="true"
                  [nzLoading]="loading"
                  [nzLoadingIndicator]="tableTemplate"
                  [nzFrontPagination]="false"
                  [nzShowSizeChanger]="true"
                  [nzPageSizeOptions]="underwritingSearch.pageSizeOptions"
                  [nzData]="listOfUnderwriting.list"
                  [nzPageIndex]="listOfUnderwriting.pageNum"
                  [nzPageSize]="listOfUnderwriting.pageSize"
                  [nzTotal]="listOfUnderwriting.total"
                  (nzPageIndexChange)="onSearch($event)"
                  (nzPageSizeChange)="onPageSizeChange($event)"
                  [nzScroll]="{ x: '1515', y: '472px'}">
          <thead>
            <tr>
              <th nzWidth="165px">
                <span nz-tooltip
                    [nzTooltipTitle]="'UnderwritingNumber' | translate">{{'UnderwritingNumber' | translate}}</span>
              </th>
              <th nzWidth="165px">
                <span nz-tooltip [nzTooltipTitle]="'answer39' | translate">{{'answer39' | translate}}</span>
              </th>
              <th nzWidth="160px">
                <span nz-tooltip [nzTooltipTitle]="'Referee' | translate">{{'Referee' | translate}}</span>
              </th>
              <th nzWidth="300px">
                <span nz-tooltip [nzTooltipTitle]="'ProductName' | translate">{{'ProductName' | translate}}</span>
              </th>

              <th nzWidth="160px">
                <span nz-tooltip [nzTooltipTitle]="'Holder' | translate">{{'Holder' | translate}}</span>
              </th>
              <th nzWidth="130px"><span nz-tooltip [nzTooltipTitle]="'Status' | translate">{{'Status' | translate}}</span>
              </th>
              <th nzWidth="220px">
                <span nz-tooltip [nzTooltipTitle]="'SignDate' | translate">{{'SignDate' | translate}}</span>
              </th>
              <th nzRight nzWidth="100px">
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let underwriting of listOfUnderwriting.list; let i = index">
            <td>
            <span style="font-weight: bold; font-size: 12px">
              #{{ underwriting.underwritingNo }}
            </span>
            </td>
            <td>
            <span style="font-weight: bold; font-size: 12px">
              {{ underwriting.policyNo }}
            </span>
            </td>
            <td nzEllipsis>
              <span>
                <span>{{ dynamicEncryption('salesName', underwriting.salesName, maskMode) }}</span>
                <span>{{ dynamicEncryption('salesName', underwriting.accountName, maskMode) }}</span>
              </span>
              <br />
              <span *ngIf="workbench.secondAccountName">
                <span>{{ dynamicEncryption('salesName', underwriting.secondSalesName, maskMode) }}</span>
                <span>{{ dynamicEncryption('salesName', underwriting.secondAccountName, maskMode) }}</span>
              </span>
            </td>
            <td>
              <div style="display: flex; align-items: center;">
                <img width="20" style="border-radius: 3px; margin-right: 5px" [src]="underwriting.companyLogo" alt=""/>
                <span>{{ underwriting.productName || 'N/A' }}<span
                  *ngIf="underwriting.productYear">&nbsp; ({{ underwriting.custom ? (underwriting.productYear | yearConversion) : (underwriting.productYear | yearsOrMonthOrAge)}})</span></span>
              </div>
            </td>
            <td nzEllipsis>
              <span>{{(dynamicEncryption('name', underwriting.name, maskMode))}}</span> <span>
              {{(dynamicEncryption('name', underwriting.pinyin, maskMode))}}</span>
            </td>
            <td>
              <div [ngSwitch]="underwriting.status">
                <div>
                  <span *ngSwitchCase="'CHECKING'" class="text-warning">●</span>
                  <span *ngSwitchCase="'UNDERWRITING'" class="text-warning">●</span>
                  <span *ngSwitchCase="'UNDERWRITE'" class="text-warning">●</span>
                  <span *ngSwitchCase="'SENDING'" class="text-warning">●</span>
                  <span *ngSwitchCase="'PENDING_PREMIUM'" class="text-warning">●</span>
                  <span *ngSwitchCase="'FINISHED'" class="text-success">●</span>
                  <span *ngSwitchCase="'FINISHED_PENDING_PREMIUM'" class="text-warning">●</span>
                  <span *ngSwitchCase="'EFFECTIVE'" class="text-success">●</span>
                  <span *ngSwitchCase="'DECLINED'" class="text-cancel">●</span>
                  <span *ngSwitchCase="'DELETED'" class="text-warning">●</span>
                  <span> {{ underwriting.status | metadataTranslate: 'underwritingStatus' }}</span>
                </div>
              </div>
            </td>
            <td>
              {{ (underwriting.signDate | date:'yyyy-MM-dd HH:mm') || 'N/A' }}
            </td>
            <td nzRight>
              <a nz-button nzType="link" nzSize="small"
                 appThrottleClick (throttleClick)="onViewUnderwriting(underwriting)">
                {{'View' | translate}}
              </a>
            </td>
          </tr>
          </tbody>
        </nz-table>
      </div>
      <div *ngIf="tableMode === 'policy'" style="margin-top: 14px;">
        <nz-table [nzBordered]="false"
                  [nzShowPagination]="true"
                  [nzLoading]="loading"
                  [nzLoadingIndicator]="tableTemplate"
                  [nzFrontPagination]="false"
                  [nzShowSizeChanger]="true"
                  [nzPageSizeOptions]="policySearch.pageSizeOptions"
                  [nzData]="listOfPolicy.list"
                  [nzPageIndex]="listOfPolicy.pageNum"
                  [nzPageSize]="listOfPolicy.pageSize"
                  [nzTotal]="listOfPolicy.total"
                  (nzPageIndexChange)="onSearch($event)"
                  (nzPageSizeChange)="onPageSizeChange($event)"
                  [nzScroll]="{ x: '1415', y: '472px'}">
          <thead>
            <tr>
              <th nzWidth="165px">
              <span nz-tooltip
                    [nzTooltipTitle]="'PolicyNo' | translate">{{'PolicyNo' | translate}}</span>
              </th>
              <th nzWidth="160px">
                <span nz-tooltip [nzTooltipTitle]="'Referee' | translate">{{'Referee' | translate}}</span>
              </th>
              <th nzWidth="300px">
                <span nz-tooltip [nzTooltipTitle]="'ProductName' | translate">{{'ProductName' | translate}}</span>
              </th>

              <th nzWidth="160px">
                <span nz-tooltip [nzTooltipTitle]="'Holder' | translate">{{'Holder' | translate}}</span>
              </th>
              <th nzWidth="180px"><span nz-tooltip [nzTooltipTitle]="'Status' | translate">{{'Status' | translate}}</span>
              </th>
              <th nzWidth="180px">
                <span nz-tooltip [nzTooltipTitle]="'PolicyDate' | translate">{{'PolicyDate' | translate}}</span>
              </th>
              <th nzRight nzWidth="100px">
              </th>
            </tr>
          </thead>
          <tbody>
          <tr *ngFor="let policy of listOfPolicy.list; let i = index">
            <td>
              <span style="font-weight: bold; font-size: 12px">
                {{ policy.policyNo }}
              </span>
            </td>
            <td nzEllipsis>
              <span>
                <span>{{ dynamicEncryption('salesName', policy.salesName, maskMode) }}</span> <span>
                {{ dynamicEncryption('accountName', policy.accountName, maskMode) }}</span>
              </span>
              <br />
              <span *ngIf="workbench.secondAccountName">
                <span>{{ dynamicEncryption('salesName', policy.secondSalesName, maskMode) }}</span> <span>
                {{ dynamicEncryption('accountName', policy.secondAccountName, maskMode) }}</span>
              </span>
            </td>
            <td>
              <div style="display: flex; align-items: center;">
                <img width="20" style="border-radius: 3px; margin-right: 5px" [src]="policy.companyLogo" alt=""/>
                <span>{{ policy.productName || 'N/A' }} <span
                  *ngIf="policy.productYear">&nbsp;({{ policy.custom ? (policy.productYear | yearConversion) :(policy.productYear | yearsOrMonthOrAge)}})</span></span>
              </div>
            </td>
            <td nzEllipsis>
              <span>{{ (dynamicEncryption('name', policy.ownerName, maskMode)) }}</span> <spans>
              {{ (dynamicEncryption('name', policy.ownerPinyin, maskMode)) }}</spans>
            </td>
            <td>
              <div [ngSwitch]="policy.status">
                <div>
                  <span *ngSwitchCase="'SURRENDERED'" class="text-cancel">●</span>
                  <span *ngSwitchCase="'DECEASED'" class="text-cancel">●</span>
                  <span *ngSwitchCase="'DECLINED'" class="text-cancel">●</span>
                  <span *ngSwitchCase="'CLOSED'" class="text-cancel">●</span>
                  <span *ngSwitchCase="'CANCELLED'" class="text-cancel">●</span>
                  <span *ngSwitchCase="'TERMINATED'" class="text-cancel">●</span>
                  <span *ngSwitchCase="'EXPIRATION'" class="text-cancel">●</span>
                  <span *ngSwitchCase="'TRANSFER_IN_FAIL'" class="text-cancel">●</span>
                  <span *ngSwitchCase="'CLAIMS_COMPLETED_LAPSED'" class="text-cancel">●</span>
                  <span *ngSwitchCase="'CLAIMS_COMPLETED_EFFECTIVE'" class="text-success">●</span>
                  <span *ngSwitchCase="'INFORCE'" class="text-success">●</span>
                  <span *ngSwitchCase="'RIDER_INFORCE'" class="text-success">●</span>
                  <span *ngSwitchCase="'WAIVER_INFORCE'" class="text-success">●</span>
                  <span *ngSwitchCase="'UNDERWRITING'" class="text-warning">●</span>
                  <span *ngSwitchCase="'LAPSED'" class="text-warning">●</span>
                  <span *ngSwitchCase="'MATURED'" class="text-warning">●</span>
                  <span *ngSwitchCase="'TRANSFERRED'" class="text-warning">●</span>
                  <span *ngSwitchCase="'POSTPONED'" class="text-warning">●</span>
                  <span *ngSwitchCase="'COOLOFF_SURRENDERING'" class="text-warning">●</span>
                  <span *ngSwitchCase="'COOLOFF_SURRENDERED'" class="text-warning">●</span>
                  <span *ngSwitchCase="'SURRENDERING'" class="text-warning">●</span>
                  <span *ngSwitchCase="'TRANSFER_IN'" class="text-warning">●</span>
                  <span *ngSwitchCase="'REDUCED_PAID_UP'" class="text-warning">●</span>
                  <span *ngSwitchCase="'MAKING_CLAIMS'" class="text-warning">●</span>
                  <span *ngSwitchCase="'POLICY_LOANS'" class="text-warning">●</span>
                  <span *ngSwitchCase="'PREMIUM_HOLIDAY'" class="text-warning">●</span>
                  <span *ngSwitchCase="'DORMANT'" class="text-warning">●</span>
                  <span *ngSwitchCase="'NOT_AFFECTED'" class="text-warning">●</span>
                  <span *ngSwitchCase="'PENDING_PREMIUM'" class="text-warning">●</span>
                  <span *ngSwitchCase="'RENEWAL_PREMIUM'" class="text-warning">●</span>
                  <span *ngSwitchCase="'TRANSFERRING'" class="text-warning">●</span>
                  <span *ngSwitchCase="'RIDER_LAPSED'" class="text-warning">●</span>
                  <span *ngSwitchCase="'OTHER'" class="text-warning">●</span>
                  <!--                <span *ngSwitchDefault class="text-warning">●</span>-->
                  <span> {{ policy.status | metadataTranslate: 'policyStatus' }}</span>
                </div>
              </div>
            </td>
            <td>
              {{ (policy.policyDate | date:'yyyy-MM-dd') || 'N/A' }}
            </td>
            <td nzRight>
              <a nz-button nzType="link" nzSize="small"
                 appThrottleClick (throttleClick)="onViewPolicy(policy)">
                {{'View' | translate}}
              </a>
            </td>
          </tr>
          </tbody>
        </nz-table>
      </div>

    </div>
  </ng-template>
</nz-modal>
