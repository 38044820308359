import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { AccountService } from '../account/account.service';
import {
  AccountInformation,
  DataDesensitization,
  DataMode,
  Instance,
  InstanceAccount,
  Locale,
  Message,
  MessageType,
  Module,
  ModuleBehaviour,
  ModuleName,
  TicketDetail,
  Underwriting,
  Versions
} from '../api/types';
import { WorkbenchService } from '../workbench/workbench.service';
import { LocaleService } from '../core/locale.service';
import { NavigationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MetadataService } from '../shared/service/metadata.service';
import { MessageService } from '../message/message.service';
import { TranslateService } from '@ngx-translate/core';
import packageInfo from '../../../package.json';
import { SwUpdate } from '@angular/service-worker';
import { WebSocketService } from '../core/web-socket.service';
import { ProposalDetailComponent } from '../proposal/proposal-detail/proposal-detail.component';
import { BookingDetailComponent } from '../booking/booking-detail/booking-detail.component';
import { Booking } from '../booking/booking-types';
import { InformationDetailComponent } from '../information/information-detail/information-detail.component';
import { KnowledgeDetailComponent } from '../knowledge/knowledge-detail/knowledge-detail.component';
import { UnderwritingDetailComponent } from '../underwriting/underwriting-detail/underwriting-detail.component';
import { PolicyDetailComponent } from '../policy/policy-detail/policy-detail.component';
import { Policy } from '../policy/policy-types';
import { TicketDetailComponent } from '../ticket/ticket-detail/ticket-detail.component';
import { ProposalService } from '../proposal/proposal.service';
import { BookingService } from '../booking/booking.service';
import { InformationService } from '../information/information.service';
import { KnowledgeService } from '../knowledge/knowledge.service';
import { UnderwritingService } from '../underwriting/underwriting.service';
import { PolicyService } from '../policy/policy.service';
import { TicketService } from '../ticket/ticket.service';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { BaseComponent } from '../base/base.component';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { QrcodeDrawerComponent } from '../shared/component/qrcode-drawer/qrcode-drawer.component';
import { PolicyReminderService } from '../policy-reminder/policy-reminder.service';
import { CommissionService } from '../commission/commission.service';
import { ShareService } from '../share/share.service';
import { LeaveWordService } from '../leave-word/leave-word.service';
import { CommissionListComponent } from '../commission/commission-list/commission-list.component';
import { ShareDetailComponent } from '../share/share-detail/share-detail.component';
import { LeaveWordDetailComponent } from '../leave-word/leave-word-detail/leave-word-detail.component';
import { AccountSwitchComponent } from '../auth/account-switch/account-switch.component';
import { ToDoService } from '../to-do/to-do.service';
import { VersionService } from '../core/version.service';
import { InfoList } from '../commission/commission-types';
import { Workbench } from '../workbench/workbench-types';
import { MessageConfig } from '../message/message-types';
import { SalesService } from './sales.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { UpdateAvailableComponent } from '../shared/component/update-available/update-available.component';
import { ProposalAccountListComponent } from './component/proposal-account-list/proposal-account-list.component';
import { plainToInstance } from 'class-transformer';
import { DeitPasswordComponent } from '../auth/deit-password/deit-password.component';
import { EventService } from '../shared/service/event.service';
import { Observable } from 'rxjs/internal/Observable';
import { filter } from 'rxjs/operators';
import { Constant } from '../base/constant';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.less']
})
export class SalesComponent extends BaseComponent implements OnInit {

  accountInfo: AccountInformation;

  workbench: Workbench;

  totalUnreadMessage: number;

  localePopoverVisible: boolean;
  profilePopoverVisible: boolean;
  workbenchPopoverVisible: boolean;
  versionPopoverVisible: boolean;

  UpdateContent: Versions = new Versions();

  version: string = packageInfo.version;
  env = '';

  swUpdateAvailable = false;
  initialized = false;
  isVisible = false;
  proposalCode: string;
  messageContent: any;

  loading = false;
  dataDesensitization: DataDesensitization = new DataDesensitization();
  qrCodePopoverVisible = false;
  codeImgUrl: string;
  mockInstanceKey = 'dev';
  config: MessageConfig;
  instance: Instance;
  realInstance: Instance;
  isShowPin = false;
  pinMode = 'verify';
  settingPin = false;
  pageTitle: string;
  wansonList: Module[] = [];
  searchVisible = false;

  constructor(private salesService: SalesService,
              private accountService: AccountService,
              private workbenchService: WorkbenchService,
              private proposalService: ProposalService,
              private bookingService: BookingService,
              private informationService: InformationService,
              private knowledgeService: KnowledgeService,
              private underwritingService: UnderwritingService,
              private policyService: PolicyService,
              private ticketService: TicketService,
              private messageService: MessageService,
              public localeService: LocaleService,
              private metadataService: MetadataService,
              private translate: TranslateService,
              private drawerService: NzDrawerService,
              private notificationService: NzNotificationService,
              private router: Router,
              private toastr: ToastrService,
              private swUpdate: SwUpdate,
              private webSocketService: WebSocketService,
              private policyReminderService: PolicyReminderService,
              private commissionService: CommissionService,
              private shareService: ShareService,
              private authService: AuthService,
              private leaveWordService: LeaveWordService,
              private toDoService: ToDoService,
              private versionService: VersionService,
              private modalService: NzModalService,
              private viewContainerRef: ViewContainerRef,
              private eventService: EventService,
  ) {
    super();
    swUpdate.available.subscribe(event => {
      this.swUpdateAvailable = true;
      this.showUpdateAvailableModal();
    });

    // const d = navigator.mediaDevices;
    // console.log(d);

    if (this.metadataService.metadata) {
      this.initialized = true;
    }

    this.metadataService
      .getMetadata()
      .subscribe(
        data => {
          this.initialized = true;
          console.log(data);
        },
        error => {
        });

    (this.router.events.pipe(filter(event => event instanceof NavigationEnd)) as Observable<NavigationEnd>)
      .subscribe(routerInfo => {
        console.log(routerInfo);
        console.log(routerInfo.url);
        switch (routerInfo.url) {
          case '/user/proposal':
          case '/user/proposal/proposal-main':
            this.pageTitle = 'ProposalModule';
            break;
          case '/user/proposal-custom':
          case '/user/proposal-custom/proposal-custom-main':
            this.pageTitle = 'ProposalTemplateModule';
            break;
          case '/user/booking':
          case '/user/booking/booking-main':
            this.pageTitle = 'BookingModule';
            break;
          case '/user/product?tabIndex=0':
          case '/user/product/main?tabIndex=0':
          case '/user/product?tabIndex=1':
          case '/user/product/main?tabIndex=1':
          case '/user/product/comparison':
            this.pageTitle = 'ProductInformation';
            break;
          case '/user/aml':
          case '/user/aml/aml-main':
            this.pageTitle = 'AmlModule';
            break;
          case '/user/ticket':
          case '/user/ticket/ticket-main':
            this.pageTitle = 'TicketModule';
            break;
          case '/user/fna':
          case '/user/fna/fna-main':
            this.pageTitle = 'FnaModule';
            break;
          case '/user/new-fna':
          case '/user/new-fna/new-fna-main':
          case '/user/new-fna-template':
            this.pageTitle = 'NewFna';
            // if (this.instance.isZUU()) {
            //   this.pageTitle = 'ZuuNewFna';
            // } else {
            //   this.pageTitle = 'NewFna';
            // }
            break;
          case '/user/underwriting':
          case '/user/underwriting/underwriting-main':
            this.pageTitle = 'UnderwritingMode';
            break;
          case '/user/policy':
          case '/user/policy/policy-main':
            this.pageTitle = 'PolicyModule';
            break;
          case '/user/document':
          case '/user/document/document-main':
            this.pageTitle = 'DocumentModule';
            break;
          case '/user/information?promotion=false':
          case '/user/information/information-main?promotion=false':
            this.pageTitle = 'InformationModule';
            break;
          case '/user/product-promotion':
          case '/user/product-promotion/product-promotion-main':
            this.pageTitle = 'ProductPromotion';
            break;
          case '/user/knowledge':
          case '/user/knowledge/knowledge-main':
            this.pageTitle = 'KnowledgeModule';
            break;
          case '/user/policyReminder':
          case '/user/policyReminder/policy-reminder':
          case '/user/policyReminder/policyReminder-main':
            this.pageTitle = 'PremiumReminder';
            break;
          case '/user/commission':
          case '/user/commission/commission-main':
            this.pageTitle = 'CommissionModule';
            break;
          case '/user/invitation':
          case '/user/invitation/invitation-main':
            this.pageTitle = 'IntendedInvitation';
            break;
          case '/user/share':
          case '/user/share/share-main':
            this.pageTitle = 'Share';
            break;
          case '/user/quotation':
          case '/user/quotation/quotation-main':
            this.pageTitle = 'QuotationModule';
            break;
          case '/user/contract':
          case '/user/contract/contract-main':
            this.pageTitle = 'SmartContract';
            break;
          case '/user/pre-sales':
          case '/user/pre-sales/pre-sales-main':
            this.pageTitle = 'PreSales';
            break;
          case '/user/leave-word':
          case '/user/leave-word/leave-word-main':
            this.pageTitle = 'CustomerLeaveWord';
            break;
          case '/user/proposal-compare':
          case '/user/proposal-compare/proposal-compare-main':
            this.pageTitle = 'ProposalCustomize';
            break;
          case '/user/user-pact':
          case '/user/user-pact/user-pact-main':
            this.pageTitle = 'CooperativeContract';
            break;
          case '/user/commission-forecast':
          case '/user/commission-forecast/commission-forecast-main':
            this.pageTitle = 'CommissionForecastModule';
            break;
          case '/user/sale-report':
          case '/user/sale-report/sale-report-main':
            this.pageTitle = 'SalesReportModule';
            break;
          case '/user/ticketOrder':
          case '/user/ticketOrder/ticket-order-list':
            this.pageTitle = 'TicketsModule';
            break;
          case '/user/premium-financing':
          case '/user/premium-financing/premium-financing-main':
            this.pageTitle = 'PremiumFinancingModule';
            break;
          case '/user/schedule/schedule-main':
            this.pageTitle = 'Schedule';
            break;
          case '/user/to-do/to-do-main':
            this.pageTitle = 'ToDoItem';
            break;
          case '/user/contact/contact-main':
            this.pageTitle = 'RelationshipModule';
            break;
          case '/user/message/message-main':
            this.pageTitle = 'MessageModule';
            break;
          // case '/sales/contact/contact-main':
          //   this.pageTitle = 'RelationshipModule';
          //   break;
          case '/user/bv':
          case '/user/bv/bv-main':
            this.pageTitle = 'BvModule';
            break;
          default:
            this.pageTitle = '';
            break;
        }
      });

  }

  ngOnInit(): void {
    window.onbeforeunload = () => {
      localStorage.removeItem('codePinDate');
      localStorage.removeItem('codePin');
      localStorage.removeItem('jumpModule');
    };
    this.translate.get(['PreSales', 'BookingInfo', 'UnderwritingFollowUp', 'Policy', 'PremiumReminder']).subscribe(res => {
      this.wansonList = [
        {
          behaviour: ModuleBehaviour.NATIVE,
          enable: false,
          icon: 'assets/images/pre_sale.png',
          name: ModuleName.PRE_SALES,
          view: res.PreSales,
          link: '',
          visible: true,
          reminder: true,
          needPinCode: false,
          squareRank: 1
        }, {
          behaviour: ModuleBehaviour.NATIVE,
          enable: false,
          icon: 'assets/images/booking.png',
          name: ModuleName.BOOKING,
          view: res.BookingInfo,
          link: '',
          visible: true,
          reminder: true,
          needPinCode: false,
          squareRank: 2
        }, {
          behaviour: ModuleBehaviour.NATIVE,
          enable: false,
          icon: 'assets/images/underwriting.png',
          name: ModuleName.UNDERWRITING,
          view: res.UnderwritingFollowUp,
          link: '',
          visible: true,
          reminder: true,
          needPinCode: false,
          squareRank: 3
        }, {
          behaviour: ModuleBehaviour.NATIVE,
          enable: false,
          icon: 'assets/images/policy.png',
          name: ModuleName.POLICY,
          view: res.Policy,
          link: '',
          visible: true,
          reminder: true,
          needPinCode: false,
          squareRank: 4
        }, {
          behaviour: ModuleBehaviour.NATIVE,
          enable: false,
          icon: 'assets/images/policy_reminder.png',
          name: ModuleName.POLICY_REMINDER,
          view: res.PremiumReminder,
          link: '',
          visible: true,
          reminder: true,
          needPinCode: false,
          squareRank: 5
        }];
    });

    this.eventService.verifyIntercept
      .subscribe(
        data => {
          console.log('pin');
          if (this.accountInfo.hasPinCode) {
            console.log('验证PIN');
            this.pinMode = 'verify'; // verify
            this.isShowPin = true;
            this.settingPin = false;
          } else {
            console.log('设置PIN');
            this.pinMode = 'setUp';
            this.isShowPin = true;
            this.settingPin = false;
          }
        }
      );

    this.webSocketService.connect();
    this.instance = this.webSocketService.mockInstance;
    this.realInstance = this.webSocketService.instance;
    this.mockInstanceKey = this.webSocketService.mockInstanceKey || 'dev';
    this.salesService
      .startUsing()
      .subscribe(
        () => {
        },
        () => {
        });

    this.accountService
      .info()
      .subscribe(
        data => {
          this.accountInfo = data;
          if (data.language && localStorage.getItem(Constant.LOCAL_STORAGE_KEY_LOCALE) !== data.language) {
            this.switchLocale(data.language as Locale);
          }
        },
        error => {
        });

    const account = new InstanceAccount();
    account.instance = this.accountService.instance;
    account.passport = this.accountService.passport;
    this.accountService.addAccount(account);

    this.workbenchService
      .getWorkbench()
      .subscribe(
        data => {
          this.workbench = plainToInstance(Workbench, data);
          if (this.workbench.features && this.workbench.features[1]) {
            this.workbench.features[1].squares.forEach(e => {
              this.wansonList.forEach(item => {
                if (item.name == e.name) {
                  item.enable = e.enable;
                  item.view = e.view;
                }
              });
            });
          }
        },
        error => {
        });

    this.messageService
      .bells()
      .subscribe(
        data => {
          this.totalUnreadMessage = data;
        },
        error => {
        });

    this.messageService.messageUnreadEvent
      .subscribe(
        res => {
          this.messageService
            .bells()
            .subscribe(
              data => {
                this.totalUnreadMessage = data;
                this.workbenchService.getWorkbench().subscribe(
                  () => {
                    this.workbench = JSON.parse(JSON.stringify(this.workbenchService.workbench));
                    if (this.workbench.features && this.workbench.features[1]) {
                      this.workbench.features[1].squares.forEach(e => {
                        this.wansonList.forEach(item => {
                          if (item.name == e.name) {
                            item.enable = e.enable;
                            item.view = e.view;
                          }
                        });
                      });
                    }
                  }
                );
              },
              error => {
              });
        }
      );

    this.messageService.messageReceived
      .subscribe(
        message => {
          let title = '';
          let content = '';
          switch (message.type) {
            case MessageType.INPUT_PROPOSAL_CODE:
              this.messageContent = JSON.parse(message.content);
              this.translate.get('VerificationCode').subscribe(
                (res) => {
                  title = res;
                });
              content = this.messageContent.content;
              break;
            default:
              title = message.title;
              content = message.content;
              break;
          }
          const notificationRef = this.notificationService.info(title, content, {
            nzDuration: message.type === MessageType.INPUT_PROPOSAL_CODE ? 0 : 3000,
            nzPauseOnHover: true,
            nzStyle: {cursor: 'pointer'}
          });
          notificationRef.onClick
            ?.subscribe(
              () => {
                switch (message.type) {
                  case MessageType.INPUT_PROPOSAL_CODE:
                    this.isVisible = true;
                    break;
                  default:
                    this.messageDetail(message);
                    break;
                }
              }
            )
            .disposedBy(this.disposeBag);
        })
      .disposedBy(this.disposeBag);

    this.messageService.messageDetail
      .subscribe(
        message => {
          this.messageDetail(message);
        })
      .disposedBy(this.disposeBag);

    this.getMessageConfig();

    this.messageService.messageConfigUpdated
      .subscribe(
        data => {
          this.getMessageConfig();
        },
        error => {
        }
      );

    // 版本说明弹窗
    this.UpdateContent = this.versionService.VersionComparison();
    this.onDesensitization();

    this.workbenchService.reloadWorkbench.subscribe(
      () => {
        this.workbench = JSON.parse(JSON.stringify(this.workbenchService.workbench));
        if (this.workbench.features && this.workbench.features[1]) {
          this.workbench.features[1].squares.forEach(e => {
            this.wansonList.forEach(item => {
              if (item.name == e.name) {
                item.enable = e.enable;
                item.view = e.view;
              }
            });
          });
        }
      }
    );
    this.codeImgUrl = `https://mp.lifebee.tech/download?instance=${this.workbenchService.instance.key}`;
  }

  getMessageConfig(): void {
    this.messageService.getMessageConfig()
      .subscribe(
        data => {
          this.config = data;
        },
        error => {
        }
      );
  }

  switchLocale(value: Locale): void {
    if (this.localeService.locale === value) {
      return;
    }
    this.authService.language(value)
      .subscribe(
        flag => {
          this.localeService.setLocale(value)
            .subscribe(
              data => {
                this.localePopoverVisible = false;

                this.reload();
                this.workbenchService.switchLocaleReload.emit(true);
              });
        },
          error => {
        });
  }

  reload(): void {
    this.accountService
      .info()
      .subscribe(
        data => {
          this.accountInfo = data;
        },
        error => {
        });

    this.workbenchService
      .getWorkbench()
      .subscribe(
        data => {
          this.workbench = data;
          if (this.workbench.features && this.workbench.features[1]) {
            this.workbench.features[1].squares.forEach(e => {
              this.wansonList.forEach(item => {
                if (item.name == e.name) {
                  item.enable = e.enable;
                  item.view = e.view;
                }
              });
            });
          }
          console.log(this.wansonList);
        },
        error => {
        });
  }

  filterSquares(modules: Module[]): Module[] {
    return modules === null ? [] : modules.filter(module => {
      return this.moduleVisible(module);
    });
  }

  onModule(module: Module): void {
    console.log(module);
    if (!this.moduleEnabled(module)) {
      const text = !module.enable ? 'ModuleDisabled' : 'ComingSoon';
      this.translate.get(text).subscribe(
        (res: any) => {
          this.toastr.info(res);
        });
      return;
    }
    this.reload();
    if (this.workbench.pinCodeEnable && module.needPinCode) {
      localStorage.setItem('jumpModule', JSON.stringify(module));
      const isPin = localStorage.getItem('codePin');
      if (!isPin) {
        if (this.accountInfo.hasPinCode) {
          this.pinMode = 'verify'; // verify
          this.isShowPin = true;
          this.settingPin = false;
        } else {
          this.pinMode = 'setUp';
          this.isShowPin = true;
          this.settingPin = false;
        }
      } else {
        this.jump(module);
      }
    } else {
      this.jump(module);
    }
    this.workbenchPopoverVisible = false;
  }

  jump(module: Module): void {
    switch (module.behaviour) {
      case ModuleBehaviour.NATIVE:
        switch (module.name) {
          case ModuleName.PROPOSAL:
            this.router.navigate(['/user/proposal']).then();
            break;
          case ModuleName.PROPOSAL_CUSTOM:
            this.router.navigate(['/user/proposal-custom']).then();
            break;
          case ModuleName.BOOKING:
            this.router.navigate(['/user/booking']).then();
            break;
          case ModuleName.PRODUCT:
            this.router.navigate(['/user/product'], {queryParams: {tabIndex: 0}}).then();
            break;
          case ModuleName.COMPANY:
            this.router.navigate(['/user/product'], {queryParams: {tabIndex: 1}}).then();
            break;
          case ModuleName.COMPARE:
            this.router.navigate(['/user/product/comparison']).then();
            break;
          case ModuleName.AML:
            this.router.navigate(['/user/aml']).then();
            break;
          case ModuleName.AML_SEARCH:
            this.router.navigate(['/user/aml'], {queryParams: {type: 'search'}}).then();
            break;
          case ModuleName.AML_MONITOR:
            this.router.navigate(['/user/aml'], {queryParams: {type: 'monitor'}}).then();
            break;
          case ModuleName.TICKET:
            this.router.navigate(['/user/ticket']).then();
            break;
          case ModuleName.CENTRE_TICKET:
            this.router.navigate(['/user/ticketOrder']).then();
            break;
          case ModuleName.FNA:
            this.router.navigate(['/user/fna']).then();
            break;
          case ModuleName.INVITATION:
            this.router.navigate(['/user/invitation']).then();
            break;
          case ModuleName.NEW_FNA:
            this.router.navigate(['/user/new-fna']).then();
            break;
          case ModuleName.UNDERWRITING:
            this.router.navigate(['/user/underwriting']).then();
            break;
          case ModuleName.POLICY:
            this.router.navigate(['/user/policy']).then();
            break;
          case ModuleName.DOCUMENT:
            this.router.navigate(['/user/document']).then();
            break;
          case ModuleName.INFORMATION:
            this.router.navigate(['/user/information'], {queryParams: {promotion: false}}).then();
            break;
          case ModuleName.PRODUCT_PROMOTION:
            this.router.navigate(['/user/product-promotion']).then();
            break;
          case ModuleName.KNOWLEDGE:
            this.router.navigate(['/user/knowledge']).then();
            break;
          case ModuleName.POLICY_REMINDER:
            this.router.navigate(['/user/policyReminder']).then();
            break;
          case ModuleName.COMMISSION:
            this.router.navigate(['/user/commission']).then();
            break;
          case ModuleName.SHARE:
            this.router.navigate(['user/share']).then();
            break;
          case ModuleName.QUOTATION:
            this.router.navigate(['user/quotation']).then();
            break;
          case ModuleName.INTELLIGENT_CONTRACT:
            this.router.navigate(['user/contract']).then();
            break;
          case ModuleName.EXPANDING:
            this.onQRCode();
            break;
          case ModuleName.PRE_SALES:
            this.router.navigate(['user/pre-sales']).then();
            break;
          case ModuleName.LEAVE_WORD:
            this.router.navigate(['user/leave-word']).then();
            break;
          case ModuleName.PROPOSAL_COMPARE:
            this.router.navigate(['user/proposal-compare']).then();
            break;
          case ModuleName.SALES_PACT:
            this.router.navigate(['user/user-pact']).then();
            break;
          case ModuleName.COMMISSION_FORECAST:
            this.router.navigate(['user/commission-forecast']).then();
            break;
          case ModuleName.SALE_REPORT:
            this.router.navigate(['user/sale-report']).then();
            break;
          case ModuleName.PREMIUM_FINANCING:
            this.router.navigate(['user/premium-financing']).then();
            break;
          case ModuleName.BUSINESS_VOLUME:
            this.router.navigate(['user/bv']).then();
            break;
          default:
            break;
        }
        break;
      case ModuleBehaviour.FILE:
      case ModuleBehaviour.LINK:
        window.open(module.link);
        break;
    }
  }

  moduleVisible(module: Module): boolean {
    return module.visible === true /* && (this.moduleDeveloped(module)|| this.moduleComingSoon(module)) */;
  }

  moduleEnabled(module: Module): boolean {
    if (!module.enable) {
      return false;
    }
    return this.moduleDeveloped(module);
  }

  moduleDeveloped(module: Module): boolean {
    switch (module.name) {
      case ModuleName.PROPOSAL:
        return true;
      // case 'PROPOSAL_CUSTOM':
      //   return true;
      case ModuleName.BOOKING:
        return true;
      case ModuleName.PRODUCT:
        return true;
      case ModuleName.COMPANY:
        return true;
      case ModuleName.COMPARE:
        return true;
      case ModuleName.AML:
        return true;
      case ModuleName.AML_MONITOR:
        return true;
      case ModuleName.AML_SEARCH:
        return true;
      case ModuleName.FNA:
        return true;
      case ModuleName.NEW_FNA:
        return true;
      case ModuleName.TICKET:
        return true;
      case ModuleName.UNDERWRITING:
        return true;
      case ModuleName.POLICY:
        return true;
      case ModuleName.DOCUMENT:
        return true;
      // case ModuleName.LEAVE_WORD:
      //   return true;
      case ModuleName.PRODUCT_PROMOTION:
        return true;
      case ModuleName.POLICY_REMINDER:
        return true;
      case ModuleName.KNOWLEDGE:
        return true;
      case ModuleName.COMMISSION:
        return true;
      case ModuleName.SHARE:
        return true;
      case ModuleName.QUOTATION:
        return true;
      case ModuleName.INTELLIGENT_CONTRACT:
        return true;
      case ModuleName.INVITATION:
        return true;
      case ModuleName.PRE_SALES:
        return true;
      case ModuleName.EXPANDING:
        return true;
      case ModuleName.LEAVE_WORD:
        return true;
      case ModuleName.PROPOSAL_COMPARE:
        return true;
      case ModuleName.SALES_PACT:
        return true;
      case ModuleName.PROPOSAL_CUSTOM:
        return true;
      case ModuleName.COMMISSION_FORECAST:
        return true;
      case ModuleName.INFORMATION:
        return true;
      case ModuleName.SALE_REPORT:
        return true;
      case ModuleName.BUSINESS_VOLUME:
        return true;
      case ModuleName.CENTRE_TICKET:
        return true;
      case ModuleName.PREMIUM_FINANCING:
        return true;
      default:
        return module.behaviour === ModuleBehaviour.FILE || module.behaviour === ModuleBehaviour.LINK;
    }
  }

  onMessage(): void {
    this.router.navigate(['/user/message/message-main']).then();
  }

  onContact(): void {
    this.router.navigate(['/user/contact/contact-main']).then();
  }

  onSchedule(): void {
    this.router.navigate(['/user/schedule/schedule-main']).then();
  }

  onToDo(): void {
    this.router.navigate(['/user/to-do/to-do-main']).then();
  }

  onNavigateToWorkbench(): void {
    this.router.navigate(['/user/workbench']).then();
    this.pageTitle = '';
  }

  onSwitchAccount(): void {
    const drawerRef = this.drawerService.create<AccountSwitchComponent, { value: any }, string>({
      nzWidth: 600,
      nzMaskClosable: true,
      nzContent: AccountSwitchComponent,
      nzContentParams: {},
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });
  }

  onAllSearch(): void {
    console.log('onAllSearch');
    this.searchVisible = true;
  }

  isShowSearch(): boolean {
    const features = this.workbenchService.workbench.features;

    // 使用 some 方法来检查是否至少有一个启用项
    const flagBooking = features.some(item =>
      item.squares.some(info => info.name === 'BOOKING' && info.enable)
    );
    const flagUnderwriting = features.some(item =>
      item.squares.some(info => info.name === 'UNDERWRITING' && info.enable)
    );
    const flagPolicy = features.some(item =>
      item.squares.some(info => info.name === 'POLICY' && info.enable)
    );
    return flagBooking || flagUnderwriting || flagPolicy;
  }

  searchVisibleChange(e: boolean): void {
    this.searchVisible = e;
  }

  onLogout(): void {
    this.accountService.removeCurrentAccount();
    this.accountService.passport = null;
    this.router.navigate(['/auth/login-by-account']).then();
  }

  onLogoutAndResetOrgCode(): void {
    this.accountService.removeCurrentAccount();
    this.accountService.passport = null;
    this.accountService.instance = null;
    this.router.navigate(['/auth/instance-code']).then();
  }

  onReloadPage(): void {
    location.reload();
  }

  get instanceLogo(): string {
    return this.workbenchService.instance?.logo;
  }

  messageDetail(message: Message): void {
    switch (message.type) {
      case MessageType.PROPOSAL:
        this.onProposalDetail(message.key);
        break;
      case MessageType.BOOKING:
        this.onBookingDetail(message.key);
        break;
      case MessageType.INFORMATION:
        this.onInformationDetail(Number(message.key));
        break;
      case MessageType.KNOWLEDGE:
        this.onKnowledgeDetail(Number(message.key));
        break;
      case MessageType.POLICY:
        this.onPolicyDetail(message.key);
        break;
      case MessageType.UNDERWRITING:
        this.onUnderwritingDetail(message.key);
        break;
      case MessageType.TICKET:
        this.onTicketDetail(Number(message.key));
        break;
      case MessageType.SCHEDULE:
        this.onPolicyReminderDetail(message.key);
        break;
      case MessageType.COMMISSION:
        this.onCommissionList(message.key);
        break;
      case MessageType.SHARE:
        this.onShareDetail(message.key);
        break;
      case MessageType.LEAVE_WORD:
        this.onLeaveWordDetail(Number(message.key));
        break;
      default:
        this.translate.get('ComingSoon').subscribe(
          (text: string) => {
            this.toastr.info(text);
          });
        break;
    }
    this.workbenchService.getWorkbench().subscribe(
      () => {
        this.workbench = JSON.parse(JSON.stringify(this.workbenchService.workbench));
        if (this.workbench.features && this.workbench.features[1]) {
          this.workbench.features[1].squares.forEach(e => {
            this.wansonList.forEach(item => {
              if (item.name == e.name) {
                item.enable = e.enable;
                item.view = e.view;
              }
            });
          });
        }
      }
    );
  }

  onProposalDetail(proposalId: string): void {
    this.loading = true;
    this.proposalService.info(proposalId)
      .subscribe(
        proposal => {
          this.loading = false;
          const drawerRef = this.drawerService.create<ProposalDetailComponent, { value: any }, string>({
            nzWidth: 800,
            nzMaskClosable: true,
            nzContent: ProposalDetailComponent,
            nzContentParams: {
              proposal
            },
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });

          drawerRef.afterClose.subscribe(data => {
          });
        },
        error => {
          this.loading = false;
        }
      );
  }

  onBookingDetail(bookingNo: string): void {
    this.loading = true;
    this.bookingService.detail(bookingNo)
      .subscribe(
        bookingDetail => {
          this.loading = false;
          const booking = bookingDetail.toBooking();

          const drawerRef = this.drawerService.create<BookingDetailComponent, { value: Booking }, string>({
            nzWidth: 800,
            nzContent: BookingDetailComponent,
            nzContentParams: {
              bookingDetail,
              booking
            }
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
            component.drawerRef = drawerRef;
          });

          drawerRef.afterClose.subscribe(data => {
          });
        },
        error => {
          this.loading = false;
        });
  }

  onInformationDetail(id: number): void {
    this.loading = true;
    this.informationService.info(id)
      .subscribe(
        information => {
          this.loading = false;
          const drawerRef = this.drawerService.create<InformationDetailComponent, { value: any }, string>({
            nzWidth: 800,
            nzContent: InformationDetailComponent,
            nzContentParams: {
              information
            }
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });

          drawerRef.afterClose.subscribe(data => {
          });
        },
        error => {
          this.loading = false;
        });
  }

  onKnowledgeDetail(id: number): void {
    this.loading = true;
    this.knowledgeService.detail(id)
      .subscribe(
        knowledge => {
          this.loading = false;
          const drawerRef = this.drawerService.create<KnowledgeDetailComponent, { value: any }, string>({
            nzWidth: 800,
            nzMaskClosable: true,
            nzContent: KnowledgeDetailComponent,
            nzContentParams: {
              knowledge
            },
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });

          drawerRef.afterClose.subscribe(data => {
            const component = drawerRef.getContentComponent();
          });
        },
        error => {
          this.loading = false;
        }
      );
  }

  onUnderwritingDetail(underwritingNo: string): void {
    this.loading = true;
    this.underwritingService.detail(underwritingNo)
      .subscribe(
        underwritingDetail => {

          this.loading = false;
          const underwriting = underwritingDetail.toUnderwriting();

          const drawerRef = this.drawerService.create<UnderwritingDetailComponent, { value: Underwriting }, string>({
            nzWidth: 800,
            nzContent: UnderwritingDetailComponent,
            nzContentParams: {
              underwritingDetail,
              underwriting,
              mode: DataMode.OWN,
              dataDesensitization: this.dataDesensitization
            }
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });

          drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
          });
        },
        error => {
          this.loading = false;
        });
  }

  onPolicyDetail(policyId: string): void {
    this.loading = true;
    this.policyService.detail(policyId)
      .subscribe(
        policyDetail => {

          this.loading = false;
          const policy = policyDetail.toPolicy();

          const drawerRef = this.drawerService.create<PolicyDetailComponent, { value: Policy }, string>({
            nzWidth: 800,
            nzContent: PolicyDetailComponent,
            nzContentParams: {
              policyDetail,
              policy,
              mode: DataMode.OWN,
              dataDesensitization: this.dataDesensitization
            }
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });

          drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
          });
        },
        error => {
          this.loading = false;
        });
  }

  onTicketDetail(id: number): void {
    this.loading = true;
    this.ticketService.detail(id)
      .subscribe(
        info => {
          this.loading = false;
          const drawerRef = this.drawerService.create<TicketDetailComponent, { value: TicketDetail }, string>({
            nzWidth: 800,
            nzMaskClosable: true,
            nzContent: TicketDetailComponent,
            nzContentParams: {
              info
            },
          });
          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });
          drawerRef.afterClose.subscribe(data => {
            const component = drawerRef.getContentComponent();
          });
        },
        error => {
          this.loading = false;
        }
      );
  }

  onQRCode(): void {
    const drawerRef = this.drawerService.create<QrcodeDrawerComponent, { value: any }, string>({
      nzWidth: 500,
      nzContent: QrcodeDrawerComponent,
      nzContentParams: {}
    });
    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
    });
    drawerRef.afterClose.subscribe();
  }

  onProposalAccount(): void {
    const drawerRef = this.drawerService.create<ProposalAccountListComponent, { value: any }, string>({
      nzWidth: 600,
      nzContent: ProposalAccountListComponent,
      nzContentParams: {}
    });
    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
    });
    drawerRef.afterClose.subscribe();
  }

  onPolicyReminderDetail(policyId: string): void {
    this.loading = true;
    this.policyReminderService.detail(policyId)
      .subscribe(
        policyDetail => {
          this.loading = false;
          const policy = policyDetail.toPolicy();
          const drawerRef = this.drawerService.create<PolicyDetailComponent, { value: Policy }, string>({
            nzWidth: 800,
            nzContent: PolicyDetailComponent,
            nzContentParams: {
              policy,
              policyDetail
            }
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });

          drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
          });
        },
        error => {
          this.loading = false;
        });
  }

  onCommissionList(date: string): void {
    const param = new InfoList();
    param.billDate = date;
    this.loading = true;
    this.commissionService.infoList(param, true)
      .subscribe(
        info => {
          this.loading = false;
          const drawerRef = this.drawerService.create<CommissionListComponent, { value: any }, string>({
            nzWidth: 600,
            nzContent: CommissionListComponent,
            nzContentParams: {
              info,
              mode: DataMode.OWN,
              commissionBillDate: date
            }
          });
          drawerRef.afterClose.subscribe(data => {
            const component = drawerRef.getContentComponent();
            // this.onSearch(this.search.pageNum);
          });
        },
        error => {
          this.loading = false;
        });
  }

  onShareDetail(code: string): void {
    this.shareService.detail(code).subscribe(
      shareDetail => {
        const drawerRef = this.drawerService.create<ShareDetailComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: ShareDetailComponent,
          nzContentParams: {
            shareDetail
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
        });

        drawerRef.afterClose.subscribe(data => {
          const component = drawerRef.getContentComponent();
        });
      }
    );
  }

  get showDot(): boolean {
    if (!this.config) {
      return false;
    }
    return this.config.messageDisturb || !this.config.digitalReminder;
  }

  onLeaveWordDetail(num: number): void {
    this.leaveWordService.detailsLeaveWord(num)
      .subscribe(
        data => {
          const drawerRef = this.drawerService.create<LeaveWordDetailComponent, { value: any }, string>({
            nzWidth: 600,
            nzContent: LeaveWordDetailComponent,
            nzMask: true,
            nzContentParams: {
              detailData: data
            }
          });
          drawerRef.afterClose.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });
        },
        error => {
          console.log(error);
        }
      );
  }

  // 脱敏字典
  onDesensitization(): void {
    this.accountService.desensitization()
      .subscribe(
        data => {
          this.dataDesensitization = data;
        }
      );
  }

  showUpdateAvailableModal(): void {
    const modalRef = this.modalService.create({
      nzCentered: true,
      nzContent: UpdateAvailableComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzComponentParams: {},
      nzOnOk: () => new Promise(resolve => setTimeout(resolve, 1000)),
      nzFooter: null,
      nzClosable: true,
      nzMaskClosable: true
    });
    const component = modalRef.getContentComponent();
    modalRef.afterOpen.subscribe(() => {
      component.modalRef = modalRef;
    });
    // Return a result when closed
    modalRef.afterClose.subscribe(result => {
    });

    component.reloadEvent.subscribe(() => {
      window.location.reload();
    });

  }

  showAppQrCode(): boolean {
    let show = true;
    const instance = this.salesService.instance;
    if (instance) {
      switch (instance.key) {
        case 'dev':
          show = true;
          break;
        case 'baylight':
          show = false;
          break;
      }
    }
    return show;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  handleOk(): void {
    if (this.proposalCode) {
      this.proposalService.sendCode({id: this.messageContent.id, code: this.proposalCode})
        .subscribe(
          data => {
            if (data) {
              this.isVisible = false;
            }
          }, () => {
          }
        );
    }
  }

  onEditPwd(): void {
    const drawerRef = this.drawerService.create<DeitPasswordComponent, { value: any }, string>({
      nzWidth: 500,
      nzMaskClosable: true,
      nzContent: DeitPasswordComponent,
      nzContentParams: {},
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.returnFlag.subscribe(
        data => {
          drawerRef.close();
        });
    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });
  }

  changeMockInstance(): void {
    localStorage.setItem(Constant.LOCAL_STORAGE_KEY_MOCK_INSTANCE_KEY, this.mockInstanceKey);
    window.location.reload();
  }

  showPin(e): void {
    console.log(e);
    this.isShowPin = false;
    if (e) {
      const module = JSON.parse(localStorage.getItem('jumpModule'));
      if (module) {
        this.jump(module);
        setTimeout(() => {
          localStorage.removeItem('jumpModule');
          // window.location.reload();
        }, 500);
      } else {
        window.location.reload();
      }
    }
  }

  onEditPin(): void {
    console.log('修改PIN');
    this.pinMode = 'edit';
    this.settingPin = true;
    this.isShowPin = true;
  }

  getPageTitle(): string {
    if (this.pageTitle === 'ToDoItem') {
      return this.instance.isFone() ? 'ToDoItem' : 'Pending';
    }
    if (this.pageTitle === 'NewFna') {
      return this.instance.isZUU() ? 'ZuuNewFna' : 'NewFna';
    }
    // pageTitle === 'ToDoItem' ? instance.isFone() ? 'ToDoItem' : 'Pending' : pageTitle ? (pageTitle | translate) : ''
    return this.pageTitle ? this.pageTitle : '';
  }
}
