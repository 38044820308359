import {Injectable} from '@angular/core';
import {HttpService} from '../core/http.service';
import {
  AttributeSearch, CoverClass, CoverQuery,
  CreateProposalCompare,
  ProposalCompare,
  ProposalCompareSearch, ProposalDetail,
  ProposalList,
  SelectProposalList
} from './proposal-compare';
import {Observer} from 'rxjs';
import {ApiResponse, PagedResp} from '../api/types';
import {HttpParams} from '@angular/common/http';
import {API} from '../api/api';
import {map} from 'rxjs/operators';
import {plainToInstance} from 'class-transformer';
import {Observable} from 'rxjs/internal/Observable';
import { ProductCategory } from '../product/product-types';

@Injectable({
  providedIn: 'root'
})
export class ProposalCompareService {

  constructor(private http: HttpService) {
  }

  /**
   * 对比列表
   * @param search ProposalCompareSearch
   */
  list(search: ProposalCompareSearch): Observable<PagedResp<ProposalCompare>> {
    let httpParams = new HttpParams();
    if (search.pageNum != null) {
      httpParams = httpParams.set('pageNum', search.pageNum.toString());
    }
    if (search.pageSize != null) {
      httpParams = httpParams.set('pageSize', search.pageSize.toString());
    }
    if (search.categoryCode != null) {
      httpParams = httpParams.set('categoryCode', search.categoryCode.toString());
    }
    if (search.status != null) {
      httpParams = httpParams.set('status', search.status.toString());
    }
    if (search.name != null) {
      httpParams = httpParams.set('name', search.name.toString());
    }
    return this.http.get<ApiResponse<PagedResp<ProposalCompare>>>(API.proposalCompareV3, {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return plainToInstance(PagedResp, data.body.data) as PagedResp<ProposalCompare>;
    }));
  }

  /**
   * 新增
   * @param params CreateProposalCompare
   */
  add(params: CreateProposalCompare): Observable<PagedResp<ProposalList>> {
    return this.http.post<ApiResponse<PagedResp<ProposalList>>>(API.proposalCompareV3, params, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 可選擇計劃書列表
   * @param search SelectProposalList
   */
  selectList(search: SelectProposalList): Observable<PagedResp<ProposalList>> {
    let httpParams = new HttpParams();
    if (search.pageNum != null) {
      httpParams = httpParams.set('pageNum', search.pageNum.toString());
    }
    if (search.pageSize != null) {
      httpParams = httpParams.set('pageSize', search.pageSize.toString());
    }
    if (search.categoryCode != null) {
      httpParams = httpParams.set('categoryCode', search.categoryCode.toString());
    }
    if (search.ProductName != null) {
      httpParams = httpParams.set('ProductName', search.ProductName.toString());
    }
    if (search.singlePayment != null) {
      httpParams = httpParams.set('singlePayment', search.singlePayment.toString());
    }
    return this.http.get<ApiResponse<PagedResp<ProposalList>>>(API.proposalCompareV3 + '/proposal-list', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 计划书对比详情
   * @param id ID
   */
  details(id: number): Observable<ProposalDetail> {
    return this.http.get<ApiResponse<ProposalDetail>>(API.proposalCompareV3 + '/' + id, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  categories(): Observable<ProductCategory[]> {
    return this.http.get<ApiResponse<ProductCategory[]>>(API.proposalCompareV3 + '/category', {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }
  /**
   * 编辑
   * @param params CreateProposalCompare
   */
  edit(params: CreateProposalCompare): Observable<PagedResp<ProposalList>> {
    return this.http.put<ApiResponse<PagedResp<ProposalList>>>(API.proposalCompareV3, params, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 对比评分
   * @param search AttributeSearch
   */
  attributeScore(search: AttributeSearch): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.proposalCompareV3 + '/attribute-score', search, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 重试计划书对比
   * @param id 编号
   */
  retry(id: number): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.proposalCompareV3 + '/retry/' + id, null, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 删除计划书对比
   * @param id 编号
   */
  delete(id: number): Observable<boolean> {
    return this.http.delete<ApiResponse<boolean>>(API.proposalCompareV3 + '/delete/' + id, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 计划书对比封面列表
   * @param search CoverQuery
   */
  coverList(search: CoverQuery): Observable<PagedResp<CoverClass>> {
    let httpParams = new HttpParams();
    if (search.pageNum != null) {
      httpParams = httpParams.set('pageNum', search.pageNum.toString());
    }
    if (search.pageSize != null) {
      httpParams = httpParams.set('pageSize', search.pageSize.toString());
    }
    if (search.categoryCode != null) {
      httpParams = httpParams.set('categoryCode', search.categoryCode.toString());
    }
    if (search.status != null) {
      httpParams = httpParams.set('status', search.status.toString());
    }
    return this.http.get<ApiResponse<PagedResp<CoverClass>>>(API.proposalCompareV3 + '/cover-list', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }
}
