import {Expose, plainToClass, plainToInstance, Transform, Type} from 'class-transformer';
import {
  BankInfo,
  Beneficiary,
  BookingProgress,
  BookingVip,
  ContingentOwner,
  Currency,
  Customer,
  CustomerCorporate,
  CustomerType,
  Delivery, DomainMetadataVOS,
  Forms,
  Material,
  MedicalPlan,
  PagedReq,
  Pendings,
  ProductPlan,
  Relationship,
  Rider,
  TimeLine
} from '../api/types';
import {Product} from '../product/product-types';


export enum PolicyStatus {
  INFORCE = 'INFORCE',
  LAPSED = 'LAPSED',
  MATURED = 'MATURED',
  TRANSFERRED = 'TRANSFERRED',
  SURRENDERED = 'SURRENDERED',
  DECEASED = 'DECEASED',
  DECLINED = 'DECLINED',
  CLOSED = 'CLOSED',
  CANCELLED = 'CANCELLED',
  UNDERWRITING = 'UNDERWRITING',
  POSTPONED = 'POSTPONED',
  TERMINATED = 'TERMINATED',
  COOLOFF_SURRENDERING = 'COOLOFF_SURRENDERING',
  COOLOFF_SURRENDERED = 'COOLOFF_SURRENDERED',
  SURRENDERING = 'SURRENDERING',
  EXPIRATION = 'EXPIRATION',
  TRANSFER_IN = 'TRANSFER_IN',
  TRANSFER_IN_FAIL = 'TRANSFER_IN_FAIL',
  REDUCED_PAID_UP = 'REDUCED_PAID_UP',
  POLICY_LOANS = 'POLICY_LOANS',
  PREMIUM_HOLIDAY = 'PREMIUM_HOLIDAY',
  DORMANT = 'DORMANT',
}

export class PolicyListResp {
  @Expose() policyId: string; // 保单编号
  @Expose() policyNo: string; // 保单号
  @Expose() accountName: string; // 销售账户名
  @Expose() salesName: string; // 销售姓名
  @Expose() secondAccountName: string; // 销售账户
  @Expose() secondSalesName: string; // 销售账户名
  @Expose() trAccountName: string; // TR
  @Expose() tr2AccountName: string; // TR2
  @Expose() tr3AccountName: string; // TR2
  @Expose() id: number; // 主键
  @Expose() trName: string; // TR姓名
  @Expose() tr2Name: number; // TR2姓名
  @Expose() signDate: number; // 客戶签署日期
  @Expose() productCode: string; // 保险产品唯一标识
  @Expose() productName: string; // 产品名称
  @Expose() productYear: string; // 年期规格
  @Expose() companyLogo: string; // 保险公司LOGO
  @Expose() ownerName: string; // 投保人名称
  @Expose() ownerPinyin: string; // 投保人拼音
  @Expose() insuredName: string; // 受保人名称
  @Expose() insuredPinyin: string; // 受保人拼音
  @Expose() currency: string; // 币种
  @Expose() premium: number; // 保费
  @Expose() unFinishPending: number; // 未完成的代办事项数
  @Expose() policyDate: number; // 保单日期
  @Expose() paymentDate: number; // 保费日期
  @Expose() paidUpDate: number; // 付清日期
  @Expose() autoTransfer: boolean; // DDA(自动转账): 1-是;0-否
  @Expose() messageCount: number; // 留言数
  @Expose() totalPremium: number; // 下一期总保费
  @Expose() status: PolicyStatus; // 保单状态：INFORCE-生效中;LAPSED-已失效;MATURED-已到期;TRANSFERRED-已转出;SURRENDERED-已弃保;DECEASED-已身故;CLOSED-已关闭
  @Expose() create: boolean; // 新建提醒标识
  @Expose() reminder: boolean; // 其他消息提醒标识
  @Expose() isTransferIn: boolean;
  @Expose() lastModifyBy: string; // 最后修改人
  @Expose() newBusinessNumber: number; // 自定义业务编号
  @Expose() lastModifiedDate: number; // 最后修改时间
  @Expose() handler: string; // 处理人
  @Expose() handlerName: string; // 处理人昵称
  @Expose() nextRemindDate: number; // 下次跟進日期
  @Expose() frequency: string; // 付款频率
  @Expose() time: number; // 缴费日期
  @Expose() custom: boolean;
  @Expose() unReadCount: number; // 留言数未读数
  @Expose() unConfirmCount: number; // 留言数未确认数
  @Expose() unFinishService: number; // 保单未完成的代办事项数
}

export class PolicySearch extends PagedReq {
  @Expose() status: PolicyStatus; // 新单状态：CHECKING-检查文件;UNDERWRITING-核保中;SENDING-寄出;FINISHED-完成;DECLINED-拒保;CANCELLED-取消;DELETED-删除
  @Expose() accountName: string; // 账户名
  @Expose() accountNames: string[]; // 账户名s
  @Expose() groupNo: string; // 分组编号
  @Expose() name: string; // 客户姓名
  @Expose() customCode: string;
  @Expose() appointmentTimeConfirmStart: number; // 预约开始时间
  @Expose() appointmentTimeConfirmEnd: number; // 预约结束时间
  @Expose() coolingOffDateStart: number; // 冷静期结束日
  @Expose() coolingOffDateEnd: number; // 冷静期结束日
  @Expose() signDate: number; // 客戶签署日期
  @Expose() policyNo: string; // 保单号
  @Expose() searchKey: string;
  @Expose() policyDate: number; // 保单日期
  @Expose() month: number; // 保单日期
  @Expose() submissionDateStart: number;
  @Expose() submissionDateEnd: number;
  @Expose() approvalDateStart: number;
  @Expose() approvalDateEnd: number;
  @Expose() autoTransfer: boolean; // DDA(自动转账): 1-是;0-否
  @Expose() isTransferIn: boolean; // : 1-是;0-否
  @Expose() companyCode: string; // 保险公司
  @Expose() categoryCode: string; //  保险分类
  @Expose() productCode: string; // 保险产品码
  @Expose() organizer: string; // 发起人
  @Expose() trAccountName: string; // 参与人
  @Expose() newBusinessNumber: number; // 自定义业务编号
  @Expose() policyDateMonth: string;
  @Expose() product: Product = new Product(); // 產品
  @Expose() time: number;
  @Expose() paymentStatus: string;
}

class PolicyServiceVOS {
  @Expose() unReadMessage: number; // 未读留言数
  @Expose() messageCount: number; // 留言总数
  @Expose() project: string; // Service主题
  @Expose() serviceNo: string; // Service编号
  @Expose() finish: boolean; // 是否完成Pending标志:false-未完成;true-完成
  @Expose() id: number; // 主键
  @Expose() applyTime: number; // 创建时间
  @Expose() status: string; // Service状态
}

export class Policy {
  @Expose() id: number; // 主键
  @Expose() newBusinessNumber: string; // 自定义业务编号
  @Expose() policyNo: string; // 保单号
  @Expose() policyId: string; // 保单编号

  @Expose() accountName: string; // 销售账户名
  @Expose() secondNickName: string;
  @Expose() secondAccountName: string; // 销售账户名
  @Expose() ownerName: string; // 投保人姓名
  @Expose() ownerPinyin: string; // 投保人拼音
  @Expose() productYear: string; // 产品年期
  @Expose() productCode: string; // 产品码
  @Expose() productName: string; // 产品名称
  @Expose() categoryCode: string; //  保险分类
  @Expose() policyDeadline: string; //  保單截止日期

  @Expose() approvalDate: number; // 保險公司批核日期(繕發日期)
  @Expose() signDate: number; // 客戶签署日期
  @Expose() submissionDate: number;
  @Expose() contributionPeriod: number; // 供款年限
  @Expose() nextPaymentDate: number; // 保费到期日
  @Expose() backtrack: boolean; // 保单日期回溯
  @Expose() authorizeCollect: boolean;
  @Expose() policyDate: number; // 保单日期
  @Expose() paymentDate: number; // 保费日期
  @Expose() paidUpDate: number; // 付清日期
  @Expose() effectiveDate: number; // 保单生效日
  @Expose() policyAnniversary: number; // 保单周年日
  @Expose() coolingOffDate: number; // 冷静期结束日
  @Expose() companyName: string; // 保险公司名称
  @Expose() companyLogo: string; // 保险公司LOGO
  @Expose() companyCode: string; // 保险公司Code
  @Expose() autoTransfer: boolean; // DDA(自动转账): 1-是;0-否
  @Expose() categoryName: string; // 保险分类名称
  @Expose() branch: string; // branch
  @Expose() incomePeriod: string; // 年金期
  @Expose() guaranteePeriod: string; // 保障期
  @Expose() salesName: string; // 销售姓名
  @Expose() accompanyName: string; // 陪同姓名
  @Expose() serviceAgent: string; // 服务代表
  @Expose() serviceAgentName: string; // 服务代表name
  @Expose() serviceAgent2: string; // 服务代表2
  @Expose() serviceAgent2Name: string; // 服务代表2name
  @Expose() accompanyAreaCode: string; // 陪同手机号区号
  @Expose() accompanyPhone: string; // 陪同手机号
  @Expose() accompanyEmail: string; // 陪同email
  @Expose() temporaryTime: number; // 暂定时间
  @Expose() appointmentTime: number; // 预约时间
  @Expose() appointmentAddress: string; // 预约地址
  @Expose() prepay: boolean; // 预缴保费: 预缴-1;不预缴-0
  @Expose() prepayPremium: number; // 预付额
  @Expose() nickName: string; // 销售名称
  @Expose() paymentNumber: string; // 缴费编号
  @Expose() time: number; // 缴费日期
  @Type(() => PolicyServiceVOS)
  @Expose() policyServiceVOS: PolicyServiceVOS[]; // 保单服务
  @Expose() relationship: Relationship; // 投保人与被保人关系
  @Type(() => ProductPlan)
  @Expose() productPlan: ProductPlan = new ProductPlan(); // 产品计划(JSON串)
  @Type(() => MedicalPlan)
  @Expose() medicalPlan: MedicalPlan = new MedicalPlan();
  @Type(() => BankInfo)
  @Transform((value) => plainToInstance(BankInfo, Object.assign(new BankInfo(), value.value)))
  @Expose() bank: BankInfo; // 银行信息
  @Expose() riders: Rider[];
  @Expose() ownerType: CustomerType = CustomerType.INDIVIDUAL; // 投保人/持有人类型:INDIVIDUAL-个人;CORPORATE-公司
  @Type(() => Customer)
  @Expose() owner: Customer = new Customer(); // 投保人/持有人
  @Type(() => CustomerCorporate)
  @Expose() companyOwner: CustomerCorporate = new CustomerCorporate();
  @Expose() insuredType: CustomerType = CustomerType.INDIVIDUAL; // 受保人类型:INDIVIDUAL-个人;CORPORATE-公司
  @Type(() => Customer)
  @Expose() insured: Customer = new Customer(); // 准受保人快照
  @Type(() => CustomerCorporate)
  @Expose() companyInsured: CustomerCorporate = new CustomerCorporate(); // 公司受保人快照
  @Type(() => Beneficiary)
  @Expose() beneficiaries: Beneficiary[]; // 受益人列表快照
  @Type(() => ContingentOwner)
  @Expose() contingentOwner: ContingentOwner = new ContingentOwner(); // 第二持有人快照
  @Expose() contingentInsured: ContingentOwner = new ContingentOwner(); // 第二受保人快照
  @Expose() applicationType: string; // 申请类型:ILAS_PRODUCT_OR_TOP_UP_APPLICATION-投資相連壽險計劃或額外供款申請;NON_ILAS_PRODUCT_APPLICATION-非投資相連壽險計劃申請;FUND_SWITCHING-基金轉換;MPF_APPLICATION-強積金計劃申請;GENERAL_INSURANCE_APPLICATION-一般保險申請;
  @Expose() fnaId: number; // 关联FNA
  @Expose() proposalId: string; // 关联FNA
  @Expose() status: PolicyStatus; // 保单状态
  @Expose() policyTimeLineVOS: TimeLine[]; // 时间线
  @Expose() arriveTime: number; // 到港時間
  @Expose() leaveTime: number; // 離港時間
  @Expose() meetingPlace: string; // 會面地點
  @Type(() => Customer)
  @Expose() otherOwners: Customer[]; // 其他投保人快照
  @Type(() => Customer)
  @Expose() otherInsureds: Customer[]; // 其他受保人快照
  @Expose() hongkongContactNumberAreaCode: string;
  @Expose() hongkongContactNumber: string;
  @Expose() delivery: Delivery = new Delivery(); // 保单支付信息
  @Expose() openBankAccount: boolean; // 是否开户
  @Expose() healthCheck: boolean; // 是否体检
  @Expose() guarantee: boolean; //
  @Expose() addTime: number; // 预约创建时间
  @Expose() forms: Forms[]; // 新单表单
  @Expose() financing: Forms[]; // 新单表单
  @Expose() attachments: Material[]; // 预约附件
  @Type(() => BookingVip)
  @Expose() vip: BookingVip; // VIP客戶
  @Expose() levy: number; // 下一期征费
  @Expose() premiumTemporaryAccount: number;
  @Expose() premiumPayable: number;
  @Expose() futurePremiumDepositAmount: number;
  @Expose() premiumTemporaryAccountCurrency: string;
  @Expose() isTransferIn: boolean; // 是否转入
  @Expose() enjoyPolicyDiscount: boolean;
  @Expose() electronicPolicy: boolean;
  @Expose() discountEventsDeadlinesDate: string;
  @Expose() premium: number; // 下一期主险保费
  @Expose() premiumAndLevy: number; // 下一期总保费及征费
  @Expose() policyYear: number; // 保单年度
  @Expose() edit: boolean; // 是否可编辑
  @Expose() nextRemindDate: number; // 下次跟進日期
  @Expose() consultant: string; // consultant
  @Expose() relationshipManager: string; // relationshipManager
  @Expose() special: string; // relationshipManager
  @Expose() paymentStatus: string; // 缴费状态
  @Expose() refundBalanceAmount: string; // 保费回赠

  @Expose() financingPremium: number; // 融资金额
  @Expose() financingCurrency: Currency; // 融资金额货币
  @Expose() financingBank: string; // 融资银行名称
  @Expose() financingBankPrincipal: string; // 融资银行负责人
  @Expose() financingBankContactPhone: string; // 融资银行联系电话
  @Expose() riderPremiums: number; // 下一期附加险保费之和

  @Expose() trAccountName: string; // TR
  @Expose() tr2AccountName: string; // TR
  @Expose() tr3AccountName: string; // TR
  @Expose() trName: string; // TR姓名
  @Expose() tr2Name: string; // TR2姓名
  @Expose() tr3Name: string; // TR2姓名
  @Expose() trAreaCode: string; // TR电话区号
  @Expose() tr2AreaCode: string; // TR2电话区号
  @Expose() tr3AreaCode: string; // TR2电话区号
  @Expose() trPhone: string; // TR2电话区号
  @Expose() tr2Phone: string; // TR2电话
  @Expose() tr3Phone: string; // TR2电话
  @Expose() trEmail: string; // TR邮箱
  @Expose() tr2Email: string; // TR2邮箱
  @Expose() tr3Email: string; // TR2邮箱

  @Expose() lastModifyBy: string; // 最后修改人
  @Expose() lastModifiedDate: number; // 最后修改时间

  @Expose() handler: string; // 处理人
  @Expose() handlerName: string; // 处理人昵称

  @Type(() => BookingProgress)
  @Expose() bookingProgress: BookingProgress; // 预约进度
  @Expose() custom: boolean;
  @Type(() => DomainMetadataVOS)
  @Expose() domainMetadataVOS: DomainMetadataVOS[]; // 动态字段
  @Expose() hideTr: boolean; // 是否隐藏签单员\服务代表

  ownerNameString(): string {
    let result = '';
    switch (this.ownerType) {
      case CustomerType.INDIVIDUAL:
        result = (this.owner.lastName ?? '') + ' ' + (this.owner.firstName ?? '');
        break;
      case CustomerType.CORPORATE:
        result = this.companyOwner.companyName ?? '';
        break;
    }
    return result;
  }

  insuredNameString(): string {
    let result = '';
    switch (this.insuredType) {
      case CustomerType.INDIVIDUAL:
        result = (this.insured.lastName ?? '') + ' ' + (this.insured.firstName ?? '');
        break;
      case CustomerType.CORPORATE:
        result = this.companyInsured.companyName ?? '';
        break;
    }
    return result;
  }

  consultantPhoneString(): string {
    return (this.accompanyAreaCode ? '+' + this.accompanyAreaCode + ' ' : '') + (this.accompanyPhone ?? '');
  }

  clientContactNoInHongKong(): string {
    return (this.hongkongContactNumberAreaCode ? '+' + this.hongkongContactNumberAreaCode + ' ' : '') + (this.hongkongContactNumber ?? '');
  }
}


export class PolicyInfo {
  @Expose() id: number; // 主键
  @Expose() newBusinessNumber: string; // 自定义业务编号
  @Expose() policyNo: string; // 保单号
  @Expose() policyId: string; // 保单编号
  @Expose() initialPremium: number; // 首期保费

  @Expose() accountName: string; // 销售账户名
  @Expose() secondNickName: string;
  @Expose() secondAccountName: string; // 销售账户名
  @Expose() ownerName: string; // 投保人姓名
  @Expose() ownerPinyin: string; // 投保人拼音
  @Expose() productYear: string; // 产品年期
  @Expose() productCode: string; // 产品码
  @Expose() productName: string; // 产品名称
  @Expose() categoryCode: string; //  保险分类
  @Expose() policyDeadline: string; //  保單截止日期

  @Expose() approvalDate: number; // 保險公司批核日期(繕發日期)
  @Expose() authorizeCollect: boolean;
  @Expose() signDate: number; // 客戶签署日期
  @Expose() submissionDate: number;
  @Expose() contributionPeriod: number; // 供款年限
  @Expose() nextPaymentDate: number; // 保费到期日
  @Expose() backtrack: boolean; // 保单日期回溯
  @Expose() policyDate: number; // 保单日期
  @Expose() paymentDate: number; // 保费日期
  @Expose() effectiveDate: number; // 保单生效日
  @Expose() policyAnniversary: number; // 保单周年日
  @Expose() coolingOffDate: number; // 冷静期结束日
  @Expose() companyName: string; // 保险公司名称
  @Expose() companyLogo: string; // 保险公司LOGO
  @Expose() companyCode: string; // 保险公司Code
  @Expose() serviceAgent: string; // 服务代表
  @Expose() serviceAgentName: string; // 服务代表name
  @Expose() serviceAgent2: string; // 服务代表2
  @Expose() serviceAgent2Name: string; // 服务代表2name
  @Expose() autoTransfer: boolean; // DDA(自动转账): 1-是;0-否
  @Expose() categoryName: string; // 保险分类名称
  @Expose() branch: string; // branch
  @Expose() salesName: string; // 销售姓名
  @Expose() accompanyName: string; // 陪同姓名
  @Expose() accompanyAreaCode: string; // 陪同手机号区号
  @Expose() accompanyPhone: string; // 陪同手机号
  @Expose() accompanyEmail: string; // 陪同email
  @Expose() temporaryTime: number; // 暂定时间
  @Expose() appointmentTime: number; // 预约时间
  @Expose() appointmentAddress: string; // 预约地址
  @Expose() prepay: boolean; // 预缴保费: 预缴-1;不预缴-0
  @Expose() prepayPremium: number; // 预付额
  @Expose() paidUpDate: number; // 付清日期
  @Expose() paymentStatus: string; // 缴费状态
  @Expose() nickName: string; // 销售名称
  @Expose() paymentNumber: string; // 缴费编号
  @Expose() incomePeriod: string; // 年金期
  @Expose() guaranteePeriod: string; // 保障期
  @Type(() => PolicyServiceVOS)
  @Expose() policyServiceVOS: PolicyServiceVOS[]; // 保单服务
  @Expose() relationship: Relationship; // 投保人与被保人关系
  @Type(() => ProductPlan)
  @Expose() productPlan: ProductPlan = new ProductPlan(); // 产品计划(JSON串)
  @Type(() => MedicalPlan)
  @Expose() medicalPlan: MedicalPlan = new MedicalPlan();
  @Type(() => BankInfo)
  @Transform((value) => plainToInstance(BankInfo, Object.assign(new BankInfo(), value.value)))
  @Expose() bank: BankInfo; // 银行信息
  @Expose() riders: Rider[];
  @Expose() ownerType: CustomerType = CustomerType.INDIVIDUAL; // 投保人/持有人类型:INDIVIDUAL-个人;CORPORATE-公司
  @Type(() => Customer)
  @Expose() owner: Customer = new Customer(); // 投保人/持有人
  @Type(() => CustomerCorporate)
  @Expose() companyOwner: CustomerCorporate = new CustomerCorporate();
  @Expose() insuredType: CustomerType = CustomerType.INDIVIDUAL; // 受保人类型:INDIVIDUAL-个人;CORPORATE-公司
  @Type(() => Customer)
  @Expose() insured: Customer = new Customer(); // 准受保人快照
  @Type(() => CustomerCorporate)
  @Expose() companyInsured: CustomerCorporate = new CustomerCorporate(); // 公司受保人快照
  @Type(() => Beneficiary)
  @Expose() beneficiaries: Beneficiary[]; // 受益人列表快照
  @Type(() => ContingentOwner)
  @Expose() contingentOwner: ContingentOwner = new ContingentOwner(); // 第二持有人快照
  @Expose() contingentInsured: ContingentOwner = new ContingentOwner(); // 第二受保人快照
  @Expose() applicationType: string; // 申请类型:ILAS_PRODUCT_OR_TOP_UP_APPLICATION-投資相連壽險計劃或額外供款申請;NON_ILAS_PRODUCT_APPLICATION-非投資相連壽險計劃申請;FUND_SWITCHING-基金轉換;MPF_APPLICATION-強積金計劃申請;GENERAL_INSURANCE_APPLICATION-一般保險申請;
  @Expose() fnaId: number; // 关联FNA
  @Expose() proposalId: string; // 关联FNA
  @Expose() status: PolicyStatus; // 保单状态
  @Expose() policyTimeLineVOS: TimeLine[]; // 时间线
  @Expose() pendings: Pendings[]; // 新单事项
  @Expose() arriveTime: number; // 到港時間
  @Expose() leaveTime: number; // 離港時間
  @Expose() meetingPlace: string; // 會面地點
  @Type(() => Customer)
  @Expose() otherOwners: Customer[]; // 其他投保人快照
  @Type(() => Customer)
  @Expose() otherInsureds: Customer[]; // 其他受保人快照
  @Expose() hongkongContactNumberAreaCode: string;
  @Expose() hongkongContactNumber: string;
  @Expose() delivery: Delivery; // 保单支付信息
  @Expose() openBankAccount: boolean; // 是否开户
  @Expose() healthCheck: boolean; // 是否体检
  @Expose() guarantee: boolean; //
  @Expose() addTime: number; // 预约创建时间
  @Expose() forms: Forms[]; // 新单表单
  @Expose() financing: Forms[]; // 新单表单
  @Expose() attachments: Material[]; // 预约附件
  @Type(() => BookingVip)
  @Expose() vip: BookingVip; // VIP客戶
  @Expose() levy: number; // 下一期征费
  @Expose() isTransferIn: boolean; // 是否转入
  @Expose() premium: number; // 下一期主险保费
  @Expose() premiumAndLevy: number; // 下一期总保费及征费
  @Expose() policyYear: number; // 保单年度

  @Expose() edit: boolean; // 是否可编辑
  @Expose() nextRemindDate: number; // 下次跟進日期
  @Expose() consultant: string; // consultant
  @Expose() relationshipManager: string; // relationshipManager
  @Expose() special: string; // relationshipManager

  @Expose() financingPremium: number; // 融资金额
  @Expose() financingCurrency: Currency; // 融资金额货币
  @Expose() financingBank: string; // 融资银行名称
  @Expose() financingBankPrincipal: string; // 融资银行负责人
  @Expose() financingBankContactPhone: string; // 融资银行联系电话
  @Expose() riderPremiums: number; // 下一期附加险保费之和

  @Expose() trAccountName: string; // TR
  @Expose() tr2AccountName: string; // TR
  @Expose() tr3AccountName: string; // TR
  @Expose() trName: string; // TR姓名
  @Expose() tr2Name: string; // TR2姓名
  @Expose() tr3Name: string; // TR3姓名
  @Expose() trAreaCode: string; // TR电话区号
  @Expose() tr2AreaCode: string; // TR2电话区号
  @Expose() tr3AreaCode: string; // TR2电话区号
  @Expose() trPhone: string; // TR电话
  @Expose() tr2Phone: string; // TR2电话
  @Expose() tr3Phone: string; // TR3电话
  @Expose() trEmail: string; // TR邮箱
  @Expose() tr2Email: string; // TR2邮箱
  @Expose() tr3Email: string; // TR3邮箱

  @Expose() lastModifyBy: string; // 最后修改人
  @Expose() lastModifiedDate: number; // 最后修改时间

  @Expose() handler: string; // 处理人
  @Expose() handlerName: string; // 处理人昵称

  @Type(() => BookingProgress)
  @Expose() bookingProgress: BookingProgress; // 预约进度
  @Expose() custom: boolean;
  @Expose() hideTr: boolean; // 是否隐藏签单员\服务代表

  ownerNameString(): string {
    let result = '';
    switch (this.ownerType) {
      case CustomerType.INDIVIDUAL:
        result = (this.owner.lastName ?? '') + ' ' + (this.owner.firstName ?? '');
        break;
      case CustomerType.CORPORATE:
        result = this.companyOwner.companyName ?? '';
        break;
    }
    return result;
  }

  insuredNameString(): string {
    let result = '';
    switch (this.insuredType) {
      case CustomerType.INDIVIDUAL:
        result = (this.insured.lastName ?? '') + ' ' + (this.insured.firstName ?? '');
        break;
      case CustomerType.CORPORATE:
        result = this.companyInsured.companyName ?? '';
        break;
    }
    return result;
  }

  consultantPhoneString(): string {
    return (this.accompanyAreaCode ? '+' + this.accompanyAreaCode + ' ' : '') + (this.accompanyPhone ?? '');
  }

  clientContactNoInHongKong(): string {
    return (this.hongkongContactNumberAreaCode ? '+' + this.hongkongContactNumberAreaCode + ' ' : '') + (this.hongkongContactNumber ?? '');
  }

  toPolicy(): Policy {
    return plainToClass(Policy, this, {excludeExtraneousValues: true});
  }
}

export class PolicyPendingSearch extends PagedReq {
  @Expose() policyId: string; // 新单编号
  @Expose() serviceNo: string; // 服务件编号
  @Expose() status: string; // 状态
  @Expose() project: string; // 变更项目
  @Expose() deliveryDate: number; // 送件日期
  @Expose() deadlinesDate: number; // 变更项目
}

/**
 * 用于Pending列表
 */
export class PolicyPending {
  @Expose() id: number; // 主键
  @Expose() serviceNo: string; // Pending编号
  @Expose() status: string; // Pending状态
  @Expose() project: string; // Pending主题
  @Expose() remark: string; // 备注
  @Expose() applyTime: number; // 创建时间
  @Expose() organizer: string; // 发起人
  @Expose() deadlines: number; // 截止时间
  @Expose() messageCount: number; // 留言总数
  @Expose() unReadMessage: number; // 未读留言数
  @Expose() finish: boolean; // 是否完成Pending标志:false-未完成;true-完成
  @Expose() underwritingNo: string; // 新单编号
  @Expose() policyId: string; // 保单编号
  @Expose() policyNo: string; // 保单号
  @Expose() deliveryDate: number; // 送件日期
}

export class PolicyPendingStatus {
  @Expose() id: number; // 主键
  @Expose() status: string; // status
}

/**
 * 用于Pending列表
 */
export class PolicyPendingDetail {
  @Expose() id: number; // 主键
  @Expose() policyId: string; // 保单编号
  @Expose() policyStatus: string; // 保单编号
  @Expose() serviceNo: string; // Pending编号
  @Expose() status: string; // Pending状态
  @Expose() project: string; // Pending主题
  @Expose() remark: string; // 备注
  @Expose() applyTime: number; // 创建时间
  @Expose() receiver: string; // 接收人
  @Expose() operator: string; // 操作人
  @Expose() organizer: string; // 发起人
  @Expose() annotate: string; // 发起人
  @Expose() deadlines: number; // 截止时间
  @Expose() messageCount: number; // 留言总数
  @Expose() unReadMessage: number; // 未读留言数
  @Expose() finish: boolean; // 是否完成Pending标志:false-未完成;true-完成
  @Expose() underwritingNo: string; // 新单编号
  @Expose() policyNo: string; // 保单号
  @Expose() deliveryDate: number; // 送件日期
  @Expose() archiveDate: number; // 建档日期
  @Expose() finishDate: number; // 结案日期
  @Expose() operatingTime: number; // 操作人最后操作时间
  @Expose() attachments: Material[]; // 附件
  @Expose() companyName: string; // 保险公司名称
  @Expose() ownerName: string; // 投保人姓名
  @Expose() insuredName: string; // 受保人姓名
  @Expose() ultimate: boolean; // 中间状态

}

export class PolicyPendingReply {
  @Expose() replyTime: number; // 回复时间
  @Expose() lastName: number; // 姓
  @Expose() attachments: Material[]; // 附件
  @Expose() accountName: string; // 当前登录回复账户
  @Expose() accountType: string; // 账户类型: STAFF-职工;SALES-销售
  @Expose() myReply: boolean; // 用于区别回复是不是本人
  @Expose() avatar: string; // 头像
  @Expose() content: string; // 回复内容
  @Expose() time: number; // 对话时间：计划书留言列表
}

export class PolicyPendingReplyReq {
  @Expose() serviceId: number; // Pending主键
  @Expose() attachments: Material[]; // 附件
  @Expose() content: string; // 回复内容
}
