<div>
  <div [class]="'property-row'">
    <span class="property-title-row" *ngIf="title">{{ title }}</span>
    <img *ngIf="required" alt="" width="24" src="assets/images/ic_required.svg">
    <span class="property-value-row">
    <nz-radio-group [(ngModel)]="value" [disabled]="disabled">
      <label *ngFor="let gender of genderOptions" nz-radio [nzValue]="gender.value">{{gender.label}}</label>
    </nz-radio-group>
    </span>
  </div>
  <div class="property-tip" *ngIf="tip && tipVisible">{{tip}}</div>
  <div class="property-bottom-line" *ngIf="bottomLineVisible"></div>
</div>
