import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PremiumFinancingCreate, PremiumFinancingProposalSearch } from '../premium-financing-types';
import { PropertySelectOption } from '../../shared/component/property-select/property-select.component';
import { PremiumFinancingService } from '../premium-financing.service';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import {
  PremiumFinancingProposalSelectComponent
} from '../premium-financing-proposal-select/premium-financing-proposal-select.component';
import { SmokeCondition } from '../../api/types';
import { TranslateService } from '@ngx-translate/core';
import { ProposalDetailComponent } from '../../proposal/proposal-detail/proposal-detail.component';
import { ProposalService } from '../../proposal/proposal.service';

@Component({
  selector: 'app-financing-excel',
  templateUrl: './financing-excel.component.html',
  styleUrls: ['./financing-excel.component.less']
})
export class FinancingExcelComponent implements OnInit {
  loading = false;

  premiumFinancing: PremiumFinancingCreate = new PremiumFinancingCreate();

  @Output()
  premiumFinancingCreate: EventEmitter<any> = new EventEmitter<any>();

  proposalSearch: PremiumFinancingProposalSearch = new PremiumFinancingProposalSearch();

  fullOptions: PropertySelectOption[] = [];
  constructor(private premiumFinancingService: PremiumFinancingService,
              private translate: TranslateService,
              private proposalService: ProposalService,
              private drawerService: NzDrawerService) { }

  ngOnInit(): void {
    this.fullOptions.push(new PropertySelectOption(this.translate.instant('WCFull'), 'FULL'));
    this.fullOptions.push(new PropertySelectOption(this.translate.instant('PFFull'), 'PARTIAL'));
    this.premiumFinancing.loanRatio = 90;
    this.premiumFinancing.insuranceCompany = '公司A';
  }


  onProposalSelect(): void {
    const drawerRef = this.drawerService.create<PremiumFinancingProposalSelectComponent, {}, string>({
      nzWidth: 900,
      nzContent: PremiumFinancingProposalSelectComponent,
      nzContentParams: {
        search: this.proposalSearch
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.proposalSelected.subscribe(proposal => {
        this.premiumFinancing.proposalId = proposal.proposalId;
        this.premiumFinancing.proposal = proposal;
        this.proposalService.info(proposal.proposalId)
          .subscribe(
            proposalInfo => {
              this.premiumFinancing.premium = proposal.premium || proposalInfo.pdfPremium;
              this.premiumFinancing.premiumTotal = proposal.premiumTotal || proposalInfo.premiumTotal;
              this.premiumFinancing.initialSurrenderValue = proposalInfo.firstDaySurrenderValue;
            },
            error => {
            });
        drawerRef.close();
      });
    });

    drawerRef.afterClose.subscribe(data => {
      const component = drawerRef.getContentComponent();
    });
  }

  get proposalLabel(): string {
    if (!this.premiumFinancing.proposal || !this.premiumFinancing.proposal.proposalId) {
      return '';
    }
    return (this.premiumFinancing.proposal.productName ?? '') + ' | ' + (this.premiumFinancing.proposal.proposalId ?? '');
  }

  get canGenerate(): boolean {
    if (this.loading) {
      return false;
    }
    const requiredFields = Object.keys(this.premiumFinancing).filter(
      (key) => !['asterisk', 'proposal', 'theme'].includes(key)
    );
    return requiredFields.every((field) => this.premiumFinancing[field] !== undefined && this.premiumFinancing[field] !== null && this.premiumFinancing[field] !== '');

  }

  onReset(): void {
    this.premiumFinancing = new PremiumFinancingCreate();
  }

  onGenerate(): void {
    if (!this.canGenerate) {
      return;
    }
    this.premiumFinancingService.created(this.premiumFinancing)
      .subscribe(generated => {
        if (generated) {
          this.premiumFinancingCreate.emit('');
        }
      });
  }


}
