import {Injectable} from '@angular/core';
import {
  ApiResponse,
  UnderwritingAttachmentReq,
  DataMode,
  PagedResp,
  UnderwritingDetailResp,
  UnderWritingListResp,
  UnderwritingBeneficiaryReq,
  BankInfoReq,
  HeathInfo,
  HealthInfoAnswer,
  PendingDetail,
  PendingDetailReply,
  PendingReply, DialogReply, SignatureList
} from '../api/types';
import {Observable} from 'rxjs/internal/Observable';
import {HttpClient, HttpParams} from '@angular/common/http';
import {API} from '../api/api';
import {map} from 'rxjs/operators';
import {HttpService} from '../core/http.service';
import {BaseService} from '../base/base.service';
import { plainToClass, plainToInstance } from 'class-transformer';
import {
  UnderwritingPending,
  UnderwritingPendingDetail, UnderwritingPendingReply, UnderwritingPendingReplyReq,
  UnderwritingPendingSearch,
  UnderwritingPendingStatus,
  UnderwritingSearch
} from './underwriting-types';
import { plainToArray } from '../shared/utils/class-transform';

@Injectable({
  providedIn: 'root'
})
export class UnderwritingService extends BaseService {

  constructor(private http: HttpService, private httpClient: HttpClient) {
    super();
  }

  /**
   * 新单列表
   * * @param search 搜索条件
   * @param type 列表类型
   */
  list(search: UnderwritingSearch, type: DataMode): Observable<PagedResp<UnderWritingListResp>> {
    let httpParams = new HttpParams();
    if (search.pageNum != null) {
      httpParams = httpParams.set('pageNum', search.pageNum.toString());
    }
    if (search.pageSize != null) {
      httpParams = httpParams.set('pageSize', search.pageSize.toString());
    }
    if (search.status != null) {
      httpParams = httpParams.set('status', search.status.toString());
    }
    if (search.searchKey != null) {
      httpParams = httpParams.set('searchKey', search.searchKey.toString());
    }
    if (search.underwritingNo != null) {
      httpParams = httpParams.set('underwritingNo', search.underwritingNo.toString());
    }
    if (search.policyNo != null) {
      httpParams = httpParams.set('policyNo', search.policyNo.toString());
    }
    if (search.name != null) {
      httpParams = httpParams.set('name', search.name.toString());
    }
    if (search.customCode != null) {
      httpParams = httpParams.set('customCode', search.customCode.toString());
    }
    if (search.newBusinessNumber != null) {
      httpParams = httpParams.set('newBusinessNumber', search.newBusinessNumber.toString());
    }
    if (search.signDate != null) {
      httpParams = httpParams.set('signDate', search.signDate.toString());
    }
    if (search.signDateEnd != null) {
      httpParams = httpParams.set('signDateEnd', search.signDateEnd.toString());
    }
    if (search.signDateStart != null) {
      httpParams = httpParams.set('signDateStart', search.signDateStart.toString());
    }
    if (search.accountName != null) {
      httpParams = httpParams.set('accountName', search.accountName.toString());
    }
    if (search.productCode != null) {
      httpParams = httpParams.set('productCode', search.productCode.toString());
    }
    let uri = '';
    switch (type) {
      case DataMode.OWN:
        uri = '/list-mine';
        break;
      case DataMode.SUBORDINATE:
        uri = '/list-subordinate';
        break;
      case DataMode.FOLLOWUP:
        uri = '/list-follow-up';
        break;
      case DataMode.SALESGROUP:
        uri = '/list-sales-group';
        break;
    }
    return this.http.get<ApiResponse<PagedResp<UnderWritingListResp>>>(API.underwritingV3 + uri, {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }


  /**
   * 详情
   * @param underwritingNo underwritingNo
   */
  detail(underwritingNo: string): Observable<UnderwritingDetailResp> {
    return this.http.get<ApiResponse<UnderwritingDetailResp>>(API.underwritingV3 + '/info/' + underwritingNo, {
      observe: 'response'
    }).pipe(map(data => {
      return plainToClass(UnderwritingDetailResp, data.body.data);
    }));
  }

  /**
   * 新单附件管理
   * @param attachmentsReq 新单附件表单
   */
  updateAttachments(attachmentsReq: UnderwritingAttachmentReq): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.underwritingV3 + '/attachment', attachmentsReq, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 更新受保人
   * @param underwriting 受保人
   */
  insured(underwriting: UnderwritingDetailResp): Observable<any> {
    return this.http.put<ApiResponse<number>>(API.underwritingV3 + '/insured', underwriting, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  Owner(underwriting: UnderwritingDetailResp): Observable<any> {
    return this.http.put<ApiResponse<number>>(API.underwritingV3 + '/owner', underwriting, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 更新受益人
   * @param beneficiaryReq 受益人
   */
  beneficiary(beneficiaryReq: UnderwritingBeneficiaryReq): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.underwritingV3 + '/beneficiary', beneficiaryReq, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 更新银行信息
   * @param beneficiaryReq 银行信息
   */
  bank(bankInfoReq: BankInfoReq): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.underwritingV3 + '/bank', bankInfoReq, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  question(underwritingNo: string): Observable<HeathInfo> {
    let httpParams = new HttpParams();
    if (underwritingNo != null) {
      httpParams = httpParams.set('underwritingNo', underwritingNo);
    }
    // @ts-ignore
    return this.http.get<ApiResponse<HeathInfo>>(API.underwritingV3 + '/question', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return plainToClass(HeathInfo, data.body.data);
    }));
  }


  underwritingAnswer(params: HealthInfoAnswer): Observable<boolean> {
    return this.http.post<ApiResponse<boolean>>(API.underwritingV3 + '/answer', params, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 查询Pending状态列表
   */
  pendingStatusList(): Observable<UnderwritingPendingStatus[]> {
    return this.http.get<ApiResponse<UnderwritingPendingStatus[]>>(API.underwritingV3 + '/pending-status-list', {
      observe: 'response'
    }).pipe(map(data => {
      return plainToArray(UnderwritingPendingStatus, data.body.data);
    }));
  }

  /**
   * 查询新单Pending列表
   * @param search 新单Pending列表
   */
  pendingList(search: UnderwritingPendingSearch): Observable<UnderwritingPending[]> {
    let httpParams = new HttpParams();
    if (search.underwritingNo != null) {
      httpParams = httpParams.set('underwritingNo', search.underwritingNo);
    }
    if (search.pendingNo != null) {
      httpParams = httpParams.set('pendingNo', search.pendingNo);
    }
    if (search.status != null) {
      httpParams = httpParams.set('status', search.status);
    }
    if (search.project != null) {
      httpParams = httpParams.set('project', search.project);
    }
    if (search.deliveryDate != null) {
      httpParams = httpParams.set('deliveryDate', search.deliveryDate);
    }
    if (search.deadlinesDate != null) {
      httpParams = httpParams.set('deadlinesDate', search.deadlinesDate);
    }
    return this.http.get<ApiResponse<UnderwritingPending[]>>(API.underwritingV3 + '/pending-list', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return plainToArray(UnderwritingPending, data.body.data);
    }));
  }

  /**
   * 查询新单Pending详情
   * @param id 新单Pending的ID
   */
  pendingDetail(id: number): Observable<PendingDetail> {
    return this.http.get<ApiResponse<PendingDetail>>(API.underwritingV3 + '/pending/' + id, {
      observe: 'response'
    }).pipe(map(data => {
      return plainToClass(PendingDetail, data.body.data);
    }));
  }

  /**
   * 查询新单Pending详情
   * @param id 新单Pending的ID
   */
  pendingDetailV2(id: number): Observable<UnderwritingPendingDetail> {
    return this.http.get<ApiResponse<UnderwritingPendingDetail>>(API.underwritingV3 + '/pending/' + id, {
      observe: 'response'
    }).pipe(map(data => {
      return plainToInstance(UnderwritingPendingDetail, data.body.data);
    }));
  }

  /**
   * 获取Pending回复列表
   * @param id 新单Pending的ID
   */
  pendingReply(id: number): Observable<PendingReply[]> {
    return this.http.get<ApiResponse<PendingReply[]>>(API.underwritingV3 + '/pending/reply/' + id, {
      observe: 'response'
    }).pipe(map(data => {
      return plainToClass(PendingReply, data.body.data);
    }));
  }

  /**
   * 获取Pending回复列表
   * @param id 新单Pending的ID
   */
  pendingReplies(id: number): Observable<UnderwritingPendingReply[]> {
    return this.http.get<ApiResponse<UnderwritingPendingReply[]>>(API.underwritingV3 + '/pending/reply/' + id, {
      observe: 'response'
    }).pipe(map(data => {
      return plainToInstance(UnderwritingPendingReply, data.body.data);
    }));
  }

  pendingDetailReply(reply: PendingDetailReply): Observable<boolean> {
    return this.http.post<ApiResponse<boolean>>(API.underwritingV3 + '/pending/reply', reply, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  pendingDetailReplyV2(replyReq: UnderwritingPendingReplyReq): Observable<boolean> {
    return this.http.post<ApiResponse<boolean>>(API.underwritingV3 + '/pending/reply', replyReq, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  dialogList(underWritingNo: string): Observable<PendingReply[]> {
    return this.http.get<ApiResponse<PendingReply[]>>(API.underwritingV3 + '/dialog-list/' + underWritingNo, {
      observe: 'response'
    }).pipe(map(data => {
      return plainToClass(PendingReply, data.body.data);
    }));
  }

  dialogReply(params: DialogReply): Observable<boolean> {
    return this.http.post<ApiResponse<boolean>>(API.underwritingV3 + '/dialog-save', params, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   *  预约表单列表
   * @param underwritingNo 预约No
   * @param applicationType  状态
   */
  fromList(underwritingNo: string, applicationType: string): Observable<SignatureList[]> {
    let httpParams = new HttpParams();
    if (underwritingNo != null) {
      httpParams = httpParams.set('underwritingNo', underwritingNo);
    }
    if (applicationType != null) {
      httpParams = httpParams.set('applicationType', applicationType);
    }
    return this.http.get<ApiResponse<SignatureList[]>>(API.underwritingV3 + '/signature/list', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return plainToClass(SignatureList, data.body.data);
    }));
  }

  downloadPdf(underwritingNo: string, formId: string): Observable<Uint8Array> {
    let httpParams = new HttpParams();
    if (formId != null) {
      httpParams = httpParams.set('formId', formId);
    }
    return this.httpClient
      .get(API.underwritingV3 + '/download-form/' + underwritingNo, {
        params: httpParams,
        responseType: 'arraybuffer'
      }).pipe(map((arrayBuffer: ArrayBuffer) => new Uint8Array(arrayBuffer)));
  }

  /**
   *  cash表单
   *  问题答案查询接口
   */
  cashQuestion(underwritingNo: string, cashFormEnum: string): Observable<any> {
    let httpParams = new HttpParams();
    if (underwritingNo !== null) {
      httpParams = httpParams.set('underwritingNo', underwritingNo);
    }
    if (cashFormEnum !== null) {
      httpParams = httpParams.set('cashFormEnum', cashFormEnum);
    }
    return this.http.get<ApiResponse<any>>(API.underwritingV3 + '/cash/question', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * cash表单
   * @param req 问卷答案
   */
  cashAnswer(req: any): Observable<boolean> {
    return this.http.post<ApiResponse<boolean>>(API.underwritingV3 + '/cash/answer', req, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 新单留言确认
   * @param param 参数
   */
  putDialogConfirm(param): Observable<boolean> {
    let httpParams = new HttpParams();
    if (param.id != null) {
      httpParams = httpParams.set('id', param.id.toString());
    }
    if (param.confirm != null) {
      httpParams = httpParams.set('confirm', param.confirm.toString());
    }
    return this.http.put<ApiResponse<boolean>>(API.underwritingV3 + '/dialog-confirm', null, {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

}


