<div class="drawer-container">
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div class="common-title" style="margin-bottom: 30px">
      {{'PremiumFinancingCreate' | translate}}
    </div>

    <div class="section-title" style="margin-top: 20px;display: flex">
      <span style="margin-left: 8px">{{'BasicInformation' | translate}}</span>
    </div>
    <app-property-text [title]="'PremiumFinancingTitle' | translate"
                       [displayMode]="'vertical'"
                       [bottomLineVisible]="false"
                       [value]="info.premiumFinancing.name"></app-property-text>
    <!--    <div style="width: 100%;padding: 10px 0;">-->

    <app-property-text [title]="'Proposal' | translate"
                       [displayMode]="'vertical'"
                       [bottomLineVisible]="false"
                       [value]="proposalLabel"></app-property-text>
    <!--    </div>-->
    <!--    <div class="section-title" style="margin-top: 20px;display: flex">-->
    <!--      <span style="margin-left: 8px">{{'Proposal' | translate}}</span>-->
    <!--      <a style="margin-left: auto" nz-button [nzSize]="'small'" appThrottleClick (throttleClick)="onSelect();"-->
    <!--         nzType="link">{{'SelectProposal'| translate}}</a>-->
    <!--    </div>-->
    <!--    <div *ngIf="proposal.proposalId" class="section-wrapper" style="padding: 10px 10px 10px 10px;">-->
    <!--      {{ proposal.proposalId }}-->
    <!--    </div>-->
    <app-property-text [displayMode]="'vertical'"
                       [bottomLineVisible]="false"
                       [title]="'Currency' | translate"
                       [value]="info.premiumFinancing.currency  || 'N/A'"></app-property-text>

    <app-property-text [displayMode]="'vertical'"
                       [bottomLineVisible]="false"
                       [title]="'PaymentTerm' | translate"
                       [value]="info.premiumFinancing.paymentTerm || 'N/A'"></app-property-text>

    <app-property-text [title]="'AnnualPremium' | translate"
                       [displayMode]="'vertical'"
                       [bottomLineVisible]="false"
                       [value]="info.premiumFinancing.premium | number"></app-property-text>

    <app-property-text [title]="'TotalPremium' | translate"
                       [displayMode]="'vertical'"
                       [bottomLineVisible]="false"
                       [value]="info.premiumFinancing.premiumTotal | number"></app-property-text>

    <app-property-text [title]="'InitialSurrenderValue' | translate"
                       [displayMode]="'vertical'"
                       [bottomLineVisible]="false"
                       [value]="info.premiumFinancing.initialSurrenderValue | number"></app-property-text>
    <div class="section-title" style="margin-top: 20px;">
      <span style="margin-left: 8px">{{'BackInfo' | translate}}</span>
    </div>
    <app-property-text [title]="'BankName' | translate"
                       [displayMode]="'vertical'"
                       [bottomLineVisible]="false"
                       [value]="info.premiumFinancing.bankInfo.name"></app-property-text>

    <app-property-text [title]="('LoanRatio' | translate) + ' (%)'"
                       [displayMode]="'vertical'"
                       [bottomLineVisible]="false"
                       [value]="info.premiumFinancing.bankInfo.loanRatio"></app-property-text>


    <app-property-text [title]="'LoanYear' | translate"
                       [displayMode]="'vertical'"
                       [bottomLineVisible]="false"
                       [value]="info.premiumFinancing.bankInfo.year"></app-property-text>

    <app-property-text [title]="('AnnualInterestRate' | translate) + ' (%)'"
                       [displayMode]="'vertical'"
                       [bottomLineVisible]="false"
                       [value]="info.premiumFinancing.bankInfo.annualInterestRate"></app-property-text>

    <app-property-text [title]="('PrepaymentInterestRate' | translate) + ' (%)'"
                       [displayMode]="'vertical'"
                       [bottomLineVisible]="false"
                       [value]="info.premiumFinancing.bankInfo.prepaymentInterestRate"></app-property-text>

    <app-property-text [title]="('PolicyLoanHandlingFeeRate' | translate) + ' (%)'"
                       [displayMode]="'vertical'"
                       [bottomLineVisible]="false"
                       [value]="info.premiumFinancing.bankInfo.policyLoanHandlingFeeRate"></app-property-text>

    <div class="section-title" style="margin-top: 20px;">
      <span style="margin-left: 8px">{{'DiscountInfo' | translate}}</span>
    </div>

    <app-property-text [title]="('FirstYearPremiumDiscount' | translate) + ' (%)'"
                       [displayMode]="'vertical'"
                       [bottomLineVisible]="false"
                       [value]="info.premiumFinancing.discountInfo.firstYearPremiumDiscount"></app-property-text>

    <app-property-text [title]="('AnnualPremiumDiscount' | translate) + ' (%)'"
                       [displayMode]="'vertical'"
                       [bottomLineVisible]="false"
                       [value]="info.premiumFinancing.discountInfo.annualPremiumDiscount"></app-property-text>

    <app-property-text [title]="('PremiumCashback' | translate) + ' (%)'"
                       [displayMode]="'vertical'"
                       [bottomLineVisible]="false"
                       [value]="info.premiumFinancing.discountInfo.premiumCashback"></app-property-text>
  </nz-spin>
</div>
