import { EventEmitter, Injectable } from '@angular/core';
import { HttpService } from '../core/http.service';
import { BaseService } from '../base/base.service';
import {
  ApiResponse, BookingAssociateFnaReq,
  BookingAssociateProposalReq, BookingAttachmentReq, BookingBeneficiaryReq,
  BookingInfoReq,
  BookingInsuredReq,
  BookingOwnerReq, DataMode, DialogReply, HealthInfoAnswer, HeathInfo, Material, Message,
  PagedResp, PendingReply,
  ProductPlanReq, SignatureList, SignedData
} from '../api/types';
import { Observable } from 'rxjs/internal/Observable';
import {HttpClient, HttpParams} from '@angular/common/http';
import { API } from '../api/api';
import { map } from 'rxjs/operators';
import { plainToClass, plainToInstance } from 'class-transformer';
import {
  Booking, BookingCheckMerge, BookingConcurrentlyGroupListResp,
  BookingDetailResp,
  BookingDraftResp, BookingGroupListResp,
  BookingListResp,
  BookingSearch, BookingSignatureUpdateReq,
  DownloadSearch, GroupDialog, MeetingAddress, SignedParam
} from './booking-types';
import { plaintToPaged } from '../shared/utils/class-transform';

@Injectable({
  providedIn: 'root'
})
export class BookingService extends BaseService {

  toReloadList: EventEmitter<DataMode> = new EventEmitter<DataMode>();

  // emit bookingNo
  toBeCompleted: EventEmitter<string> = new EventEmitter<string>();

  constructor(private http: HttpService, private httpClient: HttpClient) {
    super();
  }

  /**
   * 预约列表
   * @param search 查询条件
   * @param type 数据类型
   */
  list(search: BookingSearch, type: DataMode): Observable<PagedResp<BookingListResp>> {
    let httpParams = new HttpParams();
    if (search.pageNum != null) {
      httpParams = httpParams.set('pageNum', search.pageNum.toString());
    }
    if (search.pageSize != null) {
      httpParams = httpParams.set('pageSize', search.pageSize.toString());
    }
    if (search.status != null) {
      httpParams = httpParams.set('status', search.status.toString());
    }
    if (search.bookingNo != null) {
      httpParams = httpParams.set('bookingNo', search.bookingNo.toString());
    }
    if (search.searchKey != null) {
      httpParams = httpParams.set('searchKey', search.searchKey.toString());
    }
    if (search.orderColumn != null) {
      httpParams = httpParams.set('orderColumn', search.orderColumn.toString());
    }
    if (search.orderAspect != null) {
      httpParams = httpParams.set('orderAspect', search.orderAspect.toString());
    }
    if (search.temporaryTimeStart != null) {
      httpParams = httpParams.set('temporaryTimeStart', search.temporaryTimeStart.toString());
    }
    if (search.temporaryTimeEnd != null) {
      httpParams = httpParams.set('temporaryTimeEnd', search.temporaryTimeEnd.toString());
    }
    if (search.appointmentTimeConfirmStart != null) {
      httpParams = httpParams.set('appointmentTimeConfirmStart', search.appointmentTimeConfirmStart.toString());
    }
    if (search.appointmentTimeConfirmEnd != null) {
      httpParams = httpParams.set('appointmentTimeConfirmEnd', search.appointmentTimeConfirmEnd.toString());
    }
    if (search.name != null) {
      httpParams = httpParams.set('name', search.name.toString());
    }
    if (search.customCode != null) {
      httpParams = httpParams.set('customCode', search.customCode.toString());
    }
    if (search.accountName != null) {
      httpParams = httpParams.set('accountName', search.accountName.toString());
    }
    if (search.productCode != null) {
      httpParams = httpParams.set('productCode', search.productCode.toString());
    }
    let uri = '';
    switch (type) {
      case DataMode.OWN:
        uri = '/list-mine';
        break;
      case DataMode.SUBORDINATE:
        uri = '/list-subordinate';
        break;
      case DataMode.FOLLOWUP:
        uri = '/list-follow-up';
        break;
      case DataMode.SALESGROUP:
        uri = '/list-sales-group';
        break;
    }
    return this.http.get<ApiResponse<PagedResp<BookingListResp>>>(API.bookingV3 + uri, {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 预约分组列表
   * @param search 查询条件
   * @param type 数据类型
   */
  groupList(search: BookingSearch, type: DataMode): Observable<PagedResp<BookingGroupListResp>> {
    let httpParams = new HttpParams();
    if (search.pageNum != null) {
      httpParams = httpParams.set('pageNum', search.pageNum.toString());
    }
    if (search.pageSize != null) {
      httpParams = httpParams.set('pageSize', search.pageSize.toString());
    }
    if (search.status != null) {
      httpParams = httpParams.set('status', search.status.toString());
    }
    if (search.bookingNo != null) {
      httpParams = httpParams.set('bookingNo', search.bookingNo.toString());
    }
    if (search.name != null) {
      httpParams = httpParams.set('name', search.name.toString());
    }
    if (search.customCode != null) {
      httpParams = httpParams.set('customCode', search.customCode.toString());
    }
    if (search.accountName != null) {
      httpParams = httpParams.set('accountName', search.accountName.toString());
    }
    if (search.orderColumn != null) {
      httpParams = httpParams.set('orderColumn', search.orderColumn.toString());
    }
    if (search.orderAspect != null) {
      httpParams = httpParams.set('orderAspect', search.orderAspect.toString());
    }
    if (search.productCode != null) {
      httpParams = httpParams.set('productCode', search.productCode.toString());
    }
    if (search.temporaryTimeStart != null) {
      httpParams = httpParams.set('temporaryTimeStart', search.temporaryTimeStart.toString());
    }
    if (search.temporaryTimeEnd != null) {
      httpParams = httpParams.set('temporaryTimeEnd', search.temporaryTimeEnd.toString());
    }
    let uri = '';
    switch (type) {
      case DataMode.OWN:
        uri = '/group-list-mine';
        break;
      case DataMode.SUBORDINATE:
        uri = '/group-list-subordinate';
        break;
      case DataMode.FOLLOWUP:
        uri = '/group-list-follow-up';
        break;
      case DataMode.SALESGROUP:
        uri = '/group-list-sales-group';
        break;
    }
    return this.http.get<ApiResponse<PagedResp<BookingGroupListResp>>>(API.bookingV3 + uri, {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 共同预约分组列表
   * @param search 查询条件
   * @param type 数据类型
   */
  groupListConcurrently(search: BookingSearch): Observable<PagedResp<BookingConcurrentlyGroupListResp>> {
    let httpParams = new HttpParams();
    if (search.pageNum != null) {
      httpParams = httpParams.set('pageNum', search.pageNum.toString());
    }
    if (search.pageSize != null) {
      httpParams = httpParams.set('pageSize', search.pageSize.toString());
    }
    if (search.status != null) {
      httpParams = httpParams.set('status', search.status.toString());
    }
    if (search.bookingNo != null) {
      httpParams = httpParams.set('bookingNo', search.bookingNo.toString());
    }
    if (search.name != null) {
      httpParams = httpParams.set('name', search.name.toString());
    }
    if (search.accountName != null) {
      httpParams = httpParams.set('accountName', search.accountName.toString());
    }
    if (search.productCode != null) {
      httpParams = httpParams.set('productCode', search.productCode.toString());
    }
    return this.http.get<ApiResponse<PagedResp<BookingConcurrentlyGroupListResp>>>(API.bookingV3 + '/group-list-concurrently', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return plaintToPaged(BookingConcurrentlyGroupListResp, data.body.data);
    }));
  }

  /**
   * 预约信息
   * @param bookingNo 预约编号
   */
  detail(bookingNo: string): Observable<BookingDetailResp> {
    return this.http.get<ApiResponse<BookingDetailResp>>(API.bookingV3 + '/info/' + bookingNo, {
      observe: 'response'
    }).pipe(map(data => {
      return plainToClass(BookingDetailResp, data.body.data);
    }));
  }

  /**
   * 预约草稿
   * @param draftReq 申请参数
   */
  draft(draftReq: Booking): Observable<BookingDraftResp> {
    return this.http.post<ApiResponse<BookingDraftResp>>(API.bookingV4 + '/draft', draftReq, {
      observe: 'response'
    }).pipe(map(data => {
      return plainToClass(BookingDraftResp, data.body.data);
    }));
  }

  /**
   * 提交预约 - 新建记录
   * @param applyReq 申请参数
   */
  apply(applyReq: Booking): Observable<string> {
    return this.http.post<ApiResponse<string>>(API.bookingV6 + '/apply', applyReq, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 提交预约草稿 - 已存在预约
   * @param booking 预约信息
   */
  commit(booking: Booking): Observable<string> {
    return this.http.put<ApiResponse<string>>(API.bookingV4 + '/commit/' + booking.bookingNo, booking, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }
  /**
   * 更新投保人信息并返回投保人信息百分比
   * @param productPlanReq 预约产品计划信息
   */
  productPlan(productPlanReq: ProductPlanReq): Observable<number> {
    return this.http.put<ApiResponse<number>>(API.bookingV3 + '/product-plan', productPlanReq, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 更新预约信息并返回信息百分比
   * @param infoReq 预约信息
   */
  book(infoReq: BookingInfoReq): Observable<number> {
    return this.http.put<ApiResponse<number>>(API.bookingV4 + '/book', infoReq, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 更新投保人信息并返回投保人信息百分比
   * @param ownerReq 投保人信息
   */
  owner(ownerReq: BookingOwnerReq): Observable<number> {
    return this.http.put<ApiResponse<number>>(API.bookingV5 + '/owner', ownerReq, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 更新准受保人信息并返回准受保人信息百分比
   * @param insuredReq 受保人信息
   */
  insured(insuredReq: BookingInsuredReq): Observable<number> {
    return this.http.put<ApiResponse<number>>(API.bookingV4 + '/insured', insuredReq, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 更新受益人信息并返回信息百分比
   * @param beneficiaryReq 受益人信息
   */
  beneficiary(beneficiaryReq: BookingBeneficiaryReq): Observable<number> {
    return this.http.put<ApiResponse<number>>(API.bookingV3 + '/beneficiary', beneficiaryReq, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 關聯計劃書
   * @param associateProposalReq 關聯計劃書表单
   */
  associateProposal(associateProposalReq: BookingAssociateProposalReq): Observable<number> {
    return this.http.put<ApiResponse<number>>(API.bookingV3 + '/relation-proposal', associateProposalReq, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 解除關聯計劃書
   * @param bookingNo 预约編號
   */
  disassociateProposal(bookingNo: string): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.bookingV3 + '/release-proposal/' + bookingNo, null, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  updateAttachments(attachmentsReq: BookingAttachmentReq): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.bookingV3 + '/attachment', attachmentsReq, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 预约留言列表
   * @param bookingNo 预约编号
   */
  message(bookingNo: string): Observable<PendingReply[]> {
    return this.http.get<ApiResponse<PendingReply[]>>(API.bookingV3 + '/dialog-list/' + bookingNo, {
      observe: 'response'
    }).pipe(map(data => {
      return plainToClass(PendingReply, data.body.data);
    }));
  }

  dialogReply(dialogRep: DialogReply): Observable<boolean> {
    return this.http.post<ApiResponse<boolean>>(API.bookingV3 + '/dialog-save', dialogRep, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }


  /**
   * 导出Excel
   * @param search Query
   */

  downloadExcel(search: DownloadSearch): Observable<any> {
    let httpParams = new HttpParams();
    // if (search.productCode != null) {
    //   httpParams = httpParams.set('productCode', search.productCode.toString());
    // }
    if (search.addEndDate != null) {
      httpParams = httpParams.set('addEndDate', search.addEndDate.toString());
    }
    if (search.addStartDate != null) {
      httpParams = httpParams.set('addStartDate', search.addStartDate.toString());
    }
    if (search.type != null) {
      httpParams = httpParams.set('type', search.type.toString());
    }
    return this.http.download(API.bookingV3 + '/download-summary-record', {
      params: httpParams,
      responseType: 'blob',
      observe: 'response'
    }).pipe(map((data: any) => {
      return data;
    }));
  }


  /**
   * 导出PDF
   */

  exportPDF(): Observable<any> {
    return this.httpClient.get(API.bookingV3 + '/download-model', {
      responseType: 'blob',
      observe: 'response'
    }).pipe(map((data: any) => {
      return data;
    }));
  }

  /**
   * 导出Excel
   */

  exportExcel(bookingNo): Observable<any> {
    return this.httpClient.get(API.bookingV3 + '/export/' + bookingNo, {
      responseType: 'blob',
      observe: 'response'
    }).pipe(map((data: any) => {
      return data;
    }));
  }

  /**
   * 修改动态字段值
   * @param param 参数
   */
  domain(param): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.bookingV3 + '/domain-metadata', param, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 更新转保说明
   * @param param 参数
   */
  transfer(param): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.bookingV3 + '/update-transfer', param, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * cash表单
   * @param bookingNo 查询条件
   * @param cashFormEnum 数据类型
   */

  cashQuestion(bookingNo: string, cashFormEnum: string): Observable<any> {
    let httpParams = new HttpParams();
    if (bookingNo !== null) {
      httpParams = httpParams.set('bookingNo', bookingNo.toString());
    }
    if (cashFormEnum !== null) {
      httpParams = httpParams.set('cashFormEnum', cashFormEnum.toString());
    }
    return this.httpClient.get<ApiResponse<any>>(API.bookingV4 + '/cash/question', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * cash表单
   * @param req 问卷答案
   */
  cashAnswer(req: any): Observable<boolean> {
    return this.http.post<ApiResponse<boolean>>(API.bookingV4 + '/cash/answer', req, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 健康问卷
   * @param bookingNo 预约编号
   */
  question(bookingNo: string): Observable<HeathInfo> {
    let httpParams = new HttpParams();
    if (bookingNo != null) {
      httpParams = httpParams.set('bookingNo', bookingNo);
    }
    // @ts-ignore
    return this.http.get<ApiResponse<HeathInfo>>(API.bookingV4 + '/question', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return plainToClass(HeathInfo, data.body.data);
    }));
  }

  /**
   * 保存健康问卷答案
   * @param params  params
   */
  bookingAnswer(params: HealthInfoAnswer): Observable<boolean> {
    return this.http.post<ApiResponse<boolean>>(API.bookingV4 + '/answer', params, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  downloadPdf(bookingNo: string, formId: string): Observable<Uint8Array> {
    let httpParams = new HttpParams();
    if (formId != null) {
      httpParams = httpParams.set('formId', formId);
    }
    return this.httpClient
      .get(API.bookingV3 + '/download-form/' + bookingNo, {
        params: httpParams,
        responseType: 'arraybuffer'
      }).pipe(map((arrayBuffer: ArrayBuffer) => new Uint8Array(arrayBuffer)));
  }

  /**
   *  预约表单列表
   * @param bookingNo 预约No
   * @param applicationType  状态
   */
  fromList(bookingNo: string, applicationType: string): Observable<SignatureList[]> {
    let httpParams = new HttpParams();
    if (bookingNo != null) {
      httpParams = httpParams.set('bookingNo', bookingNo);
    }
    if (applicationType != null) {
      httpParams = httpParams.set('applicationType', applicationType);
    }
    return this.http.get<ApiResponse<SignatureList[]>>(API.bookingV3 + '/signature/list', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return plainToInstance(SignatureList, data.body.data);
    }));
  }

  /**
   * 预约表单重置签名
   * @param param 参数
   */
  putResetSignature(param): Observable<boolean> {
    let httpParams = new HttpParams();
    if (param.bookingNo != null) {
      httpParams = httpParams.set('bookingNo', param.bookingNo.toString());
    }
    if (param.bookingFormId != null) {
      httpParams = httpParams.set('bookingFormId', param.bookingFormId.toString());
    }
    return this.http.put<ApiResponse<boolean>>(API.bookingV3 + '/signature/re-sign', null, {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 预约表单签名
   * @param param: SignedParam 参数
   */
  putSignature(param: SignedParam): Observable<SignedData> {
    return this.http.put<ApiResponse<SignedData>>(API.bookingV3 + '/signature', param, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 预约表单最终确认提交
   * @param param: SignedParam 参数
   */
  putSignatureEnd(param: SignedParam): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.bookingV3 + '/signature/end', param, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 预约表单上传凭证
   * @param param 参数
   */
  putUpload(param: SignatureList): Observable<Material[]> {
    return this.http.put<ApiResponse<Material[]>>(API.bookingV3 + '/credentials/save', param, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 關聯FNA
   * @param associateFnaReq 關聯FNA表单
   */
  associateFNA(associateFnaReq: BookingAssociateFnaReq): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.bookingV3 + '/related-fna', associateFnaReq, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 解除關聯FNA
   * @param bookingNo 预约編號
   */
  disassociateFNA(bookingNo: string): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.bookingV3 + '/release-fna/' + bookingNo, null, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 删除草稿预约
   * @param bookingNo 草稿预约编号
   */
  delete(bookingNo: string): Observable<boolean> {
    return this.http.delete<ApiResponse<boolean>>(API.bookingV3 + '/deleted/' + bookingNo, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 检查是否可以合并
   * @param bookingNo 预约编号
   */
  checkMerge(bookingNo: string): Observable<BookingCheckMerge> {
    return this.http.get<ApiResponse<BookingCheckMerge>>(API.bookingV3 + '/check-merge/' + bookingNo, {
      observe: 'response'
    }).pipe(map(data => {
      return plainToInstance(BookingCheckMerge, data.body.data);
    }));
  }

  /**
   * 合并
   * @param bookingNo 预约编号
   * @param mergeBookingNo 合并分组预约编号
   */
  merge(bookingNo: string, mergeBookingNo: string): Observable<boolean> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('bookingNo', bookingNo);
    httpParams = httpParams.set('mergeBookingNo', mergeBookingNo);
    return this.http.put<ApiResponse<boolean>>(API.bookingV3 + '/merge-booking', null, {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 预约留言确认
   * @param param 参数
   */
  putDialogConfirm(param): Observable<boolean> {
    let httpParams = new HttpParams();
    if (param.id != null) {
      httpParams = httpParams.set('id', param.id.toString());
    }
    if (param.confirm != null) {
      httpParams = httpParams.set('confirm', param.confirm.toString());
    }
    return this.http.put<ApiResponse<boolean>>(API.bookingV3 + '/dialog-confirm', null, {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * .新增预约分组留言
   * @param dialogRep 参数
   */
  groupDialogReply(dialogRep: GroupDialog): Observable<boolean> {
    return this.http.post<ApiResponse<boolean>>(API.bookingV3 + '/group-dialog-save', dialogRep, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }


  /**
   * .新增预约分组留言
   * @param dialogRep 参数
   */
  groupAnnexReply(dialogRep: any): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.bookingV3 + '/group-attachment', dialogRep, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 获取我下线账户列表(包括本人)
   * 没有
   */
  subAccountList(): Observable<BookingDetailResp> {
    return this.http.get<ApiResponse<BookingDetailResp>>(API.bookingV3 + '/all-sub', {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }


  /**
   * 共同预约分组列表
   * @param search 查询条件
   */
  getMeetingAddressList(search: string): Observable<MeetingAddress[]> {
    let httpParams = new HttpParams();
    if (search != null) {
      httpParams = httpParams.set('addressType', search.toString());
    }
    return this.http.get<ApiResponse<MeetingAddress[]>>(API.bookingV3 + '/address', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 是否要求纸质合同
   * @param params |{ bookingNo, isPaperContracts, authorizeCollect}
   */
  putPaperContracts(params): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.bookingV3 + '/update-paper-contracts', params, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * .签名
   * @param info .签名信息
   */
  signature(info: BookingSignatureUpdateReq): Observable<string> {
    return this.http.put<ApiResponse<string>>(API.bookingV3 + '/signature/new', info, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

}
