import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BookingVip, Instance, Phone } from '../../../api/types';
import { PropertySelectOption } from '../../../shared/component/property-select/property-select.component';
import { MetadataService } from '../../../shared/service/metadata.service';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { PhoneEditComponent } from '../../../shared/component/phone-edit/phone-edit.component';
import { TranslateService } from '@ngx-translate/core';
import { Booking } from '../../booking-types';
import { formatDate } from '@angular/common';
import { Workbench } from '../../../workbench/workbench-types';
import { WorkbenchService } from '../../../workbench/workbench.service';
import { ToastrService } from 'ngx-toastr';
import { SubSalesChannelComponent } from '../../../shared/component/sub-sales-channel/sub-sales-channel.component';
import { AccountService } from '../../../account/account.service';
import { BookingService } from '../../booking.service';
import { DisabledTimeFn } from 'ng-zorro-antd/date-picker';
import { addDays, differenceInCalendarDays } from 'date-fns';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-booking-info-edit',
  templateUrl: './booking-info-edit.component.html',
  styleUrls: ['./booking-info-edit.component.less']
})
export class BookingInfoEditComponent implements OnInit {

  loading = false;
  isCreate = false;
  title: string;
  booking: Booking;
  applicationTypeOptions: PropertySelectOption[] = [];
  meetingLocationOptions: PropertySelectOption[] = [];
  airportTransferOptions: PropertySelectOption[] = [];
  requiredInformation: string[];
  vipOption = false;
  isEdit = false;
  showName: string;

  startValue: string;
  workbench: Workbench;
  instance: Instance;

  tip: string;
  preUnderwritingEdit = false;

  @Output()
  bookingSaved: EventEmitter<Booking> = new EventEmitter<Booking>();

  disabledDate = (current: Date): boolean => {
    return this.disabledDateFlag(current);
  }
  disabledDateTime: DisabledTimeFn = () => {
    return {
      nzDisabledHours: () => this.rangeHours(),
      nzDisabledMinutes: (hour: number): number[] => this.rangeMinutes(hour),
      nzDisabledSeconds: () => [55, 56]
    };
  }
  constructor(private metadataService: MetadataService,
              private drawerService: NzDrawerService,
              private translate: TranslateService,
              private workbenchService: WorkbenchService,
              private toastr: ToastrService,
              private accountService: AccountService,
              private bookingService: BookingService) {
  }

  ngOnInit(): void {
    this.instance = this.metadataService.mockInstance;
    this.workbench = this.workbenchService.workbench;
    this.getMeetingAddressList();
    const bookingApplicationTypes = this.metadataService.values('bookingApplicationType');
    for (const bookingApplicationType of bookingApplicationTypes) {
      this.applicationTypeOptions.push(new PropertySelectOption(bookingApplicationType.value, bookingApplicationType.key));
    }
    // const meetingPlaces = this.metadataService.values('meetingPlace');
    // for (const meetingPlace of meetingPlaces) {
    //   this.meetingLocationOptions.push(new PropertySelectOption(meetingPlace.value, meetingPlace.key));
    // }
    const airportTransfers = this.metadataService.values('airportTransfer');
    this.airportTransferOptions = [];
    for (const airportTransfer of airportTransfers) {
      this.airportTransferOptions.push(new PropertySelectOption(airportTransfer.value, airportTransfer.key));
    }
    this.translate.get(['Yes', 'No']).subscribe(
      (res: any) => {
      });
    this.translate.get(['RequiredInformation1', 'RequiredInformation2', 'RequiredInformation3', 'RequiredInformation4', 'RequiredInformation5']).subscribe(
      (res: any) => {
        this.requiredInformation = [res.RequiredInformation1, res.RequiredInformation2, res.RequiredInformation3, res.RequiredInformation4, res.RequiredInformation5];
      });
    if (this.isFone) {
      this.vipOption = !!this.booking.vip && !!this.booking.vip.airportTransfer;
    } else {
      this.vipOption = !!this.booking.vip && !!this.booking.vip.transportDemand;
    }
    // this.startValue = this.booking.temporaryTime ? new Date(this.booking.temporaryTime) : null;
    this.startValue = this.booking.temporaryTime ? this.convertTimestampToFormattedDate(this.booking.temporaryTime, this.offsets()) : null;
    console.log(this.startValue, 'startValuestartValue');

    if (this.isCreate) {
      this.booking.accountName = this.accountService.passport.accountName;
      this.booking.nickName = this.accountService.passport.name;

      this.booking.accompanyName = this.accountService.accountInfo.nickName;
      this.booking.accompanyAreaCode = this.accountService.passport.areaCode;
      this.booking.accompanyPhone = this.accountService.passport.phone;
      this.booking.accompanyEmail = this.accountService.passport.email;
    }
  }


  onConsultantPhone(): void {
    this.translate.get('ConsultantPhone').subscribe(
      (title: any) => {
        const phone = new Phone();
        phone.areaCode = this.booking.accompanyAreaCode;
        phone.phone = this.booking.accompanyPhone;

        const drawerRef = this.drawerService.create<PhoneEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: PhoneEditComponent,
          nzContentParams: {
            title,
            phone
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.phoneSaved.subscribe(
            data => {
              drawerRef.close();
              this.booking.accompanyAreaCode = data?.areaCode;
              this.booking.accompanyPhone = data?.phone;
            }
          );
        });
      });
  }

  onHongKongContactNumber(): void {
    this.translate.get('HongKongContactNumber').subscribe(
      (title: any) => {
        const phone = new Phone();
        phone.areaCode = this.booking.hongkongContactNumberAreaCode;
        phone.phone = this.booking.hongkongContactNumber;

        const drawerRef = this.drawerService.create<PhoneEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: PhoneEditComponent,
          nzContentParams: {
            title,
            phone
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.phoneSaved.subscribe(
            data => {
              drawerRef.close();
              this.booking.hongkongContactNumberAreaCode = data?.areaCode;
              this.booking.hongkongContactNumber = data?.phone;
            }
          );
        });
      });
  }

  onVipOptionChange(vipOption: boolean): void {
    if (this.vipOption === vipOption) {
      return;
    }
    this.vipOption = vipOption;
    if (this.vipOption) {
      if (!this.booking.vip) {
        this.booking.vip = new BookingVip();
      }
    } else {
      this.booking.vip = null;
    }
  }

  onPreUnderwritingChange(flag: boolean): void {
    if (this.booking.preUnderwriting === flag) {
      return;
    }
    this.booking.preUnderwriting = flag;
  }

  onSave(): void {
    if (!this.canSave) {
      return;
    }
    if (this.booking.openAccountInfo?.openAccountDate) {
      this.booking.openAccountInfo.openAccountDate = formatDate(this.booking.openAccountInfo.openAccountDate, 'yyyy-MM-dd HH:mm', 'en-US');
    }

    this.bookingSaved.emit(this.booking);
  }

  get showBookingVipChargeRemark(): boolean {
    if (!this.workbench.bookingVipChargeRemark) {
      return false;
    }
    return this.instance.isWanson() ? this.workbench.showVehicleServiceConfig : true;
  }

  get isFone(): boolean {
    return this.instance.isFone();
  }

  get canSave(): boolean {

    let result = true;
    do {
      if (this.instance.isWanson()) {
        if (!this.booking.preUnderwriting && !this.booking.temporaryTime) {
          result = false;
          this.translate.get(['PleaseSelect', 'PreferredTime']).subscribe(
            (res: any) => {
              this.tip = res.PleaseSelect + res.PreferredTime;
            });
          break;
        } else {
          this.tip = '';
        }
        if (this.booking.openBankAccount) {
          if (!this.booking.openAccountInfo?.openAccountBankName) {
            result = false;
            this.translate.get(['PleaseEnter', 'OpenAccountBankName']).subscribe(
              (res: any) => {
                this.tip = res.PleaseEnter + res.OpenAccountBankName;
              });
            break;
          }
          // if (!this.booking.openAccountInfo?.openAccountDate) {
          //   result = false;
          //   this.translate.get(['PleaseSelect', 'StartTimeOpenAccount']).subscribe(
          //     (res: any) => {
          //       this.tip = res.PleaseSelect + res.StartTimeOpenAccount;
          //     });
          //   break;
          // }
          // if (!this.booking.openAccountInfo?.openAccountEndDate) {
          //   result = false;
          //   this.translate.get(['PleaseSelect', 'EndTimeOpenAccount']).subscribe(
          //     (res: any) => {
          //       this.tip = res.PleaseSelect + res.EndTimeOpenAccount;
          //     });
          //   break;
          // }
        }
      } else if (this.instance.isWrisefs()) {
        this.tip = '';
      } else {
        if (!this.booking.temporaryTime) {
          result = false;
          this.translate.get(['PleaseSelect', 'PreferredTime']).subscribe(
            (res: any) => {
              this.tip = res.PleaseSelect + res.PreferredTime;
            });
          break;
        } else {
          this.tip = '';
        }
      }

    } while (false);

    return result;
  }

  onSub(type: string): void {
    let title = '';
    switch (type) {
      case 'Referee':
      case 'Referee2':
        this.translate.get('Referee').subscribe(
          (res: any) => {
            title = res;
          });
        break;
      case 'ServiceAgent':
        this.translate.get('ServiceAgent').subscribe(
          (res: any) => {
            title = res;
          });
        break;
      case 'ServiceAgent2':
        this.translate.get('ServiceAgent').subscribe(
          (res: any) => {
            title = res;
          });
        break;
    }
    if (this.isCreate ? true : type !== 'Referee' && type !== 'Referee2') {
      const drawerRef = this.drawerService.create<SubSalesChannelComponent, { value: any }, string>({
        nzWidth: 600,
        nzMaskClosable: true,
        nzContent: SubSalesChannelComponent,
        nzContentParams: {
          accountName: [this.booking.accountName],
          title
        },
      });

      drawerRef.afterOpen.subscribe(() => {
        const component = drawerRef.getContentComponent();
        component.strSaved.subscribe(
          data => {
            drawerRef.close();
            console.log(data);
            if (data) {
              switch (type) {
                case 'Referee':
                  this.booking.accountName = data.accountName;
                  this.booking.nickName = data.name;
                  break;
                case 'Referee2':
                  this.booking.secondAccountName = data.accountName;
                  this.booking.secondNickName = data.name;
                  break;
                case 'ServiceAgent':
                  this.booking.serviceAgent = data.accountName;
                  break;
                case 'ServiceAgent2':
                  this.booking.serviceAgent2 = data.accountName;
                  break;
              }

            }
          }
        );
      });
    }
  }

  getMeetingAddressList(): void {
    this.bookingService.getMeetingAddressList('MEETING_POINT')
      .subscribe(data => {
        data.map(item => {
          this.meetingLocationOptions.push(new PropertySelectOption(item.shortName, item.shortName));
        });
        if (this.booking.meetingPlace && !data?.some(item => item.shortName === this.booking.meetingPlace)) {
          this.meetingLocationOptions.push(new PropertySelectOption(this.booking.meetingPlace, this.booking.meetingPlace));
        }
      });
  }

  temporaryTimeChange(date: Date): void {
    if (!date) {
      return;
    }
    const dateString = formatDate(date, 'yyyy-MM-dd HH:mm', 'en-US');
    this.startValue = dateString;
    console.log('用户选择的日期:', dateString);
    const dateInTimeZone = this.convertToTimezone(dateString, this.offsets());
    console.log('转换为指定时区的日期的时间戳:', dateInTimeZone);
    this.booking.temporaryTime = dateInTimeZone;
  }
  offsets(): string {
    const str = this.workbench.systemTimeZone ? this.workbench.systemTimeZone.split(',')[0] : '';
    const newString = str.replace(/([+-])/g, (match) => {
      return match === '+' ? '-' : '+';
    });
    return newString ? newString : '';
  }

  // 将日期转换为指定时区
  convertToTimezone(date: string, timeZone: string): number {
    const momentDate = moment.tz(date, `Etc/${timeZone}`);
    return momentDate.valueOf();
  }

  convertTimestampToFormattedDate(timestamp: number, timeZone: string): string {
    const zonedDate = moment.tz(timestamp, `Etc/${timeZone}`);
    return zonedDate.format('YYYY-MM-DD HH:mm');
  }

  range(start: number = 0, end: number = 20): number[] {
    const result: number[] = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  disabledDateFlag(current): boolean {
    if (this.workbench.appointmentDateLimit && this.workbench.appointmentDateLimit.dayLimit) {
      const today = new Date();
      const addToday = addDays(today, this.workbench.appointmentDateLimit.dayLimit);
      return differenceInCalendarDays(addToday, current) > 0;
    } else {
      return false;
    }
  }

  rangeHours(): number[] {
    const result: number[] = [];
    const startTime = this.workbench?.appointmentDateLimit?.timeStartLimit && parseInt(this.workbench.appointmentDateLimit.timeStartLimit.split(':')[0], 0) || 0;
    const endTime = this.workbench?.appointmentDateLimit?.timeEndLimit && parseInt(this.workbench.appointmentDateLimit.timeEndLimit.split(':')[0], 0) || 24;
    for (let i = 0; i < 24; i++) {
      if (startTime > i || i > endTime) {
        result.push(i);
      }
    }
    return result;
  }

  rangeMinutes(hour: number): number[] {
    const result: number[] = [];
    const startTime1 = this.workbench?.appointmentDateLimit?.timeStartLimit && parseInt(this.workbench.appointmentDateLimit.timeStartLimit.split(':')[0], 0) || 0;
    const startTime2 = this.workbench?.appointmentDateLimit?.timeStartLimit && parseInt(this.workbench.appointmentDateLimit.timeStartLimit.split(':')[1], 0) || 0;

    const startTime = this.workbench?.appointmentDateLimit?.timeEndLimit && parseInt(this.workbench.appointmentDateLimit.timeEndLimit.split(':')[0], 0) || 0;
    const endTime = this.workbench?.appointmentDateLimit?.timeEndLimit && parseInt(this.workbench.appointmentDateLimit.timeEndLimit.split(':')[1], 0) || 60;
    const minutes = this.workbench?.appointmentDateLimit?.minutes;
    if (minutes && minutes.length) {
      if (hour && hour === startTime) {
        for (let i = 0; i < 60; i++) {
          if (!minutes.some(item => parseInt(item, 0) === i) ||  i > endTime) {
            result.push(i);
          }
        }
      } else if (hour && hour === startTime1) {
        console.log(startTime2, 'startTime2');
        for (let i = 0; i < 60; i++) {
          if (!minutes.some(item => parseInt(item, 0) === i) ||  i < startTime2) {
            result.push(i);
          }
        }
      } else {
        for (let i = 0; i < 60; i++) {
          if (!minutes.some(item => parseInt(item, 0) === i)) {
            result.push(i);
          }
        }
      }
    }
    return result;
  }

  isPreferredTime(): boolean {
    if (this.instance.isWrisefs()) {
      return false;
    }
    return !this.booking.preUnderwriting;
  }

}
