import {Component, OnInit} from '@angular/core';
import {Attribute, AttributeSearch, CreateProposalCompare, Details, Groups, ProposalDetail} from '../proposal-compare';
import {Expose} from 'class-transformer';
import {ProposalCompareService} from '../proposal-compare.service';
import {error} from 'protractor';
import {StorageService} from '../../shared/service/storage.service';
import {DownloadService} from '../../shared/service/download.service';
import { Workbench } from '../../workbench/workbench-types';
import { WorkbenchService } from '../../workbench/workbench.service';

@Component({
  selector: 'app-proposal-compare-detail',
  templateUrl: './proposal-compare-detail.component.html',
  styleUrls: ['./proposal-compare-detail.component.less']
})

export class ProposalCompareDetailComponent implements OnInit {
  proposalDetail: ProposalDetail;
  details: Details[] = [];
  loading = false;
  groups: any[];
  copyGroups: any[];
  isEdit = false;
  scoreVisible = false;
  attributeScore: Attribute = new Attribute();
  attributeTitle: { name: string, proposalId: string, logo: string };
  isConfirmLoading = false;
  workbench: Workbench;

  constructor(private proposalCompareService: ProposalCompareService,
              private workbenchService: WorkbenchService,
              private storageService: StorageService,
              private downloadService: DownloadService) {
  }

  get uiTheme(): any {
    switch (this.proposalDetail.categoryCode) {
      case 'SAVINGS':
        return {
          category: 'SAVINGS',
          categoryColor: 'rgba(231, 191, 136)',
          classes: {
            systemName: 'sa-system-name',
            table: 'sa-table',
            groupLifebee: 'sa-lifebee',
            groupSquare: 'sa-square',
            propertyKey: 'bg-sa-property-key',
            propertyValue: 'bg-sa-property-value'
          }
        };
      case 'CRITICAL_ILLNESS':
        return {
          category: 'CRITICAL ILLNESS',
          categoryColor: 'rgba(216, 128, 153)',
          classes: {
            systemName: 'ci-system-name',
            table: 'ci-table',
            groupLifebee: 'ci-lifebee',
            groupSquare: 'ci-square',
            propertyKey: 'bg-ci-property-key',
            propertyValue: 'bg-ci-property-value'
          }
        };
      default:
        return {
          category: '',
          categoryName: '',
          header: '',
          groupIcon: '',
          classes: {
            systemName: '',
            table: '',
            propertyKey: '',
            propertyValue: ''
          }
        };
    }
  }

  ngOnInit(): void {
    if (this.proposalDetail) {
      this.changeList();
    }
    this.workbench = this.workbenchService.workbench;
  }

  changeList(): void {
    this.details = this.proposalDetail.details;
    const groups = [];
    if (this.details && this.details.length > 0) {
      const detailGroups = this.details[0].groups;
      detailGroups.forEach((detailGroup, i) => {
        const group = {
          name: detailGroup.name,
          key: detailGroup.key,
          checked: detailGroup.checked,
          companies: [],
          products: [],
          logos: [],
          proposalIds: [],
          attributes: [],
          companyNames: []
        };
        detailGroup.attributes.forEach((attribute, j) => {
          const attr = {
            attribute: attribute.attribute,
            name: attribute.name,
            checked: attribute.checked,
            values: []
          };
          this.details.forEach((detail) => {
            const value = {
              attribute: attribute.attribute,
              name: attribute.name,
              score: detail.groups[i].attributes[j].score,
              value: detail.groups[i].attributes[j].value
            };
            attr.values.push(value);
          });
          group.attributes.push(attr);
        });

        this.details.forEach((detail) => {
          group.products.push(detail.productName);
          group.companyNames.push(detail.companyName);
          group.logos.push(detail.logo);
          group.proposalIds.push(detail.proposalId);
          group.companies.push(detail.companyName);
        });

        groups.push(group);
      });
      this.groups = groups;
      this.copyGroups = JSON.parse(JSON.stringify(this.groups));
      console.log(this.copyGroups);
    }
  }

  onCancel(): void {
    this.copyGroups = JSON.parse(JSON.stringify(this.groups));
    this.isEdit = false;
  }

  onSave(): void {
    const groups = [];
    const attributes = [];
    this.copyGroups.forEach(e => {
      if (!e.checked) {
        groups.push(e.key);
      }
      if (e.attributes) {
        e.attributes.forEach(item => {
          if (!item.checked) {
            attributes.push(item.attribute);
          }
        });
      }
    });
    const params: CreateProposalCompare = new CreateProposalCompare();
    console.log(groups);
    console.log(attributes);
    params.groups = Array.from(new Set(groups));
    params.attributes = Array.from(new Set(attributes));
    params.id = this.proposalDetail.id;
    params.name = this.proposalDetail.name;
    params.categoryCode = this.proposalDetail.categoryCode;
    params.asterisk = this.proposalDetail.asterisk;
    this.loading = true;
    this.proposalCompareService.edit(params).subscribe(data => {
      this.proposalCompareService.details(this.proposalDetail.id).subscribe(
        proposalDetail => {
          this.proposalDetail = proposalDetail;
          this.changeList();
          this.loading = false;
          this.isEdit = false;
        },
        error1 => {
          this.loading = false;
        }
      );

    }, err => {
      this.isEdit = false;

    });
  }


  Edit(): void {
    this.isEdit = true;
  }

  onScoreDetail(index: any, group: any, value: any): void {

    if (!this.workbench.starRatingConfig || this.isEdit === false) {
      return;
    }
    this.attributeTitle = {
      name: group.products[index],
      proposalId: group.proposalIds[index],
      logo: group.logos[index]
    };
    this.attributeScore = JSON.parse(JSON.stringify(value));
    this.scoreVisible = true;
  }

  handleCancel(): void {
    this.scoreVisible = false;
  }

  handleOk(): void {
    this.isConfirmLoading = true;
    const params: AttributeSearch = new AttributeSearch();
    if (this.attributeScore.score) {
      params.score = this.attributeScore.score;
    }
    if (this.proposalDetail.id) {
      params.id = this.proposalDetail.id;
    }
    if (this.attributeScore.attribute) {
      params.attribute = this.attributeScore.attribute;
    }
    if (this.attributeTitle.proposalId) {
      params.proposalId = this.attributeTitle.proposalId;
    }
    this.proposalCompareService.attributeScore(params).subscribe(
      data => {
        this.proposalCompareService.details(this.proposalDetail.id).subscribe(
          proposalDetail => {
            this.proposalDetail = proposalDetail;
            this.changeList();
            this.scoreVisible = false;
            this.isConfirmLoading = false;
          },
          error1 => {
            this.isConfirmLoading = false;
          }
        );
      },
      e => {
        this.isConfirmLoading = false;
      }
    );

  }


  onScore($event: number): void {
    console.log($event);
    this.attributeScore.score = String($event);
  }

  Number(score: any): number {
    return score ? Number(score) : score;
  }

  onRetry(): void {
    this.proposalCompareService.retry(this.proposalDetail.id).subscribe(data => {
      console.log(data);
    });
  }

  onShare(): void {

  }

  onDownload(type: string): void {
    // this.btnLoading = true;
    this.loading = true;
    // console.log('-', type);
    let url = null;
    if (type === 'CN') {
      url = this.proposalDetail.urlCn.filePath;
    }
    if (type === 'HK') {
      url = this.proposalDetail.urlHk.filePath;
    }
    if (type === 'EN') {
      url = this.proposalDetail.urlUs.filePath;
    }
    this.storageService.accessToken(url)
      .subscribe(
        data => {
          // console.log(data);
          this.downloadService.download(data.accessToken, this.proposalDetail.urlCn.fileName);
          this.loading = false;
        },
        err => {
          this.loading = false;
        });
  }
}
