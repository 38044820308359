import { Component, OnInit } from '@angular/core';
import { DialogToDoRes, PolicyToDoRes } from '../to-do-types';
import {
  DataMode,
  MessageSubType,
  MessageType,
  PendingDetail,
  PendingReply, Underwriting, UnderwritingDetailResp,
  UnderWritingListResp,
  UploadTokenReq,
  UploadType
} from '../../api/types';
import { ToDoService } from '../to-do.service';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { PolicyService } from '../../policy/policy.service';
import { EventRecordComponent } from '../components/event-record/event-record.component';
import { ContractDetails } from '../../contract/contract-type';
import { TranslateService } from '@ngx-translate/core';
import { BookingDetailResp, BookingListResp } from '../../booking/booking-types';
import { MessageDialogComponent } from '../../shared/component/message-dialog/message-dialog.component';
import { MessageService } from '../../message/message.service';
import { BookingService } from '../../booking/booking.service';
import { UnderwritingService } from '../../underwriting/underwriting.service';
import { UnderwritingSearch } from '../../underwriting/underwriting-types';

@Component({
  selector: 'app-message-to-do',
  templateUrl: './message-to-do.component.html',
  styleUrls: ['./message-to-do.component.less']
})
export class MessageToDoComponent implements OnInit {
  spinning = false;
  loading = false;
  toDoList: DialogToDoRes[] = [];

  uploadTokenReq: UploadTokenReq = new UploadTokenReq();

  constructor(private toDoService: ToDoService,
              private drawerService: NzDrawerService,
              private policyService: PolicyService,
              private translate: TranslateService,
              private messageService: MessageService,
              private bookingService: BookingService,
              private underwritingService: UnderwritingService) { }

  ngOnInit(): void {
    this.onReload();
  }
  onReload(): void {
    this.loading = true;
    this.toDoService.dialogV4()
      .subscribe(data => {
          console.log(data);
          this.toDoList = data;
          this.loading = false;
        }, error => {
          this.loading = false;
        }
      );
  }

  onDetail(list: DialogToDoRes): void {
    if (list.bookingNo) {
      this.bookingService.detail(list.bookingNo)
        .subscribe(
          bookingDetail => {
            this.onLeaveMessage(bookingDetail);
          });
    }
    if (list.underwritingNo) {
      const search = new UnderwritingSearch();
      search.underwritingNo = list.underwritingNo;
      this.underwritingService.list(search, DataMode.OWN)
        .subscribe(
          data => {
            if (data.list[0]) {
              this.onUnderwritingDialog(data.list[0]);
            }
          });
    }
    if (list.policyId) {
      this.onPolicyDialog(list.policyId);
    }
  }

  onPendingDetail(id: number): Promise<PendingDetail> {
    return new Promise((resolve, reject) => {
      this.policyService.serviceDetail(id)
        .subscribe(
          pendDetail => {
            resolve(pendDetail);
          },
          error => {
            reject(error);
          });
    });
  }

  onLeaveMessage(booking: BookingDetailResp): void {
    this.sendMesseage(booking.bookingNo, MessageType.BOOKING);
    const bookingNo = booking.bookingNo;
    this.uploadTokenReq.uploadType = UploadType.BOOKING;
    this.uploadTokenReq.middleName = bookingNo;
    const instance = this.bookingService.instance;
    let canReply = true;
    if (instance.key === 'wanson' || instance.key === 'dev') {
      canReply = true;
    } else {
      canReply = booking.status !== 'COMPLETED';
    }
    this.translate.get('BookingDialog').subscribe(
      title => {
        this.bookingService.message(bookingNo)
          .subscribe(
            data => {
              const drawerRef = this.drawerService.create<MessageDialogComponent, { value: PendingReply }, string>({
                nzWidth: 800,
                nzContent: MessageDialogComponent,
                nzContentParams: {
                  Title: title,
                  list: data,
                  uploadTokenReq: this.uploadTokenReq,
                  moduleId: bookingNo,
                  replyContent: canReply,
                  moduleType: 'Booking'
                }
              });
              drawerRef.afterOpen.subscribe(() => {
                const component = drawerRef.getContentComponent();
                component.dialogReplied.subscribe(
                  dialogRep => {
                    this.bookingService.dialogReply(dialogRep).subscribe(
                      () => {
                        this.bookingService.message(bookingNo).subscribe(
                          list => {
                            component.loadList(list);
                          });
                      });
                  });
                component.confirmParam.subscribe(
                  confirmData => {
                    this.bookingService.putDialogConfirm(confirmData).subscribe(
                      resFlag => {
                        if (resFlag) {
                          this.bookingService.message(bookingNo).subscribe(
                            list => {
                              component.loadList(list);
                            }
                          );
                        }
                      });
                  });
              });

              drawerRef.afterClose.subscribe(() => {
                this.onReload();
                // this.workbenchService.reloadWorkbench.emit('');
                this.messageService.messageUnreadEvent.emit('');
              });
            });
      });
  }

  sendMesseage(num, type): void{
    const params = {
      key: num,
      type,
      subType: MessageSubType.DIALOG,
    };
    this.messageService.putMessageReads(params).subscribe();
  }

  onUnderwritingDialog(underWriting: UnderWritingListResp): void {
    this.sendMesseage(underWriting.underwritingNo, MessageType.UNDERWRITING);
    this.uploadTokenReq.uploadType = UploadType.UNDERWRITING;
    this.uploadTokenReq.middleName = underWriting.underwritingNo;
    this.translate.get('UnderwritingDialog').subscribe(
      title => {
        this.underwritingService.dialogList(underWriting.underwritingNo)
          .subscribe(
            data => {
              const drawerRef = this.drawerService.create<MessageDialogComponent, { value: Underwriting }, string>({
                nzWidth: 800,
                nzContent: MessageDialogComponent,
                nzContentParams: {
                  Title: title,
                  list: data,
                  uploadTokenReq: this.uploadTokenReq,
                  moduleId: underWriting.underwritingNo,
                  moduleType: 'Underwriting',
                  replyContent: underWriting.isLeavingMessage
                }
              });
              drawerRef.afterOpen.subscribe(() => {
                const component = drawerRef.getContentComponent();
                component.dialogReplied.subscribe(
                  params => {
                    this.underwritingService.dialogReply(params).subscribe(
                      () => {
                        this.underwritingService.dialogList(underWriting.underwritingNo).subscribe(
                          list => {
                            component.loadList(list);
                          });
                      });
                  });
                component.confirmParam.subscribe(
                  confirmData => {
                    this.underwritingService.putDialogConfirm(confirmData).subscribe(
                      resFlag => {
                        if (resFlag) {
                          this.underwritingService.dialogList(underWriting.underwritingNo).subscribe(
                            list => {
                              component.loadList(list);
                            }
                          );
                        }
                      });
                  });
              });

              drawerRef.afterClose.subscribe(() => {
                this.onReload();
                this.messageService.messageUnreadEvent.emit('');
              });
            }
          );
      }
    );
  }

  onPolicyDialog(policyId: string): void {
    this.sendMesseage(policyId, MessageType.POLICY);
    this.uploadTokenReq.uploadType = UploadType.POLICY;
    this.uploadTokenReq.middleName = policyId;
    this.translate.get('LeaveMessage').subscribe(
      title => {
        this.policyService.dialogList(policyId)
          .subscribe(
            data => {
              const drawerRef = this.drawerService.create<MessageDialogComponent, { value: Underwriting }, string>({
                nzWidth: 800,
                nzContent: MessageDialogComponent,
                nzContentParams: {
                  Title: title,
                  list: data,
                  uploadTokenReq: this.uploadTokenReq,
                  moduleId: policyId,
                  moduleType: 'Policy'
                }
              });
              drawerRef.afterOpen.subscribe(() => {
                const component = drawerRef.getContentComponent();
                component.dialogReplied.subscribe(
                  dialogRep => {
                    this.policyService.dialogReply(dialogRep).subscribe(
                      () => {
                        this.policyService.dialogList(policyId).subscribe(
                          list => {
                            component.loadList(list);
                          }
                        );
                      }
                    );
                  }
                );
                component.confirmParam.subscribe(
                  confirmData => {
                    this.policyService.putDialogConfirm(confirmData).subscribe(
                      resFlag => {
                        if (resFlag) {
                          this.policyService.dialogList(policyId).subscribe(
                            list => {
                              component.loadList(list);
                            }
                          );
                        }
                      });
                  });
              });

              drawerRef.afterClose.subscribe(() => {
                this.onReload();
                this.messageService.messageUnreadEvent.emit('');
              });
            }
          );
      }
    );

  }

  getModule(list: DialogToDoRes): string {
    let str = '';
    if (list.bookingNo) {
      str = this.translate.instant('BookingModule');
    }
    if (list.underwritingNo) {
      str = this.translate.instant('UnderwritingMode');
    }
    if (list.policyId) {
      str = this.translate.instant('PolicyModule');
    }
    return str;
  }
}
