import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PropertySelectOption } from '../property-select/property-select.component';
import { MetadataService } from '../../service/metadata.service';

@Component({
  selector: 'app-property-gender',
  templateUrl: './property-gender.component.html',
  styleUrls: ['./property-gender.component.less']
})
export class PropertyGenderComponent implements OnInit {
  @Input() title: string;
  @Input() tip: string;
  @Input() tipVisible = false;
  @Input() bottomLineVisible = true;
  @Input() disabled = false;
  @Input() required = false;

  @Output()
  valueChange: EventEmitter<any> = new EventEmitter<any>();
  genderOptions: PropertySelectOption[] = [];

  innerValue: string;

  @Input()
  get value(): string {
    return this.innerValue;
  }

  set value(val: string) {
    this.innerValue = val;
    this.valueChange.emit(this.innerValue);
  }

  constructor(private metadataService: MetadataService) {
  }

  ngOnInit(): void {
    const genders = this.metadataService.values('gender');
    for (const gender of genders) {
      this.genderOptions.push(new PropertySelectOption(gender.value, gender.key));
    }
  }

}
