<nz-spin [nzSpinning]="spinning">
  <nz-tabset [nzSize]="'large'" [(nzSelectedIndex)]="selectIndex">
    <nz-tab [nzTitle]=myDeals>
      <app-bookings [mode]="dataService.dataModeOwn"></app-bookings>
    </nz-tab>
    <ng-template #myDeals>
      {{'MyDeals' | translate}}
    </ng-template>
    <nz-tab [nzTitle]=channelDeals *ngIf="workbench.pivot !== dataService.pivotOwn">
      <app-bookings [mode]="dataService.dataModeSubordinate"></app-bookings>
    </nz-tab>
    <ng-template #channelDeals>
      {{'MySubordinates' | translate}}
    </ng-template>
    <nz-tab [nzTitle]=myToDo *ngIf="workbench.tr">
      <app-bookings [mode]="dataService.dataModeFollowUp"></app-bookings>
    </nz-tab>
    <ng-template #myToDo>
      {{'MyToDo' | translate}}
    </ng-template>
    <nz-tab [nzTitle]=myTeam>
      <app-bookings [mode]="dataService.dataModeSalesGroup"></app-bookings>
    </nz-tab>
    <ng-template #myTeam>
      {{'MySalesGroup' | translate}}
    </ng-template>
  </nz-tabset>
</nz-spin>
