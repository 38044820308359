import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PropertySelectOption } from '../../shared/component/property-select/property-select.component';
import { MetadataService } from '../../shared/service/metadata.service';
import { ProductSelectionComponent, ProductSelectionType } from '../../shared/component/product-selection/product-selection.component';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { Product } from '../../product/product-types';
import { UnderwritingSearch } from '../underwriting-types';
import { DataMode, Instance } from '../../api/types';
import { Observable } from 'rxjs/internal/Observable';
import { ContactService } from '../../contact/contact.service';
import {formatDate} from '@angular/common';
import { NzOptionComponent } from 'ng-zorro-antd/select';
import { Tool } from '../../shared/utils/tool';
import { SubAccountListRes } from '../../contact/contact-types';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-underwriting-search',
  templateUrl: './underwriting-search.component.html',
  styleUrls: ['./underwriting-search.component.less']
})
export class UnderwritingSearchComponent implements OnInit {
  @Input()
  search: UnderwritingSearch;
  @Output()
  underwritingSearch: EventEmitter<UnderwritingSearch> = new EventEmitter<UnderwritingSearch>();
  statusOptions: PropertySelectOption[] = [];
  instance: Instance;
  accountNameOptions: SubAccountListRes[] = [];

  @Input()
  mode = DataMode.OWN;

  Dates: any[];

  constructor(private metadataService: MetadataService,
              private translate: TranslateService,
              private router: Router,
              private drawerService: NzDrawerService,
              private contactService: ContactService) {
  }

  ngOnInit(): void {
    this.instance = this.metadataService.mockInstance;
    const statusList = this.metadataService.values('underwritingStatus');
    this.statusOptions.push(new PropertySelectOption(this.translate.instant('All'), 'ALL'));
    for (const status of statusList) {
      this.statusOptions.push(new PropertySelectOption(status.value, status.key));
    }
    this.getAccountList();
  }

  onProductSelection(): void {
    const drawerRef = this.drawerService.create<ProductSelectionComponent, { value: string }, string>({
      nzWidth: 800,
      nzContent: ProductSelectionComponent,
      nzContentParams: {
        type: ProductSelectionType.DEFAULT,
        copyProduct: this.search.product
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const selectionComponent = drawerRef.getContentComponent();
      selectionComponent.productSelected
        .subscribe(
          product => {
            this.search.product = product;
            this.search.productCode = this.search.product.productCode;
            drawerRef.close();
          }
        );

    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });
  }

  onReset(): void {
    this.search = new UnderwritingSearch();
    this.Dates = null;
    this.router.navigate(['/user/underwriting']);
    this.underwritingSearch.emit(this.search);
  }

  onSearch(): void {
    this.underwritingSearch.emit(this.search);
  }

  getAccountList(): void {
    let observable: Observable<any>;
    switch (this.mode) {
      case 'SUBORDINATE':
        observable = this.contactService.getSubList();
        break;
      case 'SALESGROUP':
        observable = this.contactService.getTeamList();
        break;
    }
    observable?.subscribe(
      data => {
        this.accountNameOptions = JSON.parse(JSON.stringify(data)) || [];
      },
      error => {
      }
    );
  }

  get isShowBusiness(): boolean {
    return !(this.instance.key === 'fone' || this.instance.isBaylightOrDev() || this.instance.key === 'quanbao');
  }

  onInspectDateChange(time: number[]): void {
    if (time) {
      this.Dates = time;
      // console.log(time);
      // console.log(formatDate(time[0], 'yyyy-MM-dd HH:mm:ss', 'en-US'));
      const start = formatDate(time[0], 'yyyy-MM-dd', 'en-US');
      const end = formatDate(time[1], 'yyyy-MM-dd', 'en-US');
      const dateObj = new Date(start);
      const dateObj2 = new Date(end);
      const startDateObj = new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate());
      const endDateObj = new Date(dateObj2.getFullYear(), dateObj2.getMonth(), dateObj2.getDate());
      this.search.signDateStart = startDateObj.getTime();
      this.search.signDateEnd = endDateObj.getTime() + + 24 * 60 * 60 * 1000 - 1000;
    }
  }

  filterOption(input?: string, option?: NzOptionComponent): boolean {
    return Tool.filterOption(input, option);
  }
}
