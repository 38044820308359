<div>
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
    <div *ngIf="!listArr">
      <nz-skeleton [nzParagraph]="{ rows: 10 }"></nz-skeleton>
    </div>
    <div *ngIf="listArr" style="margin-top: 30px; ">
      <nz-table [nzBordered]="false"
                [nzOuterBordered]="true"
                [nzShowPagination]="true"
                [nzLoading]="spinning"
                [nzLoadingIndicator]="indicatorTemplate"
                [nzFrontPagination]="false"
                [nzData]="listArr.list"
                [nzPageIndex]="listArr.pageNum"
                [nzPageSize]="listArr.pageSize"
                [nzTotal]="listArr.total"
                [nzTitle]="tableHeader"
                [nzFooter]="tableFooter"
                (nzPageIndexChange)="onSearch($event)"
                [nzScroll]="{ x: '1150px'}">
        <ng-template #tableHeader>
          <nz-row>
            <nz-col [nzSpan]="12">
              <div class="list-header-title">{{'ProposalCustomizeList' | translate}}
                <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Refresh' | translate"
                   style="margin-left: 5px"
                   appThrottleClick (throttleClick)="onReload()">
                  <em nz-icon style="color: black;" nzType="reload" nzTheme="outline"></em>
                </a>
              </div>
            </nz-col>
            <nz-col [nzSpan]="12">
              <div style="width: 100%; display: flex; justify-content: flex-end;">
                <button nz-button nzType="default" nzSize="large" nzShape="circle"
                        nz-tooltip [nzTooltipTitle]="'Filter' | translate"
                        nz-popover [nzPopoverContent]="searchTemplate"
                        [(nzPopoverVisible)]="searchPopoverVisible"
                        nzPopoverTrigger="click"
                        nzPopoverPlacement="bottomRight">
                  <img alt="" width="22" src="assets/images/ic_search.svg">
                </button>

                <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                        [nzTooltipTitle]="'AddComparison' | translate" style="margin-left: 20px;"
                        appThrottleClick (throttleClick)="onCreate()">
                  <img alt="" width="22" src="assets/images/ic_proposal_new.svg">
                </button>
              </div>
            </nz-col>
          </nz-row>
        </ng-template>
        <ng-template #tableFooter>
          <div class="table-title">
            {{'TotalRows' | translate}} : <span
            style="color: #002140; font-weight: bold;">{{ listArr.total }}</span>
          </div>
        </ng-template>

        <thead>
        <tr>
          <th nzWidth="50px"></th>
          <th>{{'ComparisonNumber' | translate}}</th>
          <th>{{'ClientName' | translate}}</th>
          <th>{{'Status' | translate}}</th>
          <th>{{'Functions' | translate}}</th>
        </tr>
        </thead>
        <tbody>
          <ng-template ngFor let-contacts [ngForOf]="listArr.list">
            <tr appThrottleClick (throttleClick)="onDetail(contacts.id)">
              <td nzWidth="50px" [(nzExpand)]="contacts.expand" appThrottleClick
                  (throttleClick)="$event.stopPropagation();"></td>
              <td>{{contacts.id}}</td>
              <td>{{contacts.name}}</td>
              <td>
                <div [ngSwitch]="contacts.status">
                  <div>
                    <span *ngSwitchCase="'PENDING'" class="text-warning">●</span>
                    <span *ngSwitchCase="'COMPLETED'" class="text-success">●</span>
                    <span *ngSwitchCase="'FAIL'" class="text-danger">●</span>
                    <span> {{ contacts.status | metadataTranslate: 'proposalCompareStatus' }}</span>
                  </div>
                </div>
              <td>
                <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Detail' | translate"
                   appThrottleClick (throttleClick)="onDetail(contacts.id); $event.stopPropagation();">
                  <img alt="" width="20" src="assets/images/ic_details.svg">
                </a>
                <a *ngIf="contacts.status == 'COMPLETED'" nz-tooltip [nzTooltipTitle]="'Download' | translate" nz-dropdown
                   appThrottleClick (throttleClick)="$event.stopPropagation();"
                   [nzDropdownMenu]="menu" nzTrigger="click">
                  <img alt="" width="20" src="assets/images/ic_download.svg">
                </a>
                <nz-dropdown-menu #menu="nzDropdownMenu">
                  <ul nz-menu nzSelectable>
                    <li nz-menu-item appThrottleClick
                        (throttleClick)="onDownload(contacts.urlCn)">{{ 'CN' | translate}}</li>
                    <li nz-menu-item appThrottleClick
                        (throttleClick)="onDownload(contacts.urlHk)">{{ 'HK' | translate}}</li>
                    <li nz-menu-item appThrottleClick
                        (throttleClick)="onDownload(contacts.urlUs)">{{ 'EN' | translate}}</li>
                  </ul>
                </nz-dropdown-menu>
                <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Delete' | translate"
                   appThrottleClick
                   (throttleClick)="onDelete(contacts, tplTitle, tplContent, tplFooter); $event.stopPropagation();">
                  <img alt="" width="20" src="assets/images/ic_delete.svg">
                </a>
              </td>
            </tr>
            <tr [nzExpand]="contacts.expand" appThrottleClick (throttleClick)="$event.stopPropagation();">
              <nz-table #innerTable [nzData]="contacts.proposalList" nzSize="middle" [nzShowPagination]="false">
                <thead>
                  <tr>
                    <th>{{'ComparisonPlan' | translate}}</th>
                    <th>{{'ProposalID' | translate}}</th>
                    <th>{{'PaymentTerm' | translate}}</th>
                    <th>{{'Gender' | translate}}</th>
                    <th>{{'Age' | translate}}</th>
                    <th>{{'Smoker' | translate}}</th>
                    <th>{{'Withdrawal' | translate}}</th>
                    <th>{{'Premium' | translate}}(USD)</th>
                    <th>{{'CreateTime' | translate}}</th>
                    <th>{{'Functions' | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of contacts.proposalList; let index = index;">
                    <td>
                      <img width="20" style="border-radius: 3px; margin-right: 5px" [src]="data.companyLogo"
                           alt=""/>{{data.productName}}
                    </td>
                    <td>{{data.proposalId}}</td>
                    <td>{{data.paymentTerm}}</td>
                    <td>{{data.gender | metadataTranslate:'gender'}}</td>
                    <td>{{data.age}}</td>
                    <td>{{data.smoke | metadataTranslate:'smoke'}}</td>
                    <td>
                      {{data.isWithdrawal ? ('Yes' | translate) : ('No' | translate)}}</td>
                    <td>{{data.premium | number }}</td>
                    <td>{{data.applicationTime | date:'yyyy-MM-dd'}}</td>
                    <td>
                      <a *ngIf="workbench.downloadProposal" nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Preview' | translate"
                         appThrottleClick (throttleClick)="onPreview(data); $event.stopPropagation();">
                        <img alt="" width="20" src="assets/images/ic_view.svg">
                      </a>
                      <a *ngIf="workbench.downloadProposal" nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Download' | translate"
                         appThrottleClick (throttleClick)="onProposalDownload(data); $event.stopPropagation();">
                        <img alt="" width="20" src="assets/images/ic_download.svg">
                      </a>
                    </td>
                  </tr>
                </tbody>
                </nz-table>
              </tr>
            </ng-template>
        </tbody>
      </nz-table>
    </div>
  </nz-spin>
</div>

<!-- 搜索气泡Template -->
<ng-template #searchTemplate>
  <div style="width: 300px; padding: 5px;">
    <div style="padding: 0 0 15px 0">{{'Filter' | translate}}</div>
    <div class="property-bottom-line-blue"></div>
    <!--名字-->
    <app-property-input [title]="'ClientName' | translate"
                        [placeholder]="('PleaseEnter' | translate) + ('ClientName' | translate)"
                        [(value)]="search.name"></app-property-input>
    <!--保险种类-->
    <app-property-select [title]="'InsuranceType' | translate"
                         [placeholder]="('PleaseSelect' | translate) + ('InsuranceType' | translate)"
                         [options]="categoryOptions"
                         [(value)]="search.categoryCode">
    </app-property-select>
    <!--    状态-->
    <app-property-select [title]="'Status' | translate"
                         [placeholder]="('PleaseSelect' | translate) + ('Status' | translate)"
                         [options]="statusOptions"
                         [(value)]="search.status">
    </app-property-select>
    <nz-row [nzGutter]="5">
      <nz-col nzSpan="12">
        <button nz-button nzType="default" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick
                (throttleClick)="onReload()">
          <span>{{'Reset' | translate}}</span>
        </button>
      </nz-col>
      <nz-col nzSpan="12">
        <button nz-button nzType="primary" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick
                (throttleClick)="onSearch(1)">
          <span>{{'Confirm' | translate}}</span>
        </button>
      </nz-col>
    </nz-row>
  </div>
</ng-template>
<ng-template #tplTitle>
  <span>{{'Delete' | translate}}</span>
</ng-template>
<ng-template #tplContent let-params>
  <p>{{'DoYouWantToDeleteCompare' | translate}} :「{{ params.id }}」?</p>
</ng-template>
<ng-template #tplFooter let-ref="modalRef" let-params>
  <button nz-button appThrottleClick (throttleClick)="ref.destroy()"
          [disabled]="deleting">{{'Cancel' | translate}}</button>
  <button nz-button nzType="primary" appThrottleClick (throttleClick)="onDeleteConfirm(ref, params)"
          [nzLoading]="deleting">
    {{'Delete' | translate}}
  </button>
</ng-template>
