import { Expose, plainToInstance, Transform, Type } from 'class-transformer';
import { I18n, Material, PagedReq } from '../api/types';
import { BankInfo, DiscountInfo, Proposal } from '../proposal/proposal-types';
import { Product } from '../product/product-types';

export class PremiumFinancing {
  @Expose() id: number;
  @Expose() name: string;
  @Expose() interestRatio: number;
  @Expose() year: string;
  @Expose() productCode: string;
  @Expose() categoryCode: string;
  @Expose() proposalId: string;
  @Expose() status: string;
  @Expose() addTime: string;
  @Expose() initialSurrenderValue: number;
  @Expose() premium: number;
  @Expose() annualReturns: number;
  @Expose() premiumTotal: number;
  @Type(() => BankInfo)
  @Transform((value) => plainToInstance(BankInfo, Object.assign(new BankInfo(), value.value)))
  @Expose() bankInfo: BankInfo = new BankInfo();
  @Type(() => DiscountInfo)
  @Transform((value) => plainToInstance(DiscountInfo, Object.assign(new DiscountInfo(), value.value)))
  @Expose() discountInfo: DiscountInfo = new DiscountInfo();

  @Expose() urlHk: Material;
  @Expose() urlCn: Material;
  @Expose() urlUs: Material;

  @Expose() proposal: Proposal = new Proposal();
}

export class PremiumFinancingSearch extends PagedReq {
  @Expose() id: number;
  @Expose() name: string;
  @Expose() proposalId: string;
  @Expose() productCode: string;
  @Expose() categoryCode: string;
  @Expose() gender: string; // FEMALE-女性;MALE-男性
  @Expose() age: string;
  @Expose() fullOrPartial: string; // 全付或半付 PARTIAL :PARTIAL FULL :FULL
  @Expose() status: string;
  @Expose() product: Product = new Product(); // 產品
}

export class PremiumFinancingProposalSearch extends PagedReq {
  @Expose() id: number;
}

export class PremiumFinancingCreate {
  @Expose() name: string; // 客户姓名
  @Expose() proposalId: string; // 计划书编号
  @Expose() premium: number; // 每年保费
  @Expose() premiumTotal: number; // 总保费
  @Expose() initialSurrenderValue: number; // 首日退保价值
  @Expose() fullOrPartial: string; // 全付或半付
  @Expose() loanRatio: number; // 貸款比例（%）
  @Expose() annualInterestRate: number; // 年利率（%）
  @Expose() annualReturns: number; // 假定回报率(%)
  @Expose() asterisk: boolean; // 产品、公司名是否打星
  @Expose() insuranceCompany: string; // 产品、公司名是否打星 名字
  @Expose() theme: boolean; // 主题
  @Expose() proposal: Proposal = new Proposal();
}

export class PremiumFinancingDetail {
  @Expose() id: number;
  @Expose() accountName: string; // 客户姓名
  @Expose() name: string; // 客户姓名
  @Expose() companyName: string;
  @Expose() productName: string;
  @Transform((value) => plainToInstance(PremiumFinancingInfo, Object.assign(new PremiumFinancingInfo(), value.value)))
  @Expose() premiumFinancing: PremiumFinancingInfo = new PremiumFinancingInfo();
  @Expose() annualPremiumDiscountRate: number;
  @Expose() annualPremium: number;
  @Expose() prepaymentPremiums: number[];
  @Expose() prepaymentPremiumTotal: number;
  @Expose() firstYearDiscount: number;
  @Expose() firstYearAdditionalDiscount: number;
  @Expose() premiumCashback: number;
  @Expose() premiumPayable: number;
  @Expose() totalInvestmentIncludeHandlingFees: boolean;
  @Expose() totalDiscountAmount: number;
  @Expose() initialSurrenderValue: number;
  @Expose() loanAmount: number;
  @Expose() annualInterest: number;
  @Expose() selfPaymentAmount: number;
  @Expose() loadHandlingFee: number;
  @Expose() totalInvestment: number;
  @Expose() leverageRatio: number;
  @Expose() finalPremiumTotal: number; // 最终应付总保费
  @Expose() surrenderValues: any[]; // 退保价值
  @Expose() demonstrates: any[]; // 融资后价值演示
}


export class PremiumFinancingInfo {
  @Expose() id: number;
  @Expose() accountName: string;
  @Expose() theme: string;
  @Expose() status: string;
  @Expose() statusNote: string;
  @Expose() name: string; // 客户姓名
  @Expose() proposalId: string; // 计划书编号
  @Expose() premiumPayable: number;
  @Expose() totalInvestmentIncludeHandlingFees: boolean;
  @Expose() initialSurrenderValue: number; // 首日退保价值
  @Expose() productCode: string;
  @Expose() productNameI18n: I18n;
  @Expose() categoryCode: string;
  @Expose() companyName: string;
  @Expose() productName: string;
  @Expose() paymentTerm: string;
  @Expose() currency: string;
  @Expose() age: number;
  @Expose() gender: string;
  @Expose() smoke: string;
  @Expose() residenceRegion: string;
  @Expose() premium: number; // 每年保费
  @Expose() premiumTotal: number; // 总保费
  @Type(() => BankInfo)
  @Transform((value) => plainToInstance(BankInfo, Object.assign(new BankInfo(), value.value)))
  @Expose() bankInfo: BankInfo = new BankInfo();
  @Type(() => DiscountInfo)
  @Transform((value) => plainToInstance(DiscountInfo, Object.assign(new DiscountInfo(), value.value)))
  @Expose() discountInfo: DiscountInfo = new DiscountInfo();
  @Expose() urlHk: Material;
  @Expose() urlCn: Material;
  @Expose() urlUs: Material;
  @Expose() annualReturns: number; // 假定回报率(%)
  @Expose() loanRatio: number; // 貸款比例（%）
  @Expose() annualInterestRate: number; // 年利率（%）
  @Expose() asterisk: boolean; // 产品、公司名是否打星
  @Expose() insuranceCompany: string; // 产品、公司名是否打星 名字
  @Expose() remark: string;
  @Expose() addTime: string;
  @Expose() updateTime: string;
}
