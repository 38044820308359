import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {DataService} from '../shared/service/data.service';
import {ToastrService} from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import {WorkbenchService} from '../workbench/workbench.service';
import {Workbench} from '../workbench/workbench-types';
import {BookingService} from './booking.service';
import {BaseComponent} from '../base/base.component';
import {NzModalService} from 'ng-zorro-antd/modal';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {BookingToCompleteComponent} from './components/booking-to-complete/booking-to-complete.component';
import {BookingDetailComponent} from './booking-detail/booking-detail.component';
import {Booking} from './booking-types';
import {DataMode} from '../api/types';

@Component({
  selector: 'app-booking-main',
  templateUrl: './booking-main.component.html',
  styleUrls: ['./booking-main.component.less']
})
export class BookingMainComponent extends BaseComponent implements OnInit {
  selectIndex = 0;
  workbench: Workbench;
  spinning = false;

  constructor(public dataService: DataService,
              private viewContainerRef: ViewContainerRef,
              private workbenchService: WorkbenchService,
              private bookingService: BookingService,
              private modalService: NzModalService,
              private drawerService: NzDrawerService,
              private toastr: ToastrService,
              private router: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    this.router
      .queryParams
      .subscribe(params => {
        switch (params['mode']) {
          case DataMode.OWN:
            this.selectIndex = 0;
            break;
          case DataMode.SUBORDINATE:
            this.selectIndex = 1;
            break;
          case DataMode.FOLLOWUP:
            this.selectIndex = 2;
            break;
          case DataMode.SALESGROUP:
            this.selectIndex = 3;
            break;
        }
      });
    this.workbench = this.workbenchService.workbench;

    this.bookingService.toBeCompleted
      .subscribe(
        bookingNo => {
          this.onToComplete(bookingNo);
        }
      ).disposedBy(this.disposeBag);
  }

  onToComplete(bookingNo: string): void {
    const modalRef = this.modalService.create({
      nzCentered: true,
      nzContent: BookingToCompleteComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzComponentParams: {},
      nzOnOk: () => new Promise(resolve => setTimeout(resolve, 1000)),
      nzFooter: null
    });
    const component = modalRef.getContentComponent();
    modalRef.afterOpen.subscribe(() => {
      component.modalRef = modalRef;
    });
    // Return a result when closed
    modalRef.afterClose.subscribe(result => {
    });
    component.onGoComplete.subscribe(() => {
      this.onBookingDetail(bookingNo);
    });
  }

  onBookingDetail(bookingNo: string): void {
    this.spinning = true;
    this.bookingService.detail(bookingNo)
      .subscribe(
        bookingDetail => {

          this.spinning = false;
          const booking = bookingDetail.toBooking();

          const drawerRef = this.drawerService.create<BookingDetailComponent, { value: Booking }, string>({
            nzWidth: 800,
            nzContent: BookingDetailComponent,
            nzContentParams: {
              bookingDetail,
              booking,
              mode: DataMode.OWN
            }
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
            component.drawerRef = drawerRef;
          });

          drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
          });
        },
        error => {
          this.spinning = false;
        }).disposedBy(this.disposeBag);
  }
}
