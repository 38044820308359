import { Injectable } from '@angular/core';
import {BaseService} from '../base/base.service';
import {HttpService} from '../core/http.service';
import {Observable} from 'rxjs/internal/Observable';
import {ApiResponse} from '../api/types';
import { UnderwritingToDoRes, PolicyToDoRes, DialogToDoRes, UnderwritingListRes, PolicyListRes } from './to-do-types';
import {HttpParams} from '@angular/common/http';
import {API} from '../api/api';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ToDoService extends BaseService {

  constructor(private http: HttpService) {
    super();
  }

  /**
   * 新单所有未完成的跟进事项
   */
  underwritingPending(): Observable<UnderwritingToDoRes[]> {
    return this.http.get<ApiResponse<UnderwritingToDoRes[]>>(API.underwritingV3 + '/pending-list-all', {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }


  /**
   * 新单所有未完成的跟进事项V4
   */
  underwritingPendingV4(): Observable<UnderwritingListRes[]> {
    return this.http.get<ApiResponse<UnderwritingListRes[]>>(API.underwritingV4 + '/pending-list-all', {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }


  /**
   * 保单所有未完成的跟进事项
   */
  policyPending(): Observable<PolicyToDoRes[]> {
    return this.http.get<ApiResponse<PolicyToDoRes[]>>(API.policyV3 + '/service-list-all', {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 保单所有未完成的跟进事项V4
   */
  policyPendingV4(): Observable<PolicyListRes[]> {
    return this.http.get<ApiResponse<PolicyListRes[]>>(API.policyV4 + '/service-list-all', {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 获取留言代办
   */
  dialog(): Observable<DialogToDoRes[]> {
    return this.http.get<ApiResponse<DialogToDoRes[]>>(API.commonV3 + '/dialog/list', {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 获取留言代办
   */
  dialogV4(): Observable<DialogToDoRes[]> {
    return this.http.get<ApiResponse<DialogToDoRes[]>>(API.commonV4 + '/dialog/list', {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }
}
