<div class="drawer-container">
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div class="common-title" style="margin-bottom: 30px">
      {{'PremiumFinancingCreate' | translate}}
    </div>

    <div class="section-title" style="margin-top: 20px;display: flex">
      <span style="margin-left: 8px">{{'BasicInformation' | translate}}</span>
    </div>
    <app-property-input [title]="'PremiumFinancingTitle' | translate"
                        [required]="true"
                        [bottomLineVisible]="false"
                        [(value)]="premiumFinancing.name"
                        [displayMode]="'vertical'"
                        [placeholder]="('PleaseEnter'| translate) + ('PremiumFinancingTitle' | translate)">
    </app-property-input>
    <!--    <div style="width: 100%;padding: 10px 0;">-->
    <app-property-pick [title]="'Proposal' | translate"
                       appThrottleClick (throttleClick)="onProposalSelect()"
                       [bottomLineVisible]="false"
                       [required]="true"
                       [placeholder]="('PleaseSelect' | translate) + ('Proposal' | translate)"
                       [displayMode]="'vertical'"
                       [value]="proposalLabel"></app-property-pick>
    <!--公司及產品名稱加星號-->
    <!--    </div>-->
    <!--    <div class="section-title" style="margin-top: 20px;display: flex">-->
    <!--      <span style="margin-left: 8px">{{'Proposal' | translate}}</span>-->
    <!--      <a style="margin-left: auto" nz-button [nzSize]="'small'" appThrottleClick (throttleClick)="onSelect();"-->
    <!--         nzType="link">{{'SelectProposal'| translate}}</a>-->
    <!--    </div>-->
    <!--    <div *ngIf="proposal.proposalId" class="section-wrapper" style="padding: 10px 10px 10px 10px;">-->
    <!--      {{ proposal.proposalId }}-->
    <!--    </div>-->
    <app-property-text [displayMode]="'vertical'"
                       [bottomLineVisible]="false"
                       [title]="'Currency' | translate"
                       [value]="premiumFinancing.proposal.currency || 'N/A'"></app-property-text>

    <app-property-text [displayMode]="'vertical'"
                       [bottomLineVisible]="false"
                       [title]="'PaymentTerm' | translate"
                       [value]="premiumFinancing.proposal.paymentTerm || 'N/A'"></app-property-text>

    <app-property-input-number [displayMode]="'vertical'"
                               [bottomLineVisible]="false"
                               [title]="'AnnualPremium' | translate"
                               [placeholder]="('PleaseEnter' | translate) + ('AnnualPremium' | translate)"
                               [required]="true"
                               [(value)]="premiumFinancing.premium"></app-property-input-number>

    <app-property-input-number [displayMode]="'vertical'"
                               [bottomLineVisible]="false"
                               [title]="'TotalPremium' | translate"
                               [placeholder]="('PleaseEnter' | translate) + ('TotalPremium' | translate)"
                               [required]="true"
                               [(value)]="premiumFinancing.premiumTotal"></app-property-input-number>

    <app-property-input-number [displayMode]="'vertical'"
                               [title]="'InitialSurrenderValue' | translate"
                               [placeholder]="('PleaseEnter' | translate) + ('InitialSurrenderValue' | translate)"
                               [required]="true"
                               [bottomLineVisible]="false"
                               [(value)]="premiumFinancing.initialSurrenderValue"></app-property-input-number>

    <div class="section-title" style="margin-top: 20px;display: flex">
      <span style="margin-left: 8px">{{'BackInfo' | translate}}</span>
      <a style="margin-left: auto" nz-button [nzSize]="'small'" appThrottleClick (throttleClick)="onBankSelect();"
         nzType="link">{{'Import'| translate}}</a>
    </div>
    <app-property-input [title]="'BankName' | translate"
                        [bottomLineVisible]="false"
                        [(value)]="premiumFinancing.bankInfo.name"
                        [displayMode]="'vertical'"
                        [placeholder]="('PleaseEnter'| translate) + ('BankName' | translate)"></app-property-input>

    <app-property-input-number [title]="('LoanRatio' | translate) + ' (%)'"
                               [bottomLineVisible]="false"
                               [required]="true"
                               [(value)]="premiumFinancing.bankInfo.loanRatio"
                               [displayMode]="'vertical'"
                               [placeholder]="('PleaseEnter'| translate) + ('LoanRatio' | translate)"></app-property-input-number>

    <app-property-input-number [title]="'LoanYear' | translate"
                               [bottomLineVisible]="false"
                               [required]="true"
                               [(value)]="premiumFinancing.bankInfo.year"
                               [displayMode]="'vertical'"
                               [placeholder]="('PleaseEnter'| translate) + ('LoanYear' | translate)"></app-property-input-number>

    <app-property-input-number [title]="('AnnualInterestRate' | translate) + ' (%)'"
                               [required]="true"
                               [(value)]="premiumFinancing.bankInfo.annualInterestRate"
                               [displayMode]="'vertical'"
                               [bottomLineVisible]="false"
                               [placeholder]="('PleaseEnter'| translate) + ('AnnualInterestRate' | translate)"></app-property-input-number>

    <app-property-input-number [title]="('PrepaymentInterestRate' | translate) + ' (%)'"
                               [(value)]="premiumFinancing.bankInfo.prepaymentInterestRate"
                               [displayMode]="'vertical'"
                               [bottomLineVisible]="false"
                               [placeholder]="('PleaseEnter'| translate) + ('PrepaymentInterestRate' | translate)"></app-property-input-number>

    <app-property-input-number [title]="('PolicyLoanHandlingFeeRate' | translate) + ' (%)'"
                               [(value)]="premiumFinancing.bankInfo.policyLoanHandlingFeeRate"
                               [displayMode]="'vertical'"
                               [bottomLineVisible]="false"
                               [placeholder]="('PleaseEnter'| translate) + ('PolicyLoanHandlingFeeRate' | translate)"></app-property-input-number>

    <div class="section-title" style="margin-top: 20px;display: flex">
      <span style="margin-left: 8px">{{'DiscountInfo' | translate}}</span>
      <a style="margin-left: auto" nz-button [nzSize]="'small'" appThrottleClick (throttleClick)="onDiscountSelect();"
         nzType="link">{{'Import'| translate}}</a>
    </div>

    <app-property-input-number [title]="('FirstYearPremiumDiscount' | translate) + ' (%)'"
                               [bottomLineVisible]="false"
                               [(value)]="premiumFinancing.discountInfo.firstYearPremiumDiscount"
                               [displayMode]="'vertical'"
                               [placeholder]="('PleaseEnter'| translate) + ('FirstYearPremiumDiscount' | translate)"></app-property-input-number>

    <app-property-input-number [title]="('AnnualPremiumDiscount' | translate) + ' (%)'"
                               [bottomLineVisible]="false"
                               [(value)]="premiumFinancing.discountInfo.annualPremiumDiscount"
                               [displayMode]="'vertical'"
                               [placeholder]="('PleaseEnter'| translate) + ('AnnualPremiumDiscount' | translate)"></app-property-input-number>

    <app-property-input-number [title]="('PremiumCashback' | translate) + ' (%)'"
                               [(value)]="premiumFinancing.discountInfo.premiumCashback"
                               [displayMode]="'vertical'"
                               [bottomLineVisible]="false"
                               [placeholder]="('PleaseEnter'| translate) + ('PremiumCashback' | translate)"></app-property-input-number>

  </nz-spin>
  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="12" class="footer-button">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onReset()">
          <em nz-icon nzType="reload" nzTheme="outline"></em>
          <span>{{'Reset' | translate}}</span>
        </a>
      </div>
      <div nz-col nzSpan="12" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           [disabled]="!canGenerate"
           [style]="!canGenerate ? 'opacity: 0.5' : ''"
           appThrottleClick (throttleClick)="onGenerate()">
          <em nz-icon nzType="save" nzTheme="outline"></em>
          <span>{{'Generate' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

</div>
