<nz-tabset [nzSize]="'large'" [(nzSelectedIndex)]="selectIndex">
  <nz-tab [nzTitle]=myDeals>
    <app-policys [mode]="dataService.dataModeOwn"></app-policys>
  </nz-tab>
  <ng-template #myDeals>
    {{'MyDeals' | translate}}
  </ng-template>
  <nz-tab [nzTitle]=channelDeals *ngIf="workbench.pivot !== dataService.pivotOwn">
    <app-policys [mode]="dataService.dataModeSubordinate"></app-policys>
  </nz-tab>
  <ng-template #channelDeals>
    {{'MySubordinates' | translate}}
  </ng-template>
  <nz-tab [nzTitle]=myToDo *ngIf="workbench.tr">
    <app-policys [mode]="dataService.dataModeFollowUp"></app-policys>
  </nz-tab>
  <ng-template #myToDo>
    {{'MyToDo' | translate}}
  </ng-template>
  <nz-tab [nzTitle]=myTeam>
    <app-policys [mode]="dataService.dataModeSalesGroup"></app-policys>
  </nz-tab>
  <ng-template #myTeam>
    {{'MySalesGroup' | translate}}
  </ng-template>
</nz-tabset>
