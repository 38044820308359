<div class="drawer-container">
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div class="common-title" style="margin-bottom: 30px">
      {{'PremiumFinancingCreate' | translate}}
    </div>

    <div class="section-title pre-lien" style="margin-top: 20px;display: flex">
      <span style="margin-left: 8px">{{'BasicInformation' | translate}}</span>
    </div>

    <div class="solid-box">
      <nz-row [nzGutter]="10">
        <nz-col [nzSpan]="24">
          <app-property-pick [title]="'Proposal' | translate"
                             appThrottleClick (throttleClick)="onProposalSelect()"
                             [required]="true"
                             [placeholder]="('PleaseSelect' | translate) + ('Proposal' | translate)"
                             [displayMode]="'vertical'"
                             [value]="proposalLabel"></app-property-pick>
        </nz-col>
        <nz-col [nzSpan]="12">
          <app-property-input [title]="'Name' | translate"
                              [required]="true"
                              [(value)]="premiumFinancing.name"
                              [displayMode]="'vertical'"
                              [placeholder]="('PleaseEnter'| translate) + ('Name' | translate)">
          </app-property-input>
        </nz-col>

        <nz-col [nzSpan]="12">
          <app-property-text [displayMode]="'vertical'"
                             [title]="'Currency' | translate"
                             [value]="premiumFinancing.proposal.currency || 'N/A'"></app-property-text>
        </nz-col>
        <nz-col [nzSpan]="12">
          <app-property-text [displayMode]="'vertical'"
                             [title]="'PaymentTerm' | translate"
                             [value]="premiumFinancing.proposal.paymentTerm || 'N/A'"></app-property-text>
        </nz-col>
        <nz-col [nzSpan]="12">
          <app-property-input-number [displayMode]="'vertical'"
                                     [title]="'AnnualPremium' | translate"
                                     [placeholder]="('SelectingPlan' | translate)"
                                     [required]="true"
                                     [(value)]="premiumFinancing.premium"></app-property-input-number>
        </nz-col>
        <nz-col [nzSpan]="12">
          <app-property-input-number [displayMode]="'vertical'"
                                     [title]="'TotalPremium' | translate"
                                     [placeholder]="('SelectingPlan' | translate)"
                                     [required]="true"
                                     [(value)]="premiumFinancing.premiumTotal"></app-property-input-number>
        </nz-col>
        <nz-col [nzSpan]="12">
          <app-property-input-number [displayMode]="'vertical'"
                                     [title]="'InitialSurrenderValue' | translate"
                                     [placeholder]="('SelectingPlan' | translate)"
                                     [required]="true"
                                     [(value)]="premiumFinancing.initialSurrenderValue"></app-property-input-number>
        </nz-col>
      </nz-row>
    </div>

    <div class="section-title pre-lien" style="margin-top: 20px;display: flex">
      <span style="margin-left: 8px">{{'FinancingInfo' | translate}}</span>
    </div>

    <div class="solid-box">
      <nz-row [nzGutter]="10">
        <nz-col [nzSpan]="12">
          <app-property-select [title]="'PFWCFull' | translate"
                               [displayMode]="'vertical'"
                               [required]="true"
                               [placeholder]="('PleaseSelect' | translate) + ('PFWCFull' | translate)"
                               [options]="fullOptions"
                               [(value)]="premiumFinancing.fullOrPartial"></app-property-select>
        </nz-col>
        <nz-col [nzSpan]="12">
          <app-property-input-number [displayMode]="'vertical'"
                                     [title]="('AnnualInterestRate' | translate) + ' (%)'"
                                     [required]="true"
                                     [placeholder]="('PleaseEnter' | translate) + ('AnnualInterestRate' | translate)"
                                     [(value)]="premiumFinancing.annualInterestRate"></app-property-input-number>
        </nz-col>
        <nz-col [nzSpan]="12">
          <app-property-input-number [displayMode]="'vertical'"
                                     [title]="('LoanRatio' | translate) + ' (%)'"
                                     [required]="true"
                                     [textColor]="'#cccccc'"
                                     [placeholder]="('PleaseEnter' | translate) + ('LoanRatio' | translate)"
                                     [(value)]="premiumFinancing.loanRatio"></app-property-input-number>
        </nz-col>
      </nz-row>
    </div>

    <div class="section-title pre-lien" style="margin-top: 20px;display: flex">
      <span style="margin-left: 8px">{{'Other' | translate}}</span>
    </div>

    <div class="solid-box">
      <nz-row [nzGutter]="10">
        <nz-col [nzSpan]="12">
          <app-property-input-number [displayMode]="'vertical'"
                                     [title]="('AnnualReturns' | translate) + ' (%)'"
                                     [required]="true"
                                     [placeholder]="('PleaseEnter' | translate) + ('AnnualReturns' | translate)"
                                     [(value)]="premiumFinancing.annualReturns"></app-property-input-number>
        </nz-col>
        <nz-col [nzSpan]="12" style="display: flex;align-items: end;">
          <app-property-switch  [title]="'MaskInsurerAsterisk' | translate"
                                [bottomLineVisible]="false"
                                style="width: 100%;"
                                [(value)]="premiumFinancing.asterisk"></app-property-switch>
        </nz-col>
        <nz-col [nzSpan]="12">
          <app-property-input *ngIf="!premiumFinancing.asterisk" [title]="'InsurerCode' | translate"
                              [(value)]="premiumFinancing.insuranceCompany"
                              [displayMode]="'vertical'"
                              [textColor]="'#cccccc'"
                              [placeholder]="('PleaseEnter'| translate) + ('InsurerCode' | translate)">
          </app-property-input>
        </nz-col>
      </nz-row>
    </div>

  </nz-spin>
  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="12" class="footer-button">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onReset()">
          <em nz-icon nzType="reload" nzTheme="outline"></em>
          <span>{{'Reset' | translate}}</span>
        </a>
      </div>
      <div nz-col nzSpan="12" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           [disabled]="!canGenerate"
           [style]="!canGenerate ? 'opacity: 0.5' : ''"
           appThrottleClick (throttleClick)="onGenerate()">
          <em nz-icon nzType="save" nzTheme="outline"></em>
          <span>{{'Generate' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

</div>
