import {Expose} from 'class-transformer';
import { Material } from '../api/types';

export class UnderwritingListRes {
  @Expose() underwritingNo: string;
  @Expose() policyNo: string;
  @Expose() applyTime: number;
  @Expose() count: number;
  @Expose() expand: boolean;
  @Expose() underwritingPendingDTOS: UnderwritingToDoRes[];
}

export class UnderwritingToDoRes {
  @Expose() id: number; // 主键
  @Expose() pendingNo: string; // Pending编号
  @Expose() status: string; // Pending状态
  @Expose() project: string; // Pending主题
  @Expose() remark: string; // 备注
  @Expose() applyTime: number; // 创建时间
  @Expose() organizer: string; // 发起人
  @Expose() deadlines: number; // 截止时间
  @Expose() messageCount: number; // 留言总数
  @Expose() unReadMessage: number; // 未读留言数
  @Expose() finish: boolean; // 是否完成Pending标志:false-未完成;true-完成
  @Expose() underwritingNo: string; // 新单编号
  @Expose() policyNo: string;
  @Expose() clientName: string; // 客户姓名
  @Expose() deliveryDate: string; // 送件日期
  @Expose() attachments: Material[]; // 附件
  @Expose() show: boolean; // 销售渠道是否显示pending
}

export class PolicyListRes {
  @Expose() policyId: string;
  @Expose() policyNo: string;
  @Expose() expand: boolean;
  @Expose() count: number;
  @Expose() applyTime: number;
  @Expose() appPolicyServiceListVOS: PolicyToDoRes[];
}

export class PolicyToDoRes {
  @Expose() id: number; // 主键
  @Expose() serviceNo: string; // Service编号
  @Expose() status: string; // Service状态
  @Expose() project: string; // Service主题
  @Expose() applyTime: number; // 创建时间
  @Expose() deliveryDate: number;
  @Expose() messageCount: number; // 留言总数
  @Expose() unReadMessage: number; // 未读留言数
  @Expose() finish: boolean; // 是否完成Pending标志:false-未完成;true-完成
  @Expose() policyId: string; // 保单编号
  @Expose() policyNo: string; // 保單号
  @Expose() clientName: string;
  @Expose() remark: string;
  @Expose() show: boolean; // 销售渠道是否显示pending
}

export class DialogToDoRes {
  @Expose() id: number; // 主键
  @Expose() module: string;
  @Expose() clientName: string;
  @Expose() bookingNo: string; // 预约编号
  @Expose() underwritingNo: string; // 新单编号
  @Expose() policyId: string; // 保单ID
  @Expose() policyNo: string;
  @Expose() proposalId: string; // 计划书编号
  @Expose() content: string; // 内容
  @Expose() time: string; // 回复时间
  @Expose() dialogCount: number; // 代办数量
}
