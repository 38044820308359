<div style="padding: 20px 20px 0">
  <div class="common-title" style="margin-bottom: 30px">
    {{'从预约中导入'}}
  </div>

  <nz-spin [nzSpinning]="spinning">
    <div *ngIf="policys" style="margin-top: 30px;">
      <nz-table [nzBordered]="false"
                [nzOuterBordered]="true"
                [nzShowPagination]="true"
                [nzLoading]="loading"
                [nzFrontPagination]="false"
                [nzData]="policys.list"
                [nzPageIndex]="policys.pageNum"
                [nzPageSize]="policys.pageSize"
                [nzTotal]="policys.total"
                [nzTitle]="tableHeader"
                [nzFooter]="tableFooter"
                (nzPageIndexChange)="onSearch($event)"
                [nzScroll]="{ x: '1415'}">
        <ng-template #tableHeader>
          <nz-row>
            <nz-col [nzSpan]="12">
              <div class="list-header-title">{{'Policy' | translate}}
                <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Refresh' | translate"
                   style="margin-left: 5px"
                   appThrottleClick (throttleClick)="onReload()">
                  <em nz-icon style="color: black" nzType="reload" nzTheme="outline"></em>
                </a>
              </div>
            </nz-col>
            <nz-col [nzSpan]="12">
              <div style="width: 100%; display: flex; justify-content: flex-end;">
                <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                        [nzTooltipTitle]="'PolicySearch' | translate" style=" margin-left: 10px;"
                        appThrottleClick (throttleClick)="onPolicySearch()"
                >
                  <img alt="" width="22" src="assets/images/ic_search.svg">
                </button>

              </div>
            </nz-col>
          </nz-row>
        </ng-template>
        <ng-template #tableFooter>
          <div class="table-title">
            {{'TotalRows' | translate}} : <span
            style="color: #002140; font-weight: bold;">{{ policys.total }}</span>
          </div>
        </ng-template>

        <thead>
        <tr>
          <th nzLeft nzWidth="165px">
          <span nz-tooltip
                [nzTooltipTitle]="'PolicyNo' | translate">{{'PolicyNo' | translate}}</span>
          </th>
          <th *ngIf="isShowBusiness" nzWidth="160px">
            <span nz-tooltip [nzTooltipTitle]="'BusinessNumber' | translate">{{'BusinessNumber' | translate}}</span>
          </th>
          <th nzWidth="160px">
            <span nz-tooltip [nzTooltipTitle]="'Referee' | translate">{{'Referee' | translate}}</span>
          </th>
          <th nzWidth="220px">
            <span nz-tooltip [nzTooltipTitle]="'ProductName' | translate">{{'ProductName' | translate}}</span>
          </th>

          <th nzWidth="220px">
            <span nz-tooltip [nzTooltipTitle]="'Insured' | translate">{{'Insured' | translate}}</span>
          </th>
          <th *ngIf="isShowBusiness" nzWidth="160px">
            <span nz-tooltip [nzTooltipTitle]="'Dda' | translate">{{'Dda' | translate}}</span>
          </th>
          <th nzWidth="130px"><span nz-tooltip [nzTooltipTitle]="'Status' | translate">{{'Status' | translate}}</span>
          </th>
          <th nzWidth="130px"><span nz-tooltip [nzTooltipTitle]="'Premium' | translate">{{'Premium' | translate}}</span>
          </th>
          <th nzWidth="220px">
            <span nz-tooltip [nzTooltipTitle]="'PolicyDate' | translate">{{'PolicyDate' | translate}}</span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let policy of policys.list; let i = index"
            appThrottleClick (throttleClick)="onPolicyDetail(policy.policyId)">
          <td nzLeft>
          <span style="font-weight: bold; font-size: 12px">
            {{ policy.policyNo }}
          </span>
          </td>
          <td *ngIf="isShowBusiness"><span>{{ policy.newBusinessNumber || 'N/A' }}</span></td>
          <td>
            <div>
              <span>{{ dynamicEncryption('salesName', policy.salesName) }}</span>
              <span>{{ dynamicEncryption('accountName', policy.accountName) }}</span>
            </div>
            <div *ngIf="workbench.secondAccountName">
              <span>{{ dynamicEncryption('salesName', policy.secondSalesName) }}</span>
              <span>{{ dynamicEncryption('accountName', policy.secondAccountName) }}</span>
            </div>
          </td>
          <td>
            <div style="display: flex; align-items: center;">
              <img width="20" style="border-radius: 3px; margin-right: 5px" [src]="policy.companyLogo" alt=""/>
              <span>{{ policy.productName || 'N/A' }} <span
                *ngIf="policy.productYear">&nbsp;({{ policy.custom ? (policy.productYear | yearConversion) :(policy.productYear | yearsOrMonthOrAge)}})</span></span>
            </div>
          </td>
          <td>
            <div>{{ (dynamicEncryption('insuredName', policy.insuredName)) }}</div>
            <div>{{ (dynamicEncryption('insuredName', policy.insuredPinyin)) }}</div>
          </td>
          <td *ngIf="isShowBusiness">
            <p *ngIf="policy.autoTransfer"> {{ 'Yes'| translate }}</p>
            <p *ngIf="!policy.autoTransfer"> {{ 'No'| translate }}</p>
          </td>
          <td>
            <div [ngSwitch]="policy.status">
              <div>
                <span *ngSwitchCase="'SURRENDERED'" class="text-cancel">●</span>
                <span *ngSwitchCase="'DECEASED'" class="text-cancel">●</span>
                <span *ngSwitchCase="'DECLINED'" class="text-cancel">●</span>
                <span *ngSwitchCase="'CLOSED'" class="text-cancel">●</span>
                <span *ngSwitchCase="'CANCELLED'" class="text-cancel">●</span>
                <span *ngSwitchCase="'TERMINATED'" class="text-cancel">●</span>
                <span *ngSwitchCase="'EXPIRATION'" class="text-cancel">●</span>
                <span *ngSwitchCase="'TRANSFER_IN_FAIL'" class="text-cancel">●</span>
                <span *ngSwitchCase="'CLAIMS_COMPLETED_LAPSED'" class="text-cancel">●</span>
                <span *ngSwitchCase="'CLAIMS_COMPLETED_EFFECTIVE'" class="text-success">●</span>
                <span *ngSwitchCase="'INFORCE'" class="text-success">●</span>
                <span *ngSwitchCase="'RIDER_INFORCE'" class="text-success">●</span>
                <span *ngSwitchCase="'WAIVER_INFORCE'" class="text-success">●</span>
                <span *ngSwitchCase="'UNDERWRITING'" class="text-warning">●</span>
                <span *ngSwitchCase="'LAPSED'" class="text-warning">●</span>
                <span *ngSwitchCase="'MATURED'" class="text-warning">●</span>
                <span *ngSwitchCase="'TRANSFERRED'" class="text-warning">●</span>
                <span *ngSwitchCase="'POSTPONED'" class="text-warning">●</span>
                <span *ngSwitchCase="'COOLOFF_SURRENDERING'" class="text-warning">●</span>
                <span *ngSwitchCase="'COOLOFF_SURRENDERED'" class="text-warning">●</span>
                <span *ngSwitchCase="'SURRENDERING'" class="text-warning">●</span>
                <span *ngSwitchCase="'TRANSFER_IN'" class="text-warning">●</span>
                <span *ngSwitchCase="'REDUCED_PAID_UP'" class="text-warning">●</span>
                <span *ngSwitchCase="'MAKING_CLAIMS'" class="text-warning">●</span>
                <span *ngSwitchCase="'POLICY_LOANS'" class="text-warning">●</span>
                <span *ngSwitchCase="'PREMIUM_HOLIDAY'" class="text-warning">●</span>
                <span *ngSwitchCase="'DORMANT'" class="text-warning">●</span>
                <span *ngSwitchCase="'NOT_AFFECTED'" class="text-warning">●</span>
                <span *ngSwitchCase="'PENDING_PREMIUM'" class="text-warning">●</span>
                <span *ngSwitchCase="'RENEWAL_PREMIUM'" class="text-warning">●</span>
                <span *ngSwitchCase="'TRANSFERRING'" class="text-warning">●</span>
                <span *ngSwitchCase="'RIDER_LAPSED'" class="text-warning">●</span>
                <!--                <span *ngSwitchDefault class="text-warning">●</span>-->
                <span> {{ policy.status | metadataTranslate: 'policyStatus' }}</span>
              </div>
            </div>
          </td>
          <td>
            <div>{{ policy.premium | number }}{{policy.currency | metadataTranslate: 'currency'}}</div>
          </td>
          <td>
            {{ (policy.policyDate | date:'yyyy-MM-dd HH:mm') || 'N/A' }}
          </td>
        </tr>
        </tbody>
      </nz-table>
  </div>
  </nz-spin>

  <nz-modal
    [(nzVisible)]="importVisible"
    [nzTitle]="importModalTit"
    [nzContent]="importModalContent"
    [nzFooter]="null"
    nzCentered
    [nzWidth]="'400px'"
    [nzClosable]="false"
    [nzMaskClosable]="false"
    (nzOnCancel)="importVisible = false"
  >
    <ng-template #importModalTit style="text-align: center">{{'Import' | translate}}</ng-template>
    <ng-template #importModalContent>
      <div style="display: flex; justify-content: center; padding-bottom: 20px;">
        <nz-radio-group [(ngModel)]="importInfoValue">
          <label nz-radio [nzValue]="'holder'"><span>{{'OwnerImport' | translate}}</span></label>
          <label nz-radio [nzValue]="'insured'"><span>{{'InsuredImport' | translate}}</span></label>
        </nz-radio-group>
      </div>
      <div nz-row style="width: 100%; height: 100%; justify-content: space-around;">
        <button nz-col nzSpan="10" nz-button nzType="default"
                appThrottleClick (throttleClick)="importVisible = false"
        >{{'Cancel' | translate}}</button>
        <button nz-col nzSpan="10" nz-button nzType="primary"
                appThrottleClick (throttleClick)="openImportDrawer()"
        >{{'Confirm' | translate}}</button>
      </div>
    </ng-template>
  </nz-modal>

</div>
