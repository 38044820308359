import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Material, MaterialGroupType, MaterialType, UploadToken, UploadTokenReq, UploadType } from '../../../api/types';
import { FileItem, FileUploader, Headers, ParsedResponseHeaders } from 'ng2-file-upload';
import { Icons } from '../../utils/icons';
import { StorageService } from '../../service/storage.service';
import { Observable } from 'rxjs';
import { Subscriber } from 'rxjs/internal-compatibility';
import * as CryptoJS from 'crypto-js';
import { DownloadService } from '../../service/download.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import * as _ from 'lodash';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzImageService } from 'ng-zorro-antd/image';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { FileLikeObject } from 'ng2-file-upload/file-upload/file-like-object.class';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { TranslateService } from '@ngx-translate/core';
import { lowerFirst } from 'lodash';
import { CommonService } from '../../service/common.service';
import { MetadataService } from '../../service/metadata.service';
import { ToastrService } from 'ngx-toastr';

export class AttachmentEntity {
  index: number;
  attachment: Material;
  type: string;
}

export class AttachmentFileAlert {
  showAlert = false;
  maxFileSize = 100 * 1024 * 1024;
  fileSize = 0;

  get maxFileSizeStr(): string {
    return (this.maxFileSize / (1024 * 1024)).toFixed(2) + 'M';
  }

  get fileSizeStr(): string {
    return (this.fileSize / (1024 * 1024)).toFixed(2) + 'M';
  }
}

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.less']
})
export class AttachmentsComponent implements OnInit {

  @Input()
  attachments: Material[] = [];

  @Input()
  uploadTokenReq: UploadTokenReq;
  @Input()
  apiType: string;

  @Input()
  readonly = false;

  @Input()
  showEmpty = false;

  @Input()
  fileFormat: string; // 默认文件格式
  @Input()
  isGroup: boolean; // 文件是否分组展示
  @Input()
  isPreview: boolean; // 能上传查看下载 不能修改删除
  @Input()
  isFileSearch: boolean; // 是否文件搜索
  @Input()
  isFileTime = true; // 是否显示时间

  @Output()
  valueChange: EventEmitter<Material[]> = new EventEmitter<Material[]>();
  @Output()
  billEdit: EventEmitter<Material> = new EventEmitter<Material>();
  @Output()
  billDelete: EventEmitter<Material> = new EventEmitter<Material>();
  @Output()
  completeAll: EventEmitter<any> = new EventEmitter<any>();

  uploader: FileUploader;

  @ViewChild('fileUpload')
  fileUpload: ElementRef;

  materialMap: Map<string, Material> = new Map();

  deleting = false;
  spinning = false;
  searchParam: string;

  tagArr: any[] = [];
  tagFlag: any[] = [];
  originalData: Material[] = [];
  fileTypeArr: MaterialType[] = [];

  fileAlert = new AttachmentFileAlert();

  setFileType = new Set<string>();
  setFileTypeCopy: MaterialGroupType[] = [];

  constructor(private storageService: StorageService,
              private downloadService: DownloadService,
              private translate: TranslateService,
              private drawerService: NzDrawerService,
              private nzImageService: NzImageService,
              private modalService: NzModalService,
              private notificationService: NzNotificationService,
              private commonService: CommonService,
              private toastr: ToastrService,
              private metadataService: MetadataService) {
  }

  ngOnInit(): void {
    this.uploader = new FileUploader({
      // headers: headers,
      // allowedMimeType: ["application/vnd.ms-excel"],
      disableMultipart: true,
      autoUpload: false,
      removeAfterUpload: false,
      maxFileSize: this.fileAlert.maxFileSize
    });

    this.uploader.onAfterAddingFile = this.onAfterAddingFile.bind(this);
    this.uploader.onSuccessItem = this.onUploadSuccess.bind(this);
    this.uploader.onErrorItem = this.onUploadError.bind(this);
    this.uploader.onWhenAddingFileFailed = this.onWhenAddingFileFailed.bind(this);
    this.uploader.onCompleteAll = this.onCompleteAll.bind(this);

    this.attachments?.map(item => {
      if (item.tags) {
        this.tagArr.push(...item.tags);
      }
    });
    this.tagArr = [...new Set(this.tagArr)];
    this.tagArr.sort((a, b) => {
      return a === '公司簡介' ? -1 : 1;
    });
    this.tagArr.forEach(() => {
      this.tagFlag.push(false);
    });
    this.originalData = this.attachments ? JSON.parse(JSON.stringify(this.attachments)) : [];
    this.getFileType();
    this.getFileTypeFilter();
    this.metadataService.groupFiles.subscribe(
      data => {
        setTimeout(() => {
          this.getFileTypeFilter();
          this.spinning = false;
        }, 500);
      });
  }

  iconOfFileName(fileName: string): string {
    // return Icons.iconOfFileName(fileName);
    if (fileName) {
      return Icons.iconOfFileName(fileName);
    } else {
      fileName = '.';
      return Icons.iconOfFileName(fileName);
    }
  }

  selectedImportFileOnChanged(event): void {
  }

  // 过滤查看按钮
  previewFilter(fileName: string): boolean {
    if (!fileName) {
      return false;
    }
    let isShow;
    const ext = fileName.substr(fileName.lastIndexOf('.') + 1)?.toLowerCase();
    switch (ext) {
      case 'png':
      case 'jpeg':
      case 'jpg':
        isShow = true;
        break;
      case 'pdf':
        isShow = true;
        break;
      default:
        isShow = false;
        break;
    }
    return isShow;
  }

  onWhenAddingFileFailed(item: FileLikeObject, filter: any, options: any): any {
    if (filter.name === 'fileSize') {
      this.fileAlert.showAlert = true;
      this.fileAlert.fileSize = item.size;
      this.translate.get('ExceedsTheLimitsOfFileSize',
        {maxLength: this.fileAlert.maxFileSizeStr, length: this.fileAlert.fileSizeStr})
        .subscribe(
          text => {
            this.notificationService.create(
              'warning',
              '',
              text
            );
          }
        );
    }
  }

  onCompleteAll(): any {
    // console.log('完成上传所有文件的回调');
    this.completeAll.emit(true);
    if (this.isGroup) {
      this.spinning = false;
    }
  }

  onAddAttachment(): void {
    if (this.fileUpload) {
      this.fileUpload.nativeElement.click();
    }
  }

  uploadFileItem(fileItem: FileItem): void {
    const req: UploadTokenReq = {
      uploadType: this.uploadTokenReq.uploadType,
      middleName: this.uploadTokenReq.middleName,
      fileName: fileItem.file.name
    };

    console.log(fileItem);
    this.computeMD5(fileItem)
      .subscribe(
        md5 => {
          let observable: Observable<UploadToken>;

          observable = this.apiType === 'TICKET_LIFEBEE' ? this.storageService.centreUploadToken(req) : this.storageService.uploadToken(req);
          observable.subscribe(
              data => {
                console.log(data);
                fileItem.method = 'PUT';
                fileItem.url = data.signedUrl;
                const headers: Headers[] = [];
                headers.push({name: 'Content-Type', value: data.contentType});
                headers.push({name: 'x-amz-meta-content-md5', value: md5});
                if (this.apiType === 'TICKET_LIFEBEE') {
                  headers.push({name: 'x-oss-object-acl', value: 'private'});
                }
                fileItem.headers = headers;
                fileItem.upload();
                const material = new Material();
                material.fileName = fileItem.file.name;
                material.filePath = data.filePath;
                material.uploadTime = new Date().valueOf();
                material.author = this.storageService.passport.accountName;
                // console.log(fileItem);
                this.materialMap.set(fileItem.file.name, material);
              },
              error => {
              });
        }
      );

  }

  onAfterAddingFile(fileItem: FileItem): any {
    fileItem.withCredentials = false;
    if (this.fileUpload) {
      // 重置文件选择，否则无法重复上传同一个文件
      this.fileUpload.nativeElement.value = '';
    }
    this.uploadFileItem(fileItem);
  }

  onUploadSuccess(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {

    this.uploader.removeFromQueue(item);
    if (!this.attachments) {
      this.attachments = [];
    }
    const material = this.materialMap.get(item.file.name);
    if (material) {
      this.attachments?.unshift(material);
      this.valueChange.emit(this.attachments);
      this.materialMap.delete(item.file.name);
    }

    // this.uploader.clearQueue();
    if (status === 200) {
      this.translate.get('UploadSuccess').subscribe(
        (val: string) => {
          this.toastr.success(val);
        });
    }
    console.log(response + 'for' + item.file.name + 'status' + status);
  }

  onUploadError(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    this.uploader.removeFromQueue(item);
    console.log(response + ' for ' + item.file.name + ' status ' + status);
  }

  computeMD5(item: FileItem): Observable<string> {
    return new Observable((subscriber: Subscriber<string>) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const hash = CryptoJS.MD5(CryptoJS.enc.Latin1.parse(e.target.result));
        const md5 = hash.toString(CryptoJS.enc.Hex);
        subscriber.next(md5);
        subscriber.complete();
      };
      console.log(item);
      reader.readAsBinaryString(item._file);
    });
  }

  onPreview(attachment: Material): void {
    this.storageService.accessToken(attachment.filePath)
      .subscribe(
        data => {
          const src = data.accessToken;
          const filePath = attachment.filePath ?? '';
          const ext = filePath.substr(filePath.lastIndexOf('.') + 1).toLowerCase();
          switch (ext) {
            case 'png':
            case 'jpg':
            case 'jpeg':
              this.onImagePreview(attachment, src);
              break;
            case 'pdf':
              this.onPDFPreview(attachment, src);
              break;
            default:
              window.open(src);
              break;
          }
        },
        err => {

        });
  }

  onDownload(attachment: Material): void {
    this.storageService.accessToken(attachment.filePath)
      .subscribe(
        data => {
          this.downloadService.download(data.accessToken, attachment.fileName);
        },
        err => {

        });

  }

  onEditor(type: string, i: number, editorTitle: TemplateRef<any>, editorContent: TemplateRef<any>, editorFooter: TemplateRef<any>): void {
    const entity = new AttachmentEntity();
    entity.type = type;
    entity.index = i;
    if (this.isGroup) {
      this.setFileTypeCopy.map(item => {
        if (item.type === type) {
          entity.attachment = _.cloneDeepWith(item.files[i]);
        }
      });
    } else {
      entity.attachment = _.cloneDeepWith(this.attachments[i]);
    }

    this.modalService.create({
      nzCentered: true,
      nzTitle: editorTitle,
      nzContent: editorContent,
      nzFooter: editorFooter,
      nzMaskClosable: false,
      nzClosable: false,
      nzComponentParams: entity,
      nzOnOk: () => console.log('Click ok')
    });
  }

  onEditorConfirm(ref: NzModalRef, entity: AttachmentEntity): void {
    let filesCopy: MaterialGroupType[] = [];
    if (this.attachments.length > entity.index) {
      if (this.isGroup) {
        filesCopy = JSON.parse(JSON.stringify(this.setFileTypeCopy));
        const arr = [];
        filesCopy.map(item => {
          if (item.type === entity.type) {
            item.files[entity.index] = entity.attachment;
          }
          arr.push(...item.files);
        });
        this.attachments = JSON.parse(JSON.stringify(arr));
      } else {
        this.attachments[entity.index] = entity.attachment;
      }
      this.valueChange.emit(this.attachments);
      this.billEdit.emit(entity.attachment);
    }
    ref.close();
    if (this.isGroup) {
      setTimeout(() => {
        this.getFileTypeFilter();
      }, 500);
    }
  }

  onDelete(type: string, index: number, tplTitle: TemplateRef<any>, tplContent: TemplateRef<any>, tplFooter: TemplateRef<any>): void {
    if (!this.attachments) {
      this.attachments = [];
      return;
    }

    if (this.attachments.length < index) {
      return;
    }

    const entity = new AttachmentEntity();
    entity.type = type;
    entity.index = index;
    if (this.isGroup) {
      this.setFileTypeCopy.map(item => {
        if (item.type === type) {
          entity.attachment = item.files[index];
        }
      });
    } else {
      entity.attachment = this.attachments[index];
    }

    this.modalService.create({
      nzCentered: true,
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzMaskClosable: false,
      nzClosable: false,
      nzComponentParams: entity,
      nzOnOk: () => {
      }
    });
  }

  onDeleteConfirm(ref: NzModalRef, entity: AttachmentEntity): void {
    let filesCopy: MaterialGroupType[] = [];
    if (this.attachments.length > entity.index) {
      if (this.isGroup) {
        filesCopy = JSON.parse(JSON.stringify(this.setFileTypeCopy));
        const arr = [];
        filesCopy.map(item => {
          if (item.type === entity.type) {
            item.files.splice(entity.index, 1);
          }
          if (!item.files.length) {
            this.setFileType.delete(entity.type);
          }
          arr.push(...item.files);
        });
        this.attachments = JSON.parse(JSON.stringify(arr));
      } else {
        this.attachments.splice(entity.index, 1);
      }
      this.valueChange.emit(this.attachments);

      this.translate.get('DeleteSuccess').subscribe(
        (val: string) => {
          this.toastr.success(val);
        });
      this.billDelete.emit(entity.attachment);
    }
    ref.close();
    if (this.isGroup) {
      setTimeout(() => {
        this.getFileTypeFilter();
      }, 500);
    }
  }

  onImagePreview(material: Material, src: string): void {
    const images = [
      {
        src,
        width: 'auto',
        height: 'auto',
        alt: material.fileName
      }
    ];
    this.nzImageService.preview(images, {nzZoom: 1.0, nzRotate: 0});
  }

  onPDFPreview(material: Material, src: string): void {

    const drawerRef = this.drawerService.create<PdfViewerComponent, { value: string }, string>({
      nzWidth: 800,
      nzContent: PdfViewerComponent,
      nzContentParams: {
        src
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const pdfViewerComponent = drawerRef.getContentComponent();
      pdfViewerComponent.autoresize = true;
      pdfViewerComponent.showAll = true;
      pdfViewerComponent.originalSize = false;
      pdfViewerComponent.fitToPage = true;
    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });
  }

  searchChange(): void {
    const arr = [];
    this.tagFlag.map((item, i) => {
      if (item) {
        arr.push(this.tagArr[i]);
      }
    });
    let newArr = [];
    const newArr1 = [];
    if (this.searchParam) {
      newArr = this.originalData.filter(item => item.fileName.toLowerCase().indexOf(this.searchParam.toLowerCase()) !== -1);
      arr.forEach((selectTag, i) => {
        newArr.forEach((attachment, j) => {
          if (attachment.tags && attachment.tags.length > 0) {
            if (attachment.tags.indexOf(selectTag) !== -1) {
              newArr1.push(attachment);
            }
          }
        });
      });
      if (arr.length) {
        this.attachments = [...new Set(newArr1)];
      } else {
        this.attachments = newArr;
      }
    } else {
      arr.forEach((selectTag, i) => {
        this.originalData.forEach((attachment, j) => {
          if (attachment.tags && attachment.tags.length > 0) {
            if (attachment.tags.indexOf(selectTag) !== -1) {
              newArr.push(attachment);
            }
          }
        });
      });
      if (arr.length) {
        this.attachments = [...new Set(newArr)];
      } else {
        this.attachments = this.originalData;
      }
    }
  }

  checkTagChange(value: any, index: number): void {
    const arr = [];
    this.tagFlag[index] = value;
    this.searchChange();
  }

  getFileType(): void {
    this.commonService.getMaterialType()
      .subscribe(
        data => {
          this.fileTypeArr = data;
        }, error => {
          console.log(error);
        });
  }

  fileTypeArrOption(val): string {
    if (!val) {
      return val;
    }
    let res = new MaterialType();
    let str = '';
    if (this.uploadTokenReq?.uploadType === UploadType.BILL) {
      if (val === 'BILL') {
        this.translate.get('BillAttachment').subscribe(
          (resss) => {
            str = resss;
          }
        );
      }
      if (val === 'SALES') {
        this.translate.get('PersonalUploadAttachment').subscribe(
          (resss) => {
            str = resss;
          }
        );
      }
    } else {
      if (this.fileTypeArr.length && this.fileTypeArr.some(item => item.fileType === val)) {
        res = this.fileTypeArr.find(item => item.fileType === val);
        str = res.name;
      } else {
        str = val;
      }
    }
    return str;
  }

  getFileTypeFilter(): void {
    this.setFileType = new Set();
    if (this.attachments && this.attachments.length) {
      this.attachments.map(item => {
        if (item.fileType) {
          if (!this.setFileType.has(item.fileType)) {
            this.setFileType.add(item.fileType);
          }
        } else {
          if (!this.setFileType.has(item.fileType)) {
            this.setFileType.add('#');
          }
        }
      });
    }
    this.getFileGroup();
  }

  getFileGroup(): void {
    const arr = [];
    if (this.attachments && this.attachments.length) {
      const sourceType = Array.from(this.setFileType) || [];
      // this.setFileTypeCopy = Array.from(this.setFileType);
      sourceType.map(item => {
        const obj = {
          type: item,
          files: [],
          active: this.attachments.length <= 10,
          count: 0
        };
        this.attachments.map(file => {
          if (file.fileType) {
            if (file.fileType === item) {
              obj.files.push(file);
            }
          } else if (!file.fileType && item === '#') {
            obj.files.push(file);
          }
        });
        obj.count = obj.files.length;
        arr.push(obj);
      });
      this.setFileTypeCopy = arr;
    }
  }

  onIsPreview(): boolean {
    if (this.readonly) {
      return this.isPreview;
    } else {
      return true;
    }
  }
}
